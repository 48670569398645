{
    "en": {
        "account": {
            "eula": {
                "description": "查看并阅读您接受的用户协议",
                "documents": {
                    "terms": "服务条款",
                    "coc": "行为守则"
                },
                "page_title": "用户协议",
                "title": "最终用户许可协议",
                "accepted": "您在 {time} 接受了此文件"
            },
            "limited": {
                "page": {
                    "title": "账户限制",
                    "description": "依据表现状况，你的账户可能会被临时限制，这包括但不限于限制账户激活和启用多重认证。受到停用、警告或其他制裁也可能导致你的账户受到限制。"
                },
                "features": {
                    "host": {
                        "title": "创建您自己的 Warsaw Revamped 服务器",
                        "suspended": "由于您违反服务条款，您在 Warsaw Revamped 上创建服务器的权限已被权益保护团队暂停。",
                        "no_discord": "你必须将你的 Discord 账户与 Warsap ID 绑定.",
                        "ok": "您可以创建公开或私人的 Warsaw Revamped 服务器。"
                    },
                    "play": {
                        "title": "正在 Warsaw Revamped 服务器上游玩",
                        "suspended": "由于您违反服务条款，您在 Warsaw Revamped 服务器上的游玩权限已被权益保护团队暂停。",
                        "ok": "你可以在公开或私人的 Warsaw Revamped 服务器上游玩。"
                    }
                },
                "alert": {
                    "title": "你的账户已被限制！",
                    "more-information": "详细信息",
                    "suspension": {
                        "any": {
                            "permanent": "因为 {reason} ，您的 Warsaw Revamped 账户被永久封禁。",
                            "temporarily": "因为 {reason} ，您的账户被封禁 {time} 。"
                        },
                        "host": {
                            "permanent": "因为 {reason} ，你的账户被永久禁止创建服务器。",
                            "temporarily": "由于 {reason} ，该账户已被禁止创建服务器 {time} 。"
                        }
                    }
                }
            },
            "navigator": {
                "settings": "设置",
                "eula": "用户须知"
            },
            "settings": {
                "page_title": "我的账户",
                "navigation": {
                    "settings": "设置",
                    "experimental_features": "实验性功能"
                },
                "experimental_features": {
                    "title": "实验性功能",
                    "description": "我们通常逐步发布新的功能以便我们在发布之前收集反馈。下列内容目前正在试验阶段，可能并非最终效果。欢迎进行反馈。",
                    "no_features_available": "你的账户目前没有启用试验性功能。",
                    "warning_test_feature": "为保证万无一失，此实验性功能在完整版发布前仅向有限数量的用户开放。"
                }
            }
        },
        "permission": {
            "status-badge": {
                "qa": {
                    "description": "质量保证团队会针对最新版本中存在的漏洞进行测试。",
                    "short": "质量保证",
                    "long": "质量保证"
                },
                "dev": {
                    "short": "开发人员",
                    "long": "开发团队",
                    "description": "开发团队负责 Warsaw Revamped 的开发工作。"
                },
                "staff": {
                    "short": "工作人员",
                    "long": "Warsaw Revamped 工作人员",
                    "description": "工作人员"
                },
                "verified": {
                    "short": "已认证",
                    "long": "官方认证账户",
                    "description": "Warsaw Revamped 上的官方认证徽章体现该账户所有者身份的真实性，此徽章被授予给为 Warsaw Revamped 社区作出重要贡献的成员",
                    "info": {
                        "active": {
                            "title": "活跃分子",
                            "description": "官方认证账户是那些积极参与 Warsaw Revamped 社区建设的用户。这些账户不仅十分活跃还在很多方面创作了许多内容。"
                        },
                        "badge": {
                            "title": "认证",
                            "description": "官方认证账户可以通过其个人资料上的认证标记加以识别， 这可以让您立即识别出这是Warsaw Revamped社区的重要贡献者。"
                        }
                    }
                },
                "mod": {
                    "short": "权益保护",
                    "long": "权益保护团队",
                    "description": "权益保护团队将在所有 Warsaw Revamped 平台上打击不端行为来保障绿色游戏环境，并肩负着保护全体 Warsaw Revamped 玩家的重要职责。",
                    "info": {
                        "help": {
                            "title": "玩家支持",
                            "description": "为了向用户提供优质服务， 我们有一支经验丰富的玩家支持团队来回应各类请求与问题。其内容涵盖了与你的账户、账户安全和功能相关的问题。"
                        },
                        "protect": {
                            "title": "监管",
                            "description": "监管者以《行为守则》为准绳，拥有对违规者进行警告甚至是封禁的权限。"
                        },
                        "badge": {
                            "title": "识别",
                            "description": "权益保护团队是专门监管 WR 平台的工作人员。其职责为保护普通用户并盯紧那些“坏人”。你可以通过专属徽章来识别他们的身份。"
                        },
                        "coc": {
                            "title": "修订《行为守则》",
                            "description": "权益保护团队成员与 WR 工作人员密切合作，维护行为守则。这意味着守则将会随时更新，并确保每个人在本平台都能有安全且愉快的体验。"
                        }
                    }
                },
                "admin": {
                    "short": "管理员",
                    "long": "Warsaw Revamped 管理员",
                    "description": "管理员负责各种管理工作，监督日常运作。"
                }
            }
        },
        "page": {
            "insider": {
                "join": {
                    "title": "对加入 WR 先锋计划有兴趣了？",
                    "description": "给我们发送电子邮件，告诉我们为什么你可以胜任 WR 先锋。包括你的过往经验与目的，发送 \n {email}。别忘了给我们你的 Discord 名字与 Tag 编号，这样我们就能与你在 Discord 上联系。"
                },
                "description": "帮助我们共建 Warsaw Revamped",
                "faq": {
                    "what": {
                        "title": "什么是 WR 先锋计划？",
                        "description": "WR 先锋计划旨在将零散的《战地风云》社区聚集起来，彰显 WR 的能力。作为游戏玩家，我们有一个共同点，那就是对游戏的热爱。为了传播我们对《战地风云4》的热爱，并展示我们在幕后的工作，我们创建了WR 先锋计划。"
                    },
                    "who": {
                        "title": "WR 先锋计划面向哪些人？",
                        "description": "WR 先锋计划的目标是让每个人都有机会加入成为 WR 内部人员。因此，你是否非常有动力，仍在定期玩《战地风云4》和（或）管理一个专门针对《战地风云》或《战地风云4》的Discord服务器、粉丝网站、论坛或Reddit页面？那么你就有资格成为 WR 的先锋了！\n\nYouTube 和 Twitch 上的流媒体和视频内容创作者也有资格成为 WR 先锋，我们非常欢迎他们提前尝试 Warsaw Revamped 并与观众和其他人分享他们的内容。\n\n除了热情的《战地风云4》玩家、流媒体和视频内容创作者之外，专门的 Mod 作者也有资格成为 WR 先锋。"
                    },
                    "advantages": {
                        "description": "WR 先锋可以访问专属的 Discord 分区，在那里我们经常询问关于新功能、设计或实施想法的反馈。\n\nWR 先锋可以提前获得新功能的独家使用权，包括 Modding API，以便尽早开始制作 MOD。\n\nWR 先锋可以通过封闭 Alpha 测试和未来的封闭 Beta 测试，当达到测试阶段时，他们将获得独家的 Warsaw Revamped 早期访问权。WR 先锋是第一个获得 Warsaw Revamped 早期访问权的人。",
                        "title": "作为 WR 先锋，能有哪些好处？"
                    }
                }
            }
        },
        "nav": {
            "auth": {
                "tooltip": {
                    "sign_in": "登录或创建账户"
                }
            },
            "primary": {
                "home": "主页",
                "mode": {
                    "dark_on": "打开夜间模式",
                    "dark_off": "关闭夜间模式"
                },
                "play_button": "开始游戏"
            }
        },
        "error": {
            "buttons": {
                "return": "返回",
                "go_home": "返回主页",
                "sign_in": "登录"
            },
            "general_error_title": "哎呀！",
            "page_not_found": {
                "title": "页面不见了",
                "description": "您尝试访问的页面不存在。"
            },
            "profile_unavailable": {
                "title": "个人资料不可用",
                "account_suspended": "此账户目前已在 Warsaw Revamped 停用。",
                "load_api_error": "无法加载您要查看的档案。"
            },
            "require_sign_in": {
                "title": "尚未登录",
                "description": "您必须登录 Warsaw ID 来查看此内容。"
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "我们检测到一个与 Warsaw Dragon 有关的问题，请提交反馈以协助我们改进",
                "send_feedback": "发送反馈"
            },
            "modal": {
                "placeholder": "我单击了……又按下了……",
                "description": "为了改进 Warsaw Revamped ，我们自动收集了在网站上的所有技术问题。你可以帮助我们重现问题，告诉我们问题的原因与问题发生之前你的操作。简短的几句话就足够让问题重现。"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "夜间模式",
                "description": "点击导航栏上的月亮或太阳图标来启用此实验功能"
            }
        },
        "general": {
            "buttons": {
                "learn-more": "了解更多",
                "see-more-feedback": "查看更多反馈",
                "other-platforms": "其他平台",
                "download": "下载",
                "help": "帮助",
                "retry": "重试",
                "ok": "确定",
                "continue": "继续"
            },
            "errors": {
                "feedback": {
                    "error-generic": "连接反馈服务器失败，请稍后再试。"
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "阅读全文",
                "more-news": "更多新闻"
            }
        },
        "notifications": {
            "email-not-activated": "你必须验证头像后才能在 Warsaw Revamped 上游玩。"
        },
        "profile": {
            "error": {
                "general": {
                    "title": "哎呀！出错了",
                    "description": "抱歉，我们目前无法显示该档案。请稍后再试。"
                },
                "banned": {
                    "title": "此用户已被 Warsaw Revamped 封禁",
                    "description": "该用户因违反了 {code-of-conduct} 与我们的 {terms-of-service} 而被封禁。"
                },
                "not_found": {
                    "title": "抱歉，没有该名称的用户",
                    "description": "我们找不到使用该名称的用户"
                }
            },
            "badge": {
                "type": {
                    "exclusive": {
                        "description": "这些徽章不可获得。",
                        "title": "专属"
                    },
                    "achievement": {
                        "title": "成就"
                    }
                },
                "view": {
                    "levels": "{badge} 等级",
                    "stats": "{badge}: 已有 {percentage} 的玩家达成",
                    "obtained": "{time} 之前获得"
                }
            }
        },
        "open-positions": {
            "title": "加入我们",
            "who-we-are": {
                "title": "我们是谁？"
            }
        }
    }
}
