{
    "general": {
        "buttons": {
            "return-to-home": "Return to homepage"
        }
    },
    "header": {
        "support": "Support",
        "account": "Account"
    },
    "blocks": {
      "account": {
          "title": "Hello, {username}",
          "profile": {
              "sign-out": "Sign out",
              "warsaw-id": "My Warsaw ID",
              "preferences": "My Preferences"
          },
          "experimental": {
              "title": "Experimental Features"
          }
      }
    },
    "service": {
        "wr": "Warsaw Revamped",
        "wr-help": "WR Help",
        "wr-labs": "WR Labs"
    },
    "error": {
        "error-404": {
            "title": "Page not Found",
            "page-title": "Page not found",
            "info": "Sorry, the page you are trying to reach doesn't exist."
        }
    },
    "footer": {
        "logo": {
            "title": "Created by",
            "copyright": "Warsaw Revamped © {year}"
        },
        "dragon": {
            "title": "Powered by",
            "copyright": "Warsaw Dragon © {year}"
        },
        "game": {
            "title": "Warsaw Revamped",
            "play": "Play",
            "insider": "WR Insiders"
        },
        "eula": {
            "title": "User agreements",
            "terms-of-service": "Terms of service",
            "privacy-policy": "Privacy Policy",
            "code-of-conduct": "Code of Conduct",
            "cookie-policy": "Cookie Policy"
        },
        "help": {
            "title": "Warsaw Help",
            "contact-us": "Contact us",
            "faq": "FAQ",
            "feedback": "Feedback",
            "status": "Status"
        },
        "about": {
            "title": "About us",
            "team": "Our Team",
            "join-team": "Join the team"
        },
        "legal": {
            "legal_a": "© {year} Warsaw Revamped. All rights reserved",
            "legal_b": "We are in no shape or form affiliated with Electronic Arts Inc. or EA Digital Illusions CE AB (also known as DICE). Battlefield® and Battlefield 4® are registered trademarks by Electronic Arts Inc. All company, product, and service names, logos, and brands, and any other copyrighted materials are property of their respective owners and are used for identification purposes only. Use of these materials does not imply endorsement.",
            "legal_c": "EXCEPT AS EXPRESSLY CREDITED, NO WEAPON, VEHICLE OR GEAR MANUFACTURER IS AFFILIATED WITH OR HAS SPONSORED OR ENDORSED THIS GAME."
        }
    }
}
