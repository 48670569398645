/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import {Setting} from '../settings.e';
import {Deployment as baseDeployment} from "../../../../config/deployment";
import {Deployment} from "../../../../../../dragon/config/deployment";

const SETTING_PREFIX = "wr:local.setting:%v";

/**
 * Setting
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export default abstract class ISetting {
    Setting: Setting;

    Default: any;

    protected constructor(Setting: Setting, valueDefault?: any) {
        this.Setting = Setting;
        this.Default = valueDefault;

        /**
         * Set default value if none exists
         */
        if (window.localStorage.getItem(SETTING_PREFIX.replace("%v", Setting)) === null) {
            window.localStorage.setItem(SETTING_PREFIX.replace("%v", Setting), JSON.stringify({ key: Setting, value: valueDefault, version: { base: baseDeployment.commit + "/" + baseDeployment.branch, local: Deployment.commit_sha_short + "/" + Deployment.deployment_branch } ,first: new Date().toISOString(), default: valueDefault, updated: new Date().toISOString() }))
        }
    }

    /**
     * Set the value of this setting in the local storage
     */
    set(value: any) {
        if (window.localStorage.getItem(SETTING_PREFIX.replace("%v", this.Setting)) === null) {
            this.setDefault(value);
            return;
        }
    }

    /**
     * Delete the value of this setting in the local storage
     */
    del() {
        window.localStorage.removeItem(SETTING_PREFIX.replace("%v", this.Setting));
    }

    /**
     * Load the default value
     */
    private setDefault(value?: any) {
        if (window.localStorage.getItem(SETTING_PREFIX.replace("%v", this.Setting)) === null && (this.Default || value)) {

            let localValue = this.Default;
            if (value)
                localValue = value;

            window.localStorage.setItem(SETTING_PREFIX.replace("%v", this.Setting), JSON.stringify({ key: Setting, value: localValue, version: { base: baseDeployment.commit + "/" + baseDeployment.branch, local: Deployment.commit_sha_short + "/" + Deployment.deployment_branch } ,first: new Date().toISOString(), default: this.Default, updated: new Date().toISOString() }))
        }
    }
}