{
    "footer": {
        "logo": {
            "title": "Gemaakt door"
        },
        "dragon": {
            "title": "Aangedreven door"
        },
        "game": {
            "play": "Speel",
            "insider": "WR Insiders"
        },
        "eula": {
            "code-of-conduct": "Gedragscode",
            "title": "Gebruikersovereenkomsten",
            "terms-of-service": "Algemene voorwaarden",
            "privacy-policy": "Privacyverklaring",
            "cookie-policy": "Cookiebeleid"
        },
        "help": {
            "title": "Warsaw Help",
            "contact-us": "Contacteer ons",
            "faq": "Veelgestelde vragen"
        },
        "about": {
            "title": "Over ons",
            "open-positions": "Open posities",
            "us-on-gitlab": "Warsaw Revamped op GitLab"
        },
        "legal": {
            "legal_a": "© {year} Warsaw Revamped. Alle rechten voorbehouden",
            "legal_c": "TENZIJ UITDRUKKELIJK VERMELD, IS GEEN WAPEN-, VOERTUIG- OF UITRUSTINGFABRIKANT VERBONDEN MET DIT SPEL OF HEEFT HET GESPONSORD OF ONDERSCHREVEN.",
            "legal_b": "Wij zijn op geen enkele wijze verbonden met Electronic Arts Inc. of EA Digital Illusions CE AB (ook bekend als DICE). Battlefield® en Battlefield 4® zijn geregistreerde handelsmerken van Electronic Arts Inc. Alle bedrijfs-, product- en dienstnamen, logo's en merken, en alle andere auteursrechtelijk beschermde materialen zijn eigendom van hun respectieve eigenaars en worden uitsluitend voor identificatiedoeleinden gebruikt. Het gebruik van deze materialen houdt geen goedkeuring in."
        }
    },
    "header": {
        "services": {
            "wr": "Home",
            "wr-help": "WR Help",
            "wr-labs": "Labs"
        },
        "my-account": {
            "my-profile": "Mijn Profiel",
            "preferences": "Mijn Voorkeuren",
            "my-id": "Mijn Warsaw ID",
            "signout": "Log uit"
        },
        "tooltips": {
            "sign-in": "Log in of maak een account aan"
        }
    },
    "error": {
        "error-404": {
            "title": "Pagina niet gevonden",
            "page-title": "Pagina niet gevonden",
            "info": "Sorry, de pagina die je probeert te bereiken bestaat niet."
        }
    },
    "general": {
        "buttons": {
            "return-to-home": "Terug naar de homepage"
        }
    }
}
