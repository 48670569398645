{
    "page": {
        "home": {
            "title": "Accueil"
        },
        "download": {
            "title": "Télécharger"
        }
    },
    "play": {
        "title": "Jouer",
        "description": "Créez votre propre Battlefield 4™ expérience de jeu. Jouez, découvrez et créez."
    },
    "insider": {
        "title": "WR Insiders",
        "description": "Conçu pour rapprocher le communauté Battlefield™. Les créateurs de contenu et les joueurs engagés sont invités à rejoindre notre programme WR Insiders."
    },
    "401": {
        "title": "Non autorisé"
    },
    "404": {
        "title": "Pas trouvé"
    },
    "home": {
        "title": "Accueil",
        "description": "Les dernières nouvelles et mises à jour pour tout ce qui concerne Warsaw Revamped: la plateforme de mods pour Battlefield 4™."
    },
    "roadmap": {
        "title": "Roadmap"
    },
    "open-positions": {
        "title": "Positions ouvertes"
    },
    "legal": {
        "video-policy": {
            "title": "Politique vidéo"
        },
        "ugc-policy": {
            "title": "Politique relative au CGU"
        },
        "cookie-policy": {
            "title": "Politique relative aux cookies",
            "description": "Découvrez quels cookies nous collectons et stockons sur votre appareil lorsque vous visitez notre site web et utilisez nos services."
        },
        "privacy-policy": {
            "title": "Politique de confidentialité",
            "description": "Découvrez comment nous traitons, stockons et protégeons les données sensibles et protégeons l'intégrité de notre plateforme pour nos utilisateurs."
        },
        "code-of-conduct": {
            "title": "Code de conduite"
        },
        "terms-of-service": {
            "title": "Conditions d'utilisation"
        }
    },
    "launcher": {
        "authenticate": {
            "title": "Authentification"
        }
    },
    "team": {
        "title": "L'équipe",
        "join": {
            "title": "Rejoindre l'équipe"
        },
        "view": {
            "title": "Postuler"
        }
    }
}
