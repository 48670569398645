/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

/**
 * Local storage key
 */
const WR_KEY_DARKMODE = "wr:layout.setting.dark-mode";

/**
 * Fetch the JWT token from local storage.
 */
export const getDarkMode = () => {
    return (window.localStorage.getItem(WR_KEY_DARKMODE) === 'true') // Stored as str, reutrn as bool
};

/**
 * Save a new token to the local storage.
 * @param value {boolean}
 */
export const setStorageDarkMode = value => {
    window.localStorage.setItem(WR_KEY_DARKMODE, value);
};