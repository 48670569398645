{
    "en": {
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "Zaufanie i Bezpieczeństwo",
                    "long": "Zespół Zaufania i Bezpieczeństwa",
                    "description": "Zespół zaufania i bezpieczeństwa ponosi kluczową odpowiedzialność za ochronę użytkowników Warsaw Revamped poprzez zapewnienie bezpieczeństwa w Internecie poprzez zwalczanie nadużyć na wszystkich platformach wycofanych w Warsaw Revamped.",
                    "info": {
                        "help": {
                            "title": "Wsparcie graczy",
                            "description": "Aby zapewnić użytkownikom najlepsze możliwe doświadczenie, mamy doświadczony zespół wsparcia graczy, który będzie odpowiadał na zgłoszenia dotyczące Twojego konta, bezpieczeństwa i funkcji."
                        },
                        "protect": {
                            "title": "Moderacja",
                            "description": "Moderatorzy chronią kodeks postępowania, korzystając z ich dostępu do narzędzi, które pozwalają im ostrzegać użytkowników, którzy naruszają zasady lub nawet zakazują im korzystania z platformy WR."
                        },
                        "badge": {
                            "title": "Rozpoznawanie",
                            "description": "Członkowie zespołu zaufania i bezpieczeństwa są opiekunami, którzy w szczególności monitorują platformę WR, aby chronić niewinnych użytkowników i przyglądać się 'złym gościem'. Możesz je rozpoznać za pomocą ich unikalnej odznaki."
                        },
                        "coc": {
                            "title": "Zmiany w kodeksie postępowania",
                            "description": "Członkowie zespołu zaufania i bezpieczeństwa ściśle współpracują z personelem WR w celu utrzymania kodeksu postępowania. Oznacza to, że przepisy są zawsze aktualizowane i że platforma jest bezpiecznym i przyjemnym miejscem dla wszystkich."
                        }
                    }
                },
                "admin": {
                    "short": "Administrator",
                    "long": "Administrator Warsaw Revamped",
                    "description": "Administratorzy są odpowiedzialni za różne obowiązki administracyjne i nadzorują bieżące operacje."
                },
                "qa": {
                    "short": "Zapewnienie jakości",
                    "long": "Zapewnienie jakości",
                    "description": "Testy zespołu zapewnienia jakości dla błędów w najnowszych wersjach."
                },
                "dev": {
                    "short": "Deweloper",
                    "long": "Zespół deweloperski",
                    "description": "Zespół deweloperski odpowiedzialny za rozwój Warsaw Revamped."
                },
                "staff": {
                    "short": "Personel",
                    "long": "Personel Warsaw Revamped",
                    "description": "Personel"
                },
                "verified": {
                    "short": "Zweryfikowany/a",
                    "long": "Zweryfikowane konto",
                    "description": "Odznaka zweryfikowanego konta w Warsaw Revamped pokazuje, że to konto jest autentyczne i jest przyznawane członkom, którzy odgrywają ważną rolę w odebranej społeczności",
                    "info": {
                        "active": {
                            "title": "Aktywny",
                            "description": "Zweryfikowane konta są aktywnie zaangażowane w społeczności Warsaw Revamped. Aktywnie wykorzystują swoje konto i w wielu przypadkach tworzą treść."
                        },
                        "badge": {
                            "title": "Rozpoznawanie",
                            "description": "Zweryfikowane konta mogą być rozpoznawane za pomocą znaku wyboru w ich profilu, który pozwala na natychmiastowe rozpoznanie ważnej osoby ze społeczności Warsaw Revamped."
                        }
                    }
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "Wykryliśmy problem z Warsaw Dragon, pomóż nam ulepszyć poprzez przesłanie opinii",
                "send_feedback": "Prześlij opinię"
            }
        },
        "error": {
            "general_error_title": "Ups...!",
            "page_not_found": {
                "title": "Nie odnaleziono strony",
                "description": "Strona, której szukasz nie istnieje."
            },
            "profile_unavailable": {
                "title": "Profil niedostępny",
                "account_suspended": "To konto jest obecnie zawieszone w Warsaw Revamped.",
                "load_api_error": "Nie można załadować profilu, którego szukasz."
            },
            "require_sign_in": {
                "title": "Nie zalogowany",
                "description": "Aby wyświetlić tę zawartość, musisz zalogować się za pomocą swojego Warsaw ID."
            },
            "buttons": {
                "return": "Powrót",
                "go_home": "Powrót do strony głównej",
                "sign_in": "Zaloguj się"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "Ograniczenia konta",
                    "description": "Konta mogą otrzymywać tymczasowe ograniczenia w zależności od ich stanu zdrowia, w tym między innymi aktywacja konta i aktywacja uwierzytelniania wieloskładnikowego. Otrzymanie zawieszenia, ostrzeżenia lub innej sankcji może również spowodować ograniczenie Twojego konta."
                },
                "features": {
                    "host": {
                        "title": "Hostowanie własnego serwera Warsaw Revamped",
                        "suspended": "Twój dostęp do hostingu serwerów Warsaw Revamped został zawieszony przez zespół zeufania i bezpieczeństwa z powodu naruszenia warunków korzystania z usług.",
                        "no_discord": "Musisz połączyć swoje konto Discord z Warsaw ID.",
                        "ok": "Możesz hostować publiczne i prywatne serwery Warsaw Revamped."
                    },
                    "play": {
                        "title": "Granie na serwerach Warsaw Revamped",
                        "suspended": "Twój dostęp do gry na serwerach Warsaw Revamped został zawieszony przez zespół Zaufania i Bezpieczeństwa z powodu naruszenia warunków korzystania z usług.",
                        "ok": "Możesz grać na publicznych i prywatnych serwerach w Warsaw Revamped."
                    }
                },
                "alert": {
                    "title": "Twoje konto zostało zablokowane!",
                    "more-information": "Więcej informacji",
                    "suspension": {
                        "any": {
                            "permanent": "Twoje konto zostało trwale zawieszone z Warsaw Revamped z powodu {reason}.",
                            "temporarily": "Twoje konto zostało zawieszone na {time} z powodu {reason}."
                        },
                        "host": {
                            "permanent": "Twoje konto zostało trwale zawieszone z Warsaw Revamped z powodu {reason}.",
                            "temporarily": "Twoje konto zostało zawieszone od hostowania serwerów na {time} z powodu {reason}."
                        }
                    }
                }
            },
            "navigator": {
                "settings": "Ustawienia",
                "eula": "Umowy"
            },
            "settings": {
                "page_title": "Moje konto",
                "navigation": {
                    "settings": "Ustawienia",
                    "experimental_features": "Funkcje eksperymentalne"
                },
                "experimental_features": {
                    "title": "Funkcje eksperymentalne",
                    "description": "Często wydajemy częściowo nowe funkcje, aby umożliwić nam zbieranie informacji na ich temat przed opublikowaniem ich dla wszystkich. Wymienione poniżej funkcje są obecnie w fazie eksperymentalnej i mogą nie być produktem końcowym. Doceniamy opinie na temat tych funkcji.",
                    "no_features_available": "Obecnie nie są włączone żadne funkcje eksperymentalne dla Twojego konta.",
                    "warning_test_feature": "Ta eksperymentalna funkcja jest dostępna tylko dla ograniczonej liczby użytkowników, aby umożliwić nam sprawdzenie problemów przed ich opublikowaniem."
                }
            },
            "eula": {
                "page_title": "Umowy użytkownika",
                "title": "Umowa licencyjna użytkownika końcowego",
                "description": "Zobacz i przeczytaj zaakceptowane umowy",
                "documents": {
                    "terms": "Warunki korzystania z usługi",
                    "coc": "Kodeks Postępowania"
                },
                "accepted": "Zaakceptowałeś ten dokument {time}"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Tryb ciemny",
                "description": "Włącz tę eksperymentalną funkcję, klikając na księżyc lub słońce na pasku nawigacji"
            }
        },
        "nav": {
            "primary": {
                "home": "Strona główna",
                "mode": {
                    "dark_on": "Włącz tryb ciemny",
                    "dark_off": "Wyłącz tryb ciemny"
                }
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Zaloguj się lub utwórz konto"
                }
            }
        }
    }
}
