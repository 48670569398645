/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import ISubModule from "../../ISubModule";
import LayoutMod from "./layout.mod";
import {getDarkMode, setStorageDarkMode} from "./utils/dark-mode-storage.util";
import store from '../../../../../store';
import {Config} from "../../../../../dragon/config/config";

/**
 * Server Manager
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export default class DarkModeSub extends ISubModule {
    constructor(parent: LayoutMod) {
        super("Dark Mode", parent);
    }

    protected async enable(): Promise<any> {
        // Check if dark mode is set in the settings, if not, set it to the browser default.
        if (window.localStorage.getItem("wr:layout.setting.dark-mode") === null) {

            if (Config.Features.Layout.allowAutomaticDarkMode) {
                // Check if the OS prefers dark mode
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    this.mod_logger.verbose("OS prefers dark mode, enabling dark mode.");
                    this.setDarkMode(true)
                } else {
                    this.mod_logger.verbose("OS has no dark mode preference.");
                    this.setDarkMode(false);
                }
            } else {
                this.mod_logger.verbose("Automatic dark mode detection is disabled in the configuration file.");
                this.setDarkMode(false);
            }

        } else {
            this.setDarkMode(getDarkMode()); // Update dark mode to what the user has stored in their local storage.
        }
        return true; // No issues
    }

    /**
     * Set the page dark mode.
     * @param status {boolean} new setting for dark mode
     */
    setDarkMode(status: any) {
        // Check to prevent duplicates
        if (status === store.state.layout.dark_mode) {
            this.mod_logger.warn(`Refused to set dark mode to ${status}. Dark mode is already ${status}`);
            return;
        }

        this.mod_logger.info("Dark mode has been set to: " + status); // Notify in console
        setStorageDarkMode(status); // Update dark mode in local storage.
        store.state.layout.dark_mode = status; // Update dark mode in the store.
    }
}