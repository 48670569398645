/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

/**
 * Paths and locations related to storage of assets (images, static files, etc)
 * @author Firjen <firjen@warsaw-revamped.com>
 * @copyright Warsaw Revamped (c)
 */
export const Storage = {
    hosts: {
        /**
         * Domain for Minio CDN
         */
        cdn: "https://cdn.warsaw-revamped.com",

        /**
         * Configuration related to the imaging Util
         */
        imaging: {
            /**
             * URL to the imgproxy server.
             */
            primary: "https://img.warsaw-revamped.com",

            /**
             * Fallback URL for the Dragon imaging until when no local image is found.
             * Example of a path is 'https://cdn.warsaw-revamped.com/imaging/branding/icon/primary.png' where the CDN url is the fallback CDN
             */
            fallback: "https://cdn.warsaw-revamped.com",
        },

        /**
         * Domain for gamecontent
         */
        gamecontent: "https://cdn.warsaw-revamped.com"
    },

    content: {
        launcher: {
            win: "http://cdn.dev.warsaw-revamped.com/dragonium-dev/Warsaw_Revamped.exe",
            linux: {
                debian: "http://cdn.dev.warsaw-revamped.com/dragonium-dev/Warsaw_Revamped.deb",
                centos: "http://cdn.dev.warsaw-revamped.com/dragonium-dev/Warsaw_Revamped.rpm",
                arch: "http://cdn.dev.warsaw-revamped.com/dragonium-dev/Warsaw_Revamped.pacman"
            }
        }
    }
};