{
  "cookie-policy": {
    "title": "Cookie Policy",
    "description_1": "A cookie is a small piece of text sent to your browser by a website you visit. It helps the site remember information about your visit, which can make it easier to visit the site again and make the site more useful to you.",
    "description_2": "This page describes the types of cookies used by Warsaw Revamped. See the {privacy_policy} to learn how we protect your privacy in our use of cookies and other information.",
    "functionality": {
      "title": "Functionality",
      "description": "Cookies used for functionality allow users to interact with Warsaw Revamped or to access features that are fundamental to Warsaw Revamped. Things considered fundamental to the service include preferences like the user’s choice of language or the user’s layout preferences, and maintaining information relating to a user’s session, such as the time of login."
    },
    "security": {
      "title": "Security",
      "description_1": "Cookies used for security authenticate users, prevent unauthorised access and protect users as they interact with a service.",
      "description_2": "Some cookies are allowed to keep the user signed into their ‘Warsaw ID’. Warsaw ID uses cookies prefixed by ‘KEYCLOAK’ or ‘AUTH’ to store information related to the current session.They expire based on the user’s session length preference."
    },
    "analytics": {
      "title": "Analytics",
      "description": "Warsaw Revamped uses Google Analytics to improve content and to build better features to improve the user’s experience. Read more in {google_cookie_policy}"
    }
  },
  "privacy-policy": {
    "title": "Privacy Policy",
    "description": "Your privacy is important to Warsaw Revamped and we take our responsibility for caring for it seriously. This policy describes how Warsaw Revamped (“WR”), processes the personal information we collect about you when you use our services.",
    "info-we-collect": {
      "title": "Information we collect",
      "description_1": {
        "intro": "We collect information that you provide to us:",
        "1": "Your Warsaw ID account information, including your email address, username, and password",
        "2": "Information you provide when seeking help from us"
      },
      "description_2": "When you participate in social features, like the chat, any audio, visual and electronic information that you disclose may be copied, collected or used by other players and can become publicly available.",
      "description_3": "When playing on Warsaw Revamped, your in-game profile information, gameplay and game statistics are publicly visible."
    },
    "how-we-use": {
      "title": "How we use your information",
      "description": "We use your personal information to operate, improve and develop Warsaw Revamped and associated services. We do not share, sell or otherwise disclose personal information to third parties."
    },
    "how-we-protect": {
      "title": "How we protect your information",
      "description": "Security of your information is extremely important for us and we take a wide range of technical and organisational measures to help protect it. Including encrypting and hashing of sensitive data and storing the least amount of personal information and only the data we require. Even so, please be aware that no security measure is 100% perfect. Thus we cannot guarantee the security of your information at all times. You should always be diligent when it comes to the protection of your information."
    },
    "google-analytics": {
      "title": "Google Analytics",
      "description": "We use Google Analytics to collect behavior of the users of our websites. For more information see the {google_privacy_policy}."
    },
    "gdpr": {
      "title": "General Data Protection Regulation",
      "description": "You can exercise any of your privacy rights by visiting your {warsaw_id_settings}. To protect your account ownership, you may be asked to verify your account ownership. Once you have verified your account ownership, you might also be required to provide your confirmation authorising an agent to exercise these rights on your behalf. If you have any questions about exercising your privacy rights, you can contact us through {wr_help}.",
      "possibilities": {
        "delete": "You can delete all personal information on your account by removing your Warsaw ID on your {warsaw_id_settings}. If you do so, you will no longer be able to play on Warsaw Revamped.",
        "access": "You can access the personal information we hold about you on your {warsaw_id_settings}."
      }
    },
    "revisits": {
      "title": "Updates to our Privacy Policy",
      "description": "We may occasionally update our privacy policy. When we do, we will revise the 'last updated' date at the bottom of the policy. If there are material changes to this policy, we will use reasonable efforts to notify you either by prominently posting a notice of such changes before they take effect or by directly sending you a notification."
    },
    "contact-us": {
      "title": "Contact us",
      "description": "If you have any questions about this policy, please use {wr_help} to contact us."
    },
    "last_updated": "Last updated: {time}"
  }
}