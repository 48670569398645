{
    "page": {
        "download": {
            "title": "Скачать"
        },
        "home": {
            "title": "Домашняя страница"
        }
    },
    "insider": {
        "title": "WR Insiders",
        "description": "Создан, чтобы собрать сообщество Battlefield™ вместе. Создатели контента и преданные игроки могут присоединиться к нашей программе WR Insiders."
    },
    "roadmap": {
        "title": "Дорожная карта",
        "description": "Дорожная карта Warsaw Revamped подробно описывает контент с первого дня релиза, включая игру против ИИ-солдат для одиночной, совместной и многопользовательской игры."
    },
    "401": {
        "title": "Неавторизованный",
        "description": "Для просмотра этой страницы вы должны войти в систему с вашим WR ID и иметь соответствующие привилегии."
    },
    "404": {
        "title": "Не найдено",
        "description": "Страница, которую вы ищете, не найдена. Нужна помощь? Тогда перейдите на help.warsaw-revamped.com для дополнительной информации."
    },
    "home": {
        "title": "Домашняя страница",
        "description": "Последние новости и обновления для всего, что связано с Warsaw Revamped: мод платформа для Battlefield 4™."
    },
    "play": {
        "title": "Играть",
        "description": "Создайте ваш собственный игровой опыт Battlefield 4™. Играйте, открывайте и творите."
    },
    "legal": {
        "cookie-policy": {
            "title": "Политика Cookie",
            "description": "Узнайте, какие файлы cookie мы собираем и сохраняем на вашем устройстве, когда вы посещаете наш веб-сайт и пользуетесь нашими сервисами."
        },
        "privacy-policy": {
            "title": "Политика конфиденциальности",
            "description": "Узнайте, как мы обрабатываем, храним и защищаем конфиденциальные данные, а также защищаем целостность нашей платформы для наших пользователей."
        },
        "code-of-conduct": {
            "title": "Кодекс поведения",
            "description": ""
        },
        "terms-of-service": {
            "title": "Условия обслуживания",
            "description": ""
        },
        "ugc-policy": {
            "title": "Политика пользовательского контента"
        },
        "video-policy": {
            "title": "Видео политика"
        }
    },
    "event": {
        "technical-playtest": {
            "description": "",
            "title": "Технический плейтест"
        }
    },
    "open-positions": {
        "title": "Вакансии"
    },
    "launcher": {
        "authenticate": {
            "title": "Аутентификация"
        }
    },
    "team": {
        "title": "Команда",
        "join": {
            "title": "Присоединиться к команде"
        },
        "view": {
            "title": "Подать заявление"
        }
    }
}
