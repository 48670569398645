{
    "en": {
        "page": {
            "insider": {
                "faq": {
                    "advantages": {
                        "description": "WR Insider'lar, yeni özellikler, tasarım veya uygulama fikirleri hakkında sık sık geri bildirim istediğimiz özel bir Discord bölümüne erişebilir.\n\nWR Insider'lar, mod oluşturmaya erken başlamak için Modlama API'si de dahil olmak üzere yeni özelliklere özel erken erişim elde eder.\n\nWR Insider'lar, Kapalı Alfa aracılığıyla Oyun Testlerine ve gelecekte Varşova Yenilenmiş beta aşamasına ulaştığında Kapalı Beta sırasında özel erken erişime sahiptir. WR Insider'lar, Warsaw Revamped'e erken erişim sağlayan ilk kişilerdir.",
                        "title": "Insider olmanın avantajları nelerdir?"
                    },
                    "who": {
                        "description": "WR Insider Programının amacı, herkese WR Insider olarak katılma fırsatı vermektir. Öyleyse, hala düzenli olarak Battlefield 4™ oynuyor ve/veya Battlefield™ veya Battlefield 4™'e ayrılmış bir Discord sunucusu, fan sitesi, forum veya Reddit sayfasını yönetirken son derece motive misiniz? O zaman WR Insider olmaya hak kazanırsınız!\n\nYouTube ve Twitch'teki yayıncılar ve video içerik oluşturucuları da WR Insider'lar olarak nitelendirilir ve Warsaw Revamped'i erkenden deneyebilir ve içeriklerini izleyicileri ve başkalarıyla paylaşabilirler.\n\nTutkulu Battlefield 4™ oyuncuları, yayıncılar ve video içerik oluşturucularının yanı sıra, özel modcular da WR Insider'lar olarak nitelendiriliyor!",
                        "title": "WR Insiders programı kimler için ayrılmıştır?"
                    },
                    "what": {
                        "title": "WR Insider programı nedir?",
                        "description": "WR Insiders Programı, bölünmüş Battlefield™ topluluğunu bir araya getirmek ve WR'nin neler yapabileceğini göstermek için tasarlanmıştır. Oyuncular olarak ortak bir noktamız var, bu seriyi seviyoruz. Battlefield 4™ sevgimizi yaymak ve perde arkasında ne üzerinde çalıştığımızı göstermek için WR Insider'ları yarattık."
                    }
                },
                "join": {
                    "description": "Bize neden bir WR Insider olmanız gerektiğini açıklayan bir e-posta gönderin. {email}'e geçmiş deneyimlerinizi ve motivasyonunuzu ekleyin. Discord'da sizinle sohbet edebilmemiz için bize Discord adınızı ve etiketinizi vermeyi unutmayın.",
                    "title": "WR Insiders programına katılmak ister misiniz?"
                },
                "description": "Warsaw Revamped'ı inşa etmemize yardım edin"
            }
        },
        "nav": {
            "primary": {
                "home": "",
                "mode": {
                    "dark_on": "Karanlık modu aç",
                    "dark_off": "Karanlık modu kapat"
                },
                "play_button": ""
            },
            "auth": {
                "tooltip": {
                    "sign_in": ""
                }
            }
        },
        "account": {
            "limited": {
                "features": {
                    "host": {
                        "no_discord": "Discord hesabınızı Warsaw kimliğinizle ilişkilendirmelisiniz.",
                        "ok": "Genel ve özel Warsaw Revamped sunucuları barındırabilirsiniz.",
                        "suspended": "Warsaw Revamped sunucularını barındırma erişiminiz, hizmet şartları ihlalleri nedeniyle Trust & Safety ekibi tarafından askıya alındı.",
                        "title": "Kendi Warsaw Revamped sunucunuzu barındırma"
                    },
                    "play": {
                        "title": "Warsaw Revamped sunucuda oynamak",
                        "ok": "Herkese açık ve özel Warsaw Revamped sunucularında oynayabilirsiniz.",
                        "suspended": "Warsaw Revamped sunucularında oynamaya erişiminiz, hizmet şartları ihlalleri nedeniyle Trust & Safety ekibi tarafından askıya alındı."
                    }
                },
                "alert": {
                    "title": "Hesabınız sınırlandırıldı!",
                    "more-information": "Daha bilgi",
                    "suspension": {
                        "any": {
                            "permanent": "Hesabınız {reason} nedeniyle Warsaw Revamped'den kalıcı olarak askıya alındı.",
                            "temporarily": "Hesabınız {reason} nedeniyle {time} süreyle askıya alındı."
                        },
                        "host": {
                            "permanent": "Hesabınız, {reason} nedeniyle barındırma sunucularından kalıcı olarak askıya alındı.",
                            "temporarily": "Hesabınız, {reason} nedeniyle {time} süreyle barındırma sunucularından askıya alındı."
                        }
                    }
                },
                "page": {
                    "description": "Accountlar, sağlık durumlarına bağlı olarak geçici kısıtlamalar alabilir; buna hesap etkinleştirme ve çok faktörlü kimlik doğrulamanın etkinleştirilmesi dahildir ancak bununla sınırlı değildir. Bir askıya alma, uyarı veya başka bir yaptırım almak da hesabınızın sınırlandırılmasına neden olabilir.",
                    "title": "Account sınırlamaları"
                }
            },
            "settings": {
                "experimental_features": {
                    "no_features_available": "Şu anda hesabınız için etkinleştirilmiş deneysel özellik yok.",
                    "description": "Herkesin kullanımına sunmadan önce onlar hakkında geri bildirim almamıza izin vermek için sık sık yeni özellikleri kısmen yayınlıyoruz. Aşağıda listelenen özellikler şu anda deneysel bir aşamadadır ve bitmiş bir ürün olmayabilir. Bu özelliklerle ilgili geri bildirim için teşekkür ederiz.",
                    "title": "deneysel özellikler",
                    "warning_test_feature": "Bu deneysel özellik, tamamen yayınlamadan önce sorunları kontrol etmemize izin vermek için yalnızca sınırlı sayıda kullanıcıya sunulmuştur."
                },
                "page_title": "Benim account",
                "navigation": {
                    "settings": "Ayarlar",
                    "experimental_features": "deneysel özellikler"
                }
            },
            "eula": {
                "page_title": "Kullanıcı sözleşmeleri",
                "title": "Son kullanıcı lisans sözleşmeleri",
                "description": "Kabul ettiğiniz kullanıcı sözleşmelerini görüntüleyin ve okuyun",
                "documents": {
                    "terms": "Kullanım Şartları",
                    "coc": "Davranış kodu"
                },
                "accepted": "Bu dokümanı {time} tarihinde kabul ettiniz"
            },
            "navigator": {
                "eula": "Anlaşmalar",
                "settings": "Ayarlar"
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "description": "Güven ve güvenlik ekibi, tüm Warsaw Revamped platformlarında kötü niyetli davranışlarla mücadele ederek çevrimiçi güvenliği sağlayarak Warsaw Revamped kullanıcılarını koruma konusunda kritik bir sorumluluğa sahiptir.",
                    "info": {
                        "coc": {
                            "description": "Trust & Safety ekip üyeleri, Davranış Kurallarını sürdürmek için WR Ekibi ile yakın bir şekilde çalışır. Bu, kuralların her zaman güncel tutulduğu ve platformun herkes için güvenli ve eğlenceli bir yer olması sağlandığı anlamına gelir.",
                            "title": "Davranış Kurallarında Değişiklikler"
                        },
                        "help": {
                            "title": "Oyuncu desteği",
                            "description": "Kullanıcılara mümkün olan en iyi deneyimi sağlamak için hesabınız, güvenliğiniz ve özellikleriniz ile ilgili biletleri yanıtlayacak deneyimli bir Oyuncu Destek ekibimiz var."
                        },
                        "protect": {
                            "title": "Moderatörlük",
                            "description": "Moderatörler, kuralları ihlal eden kullanıcıları uyarmalarına ve hatta onları WR platformundan yasaklamalarına izin veren araçlara erişimlerini kullanarak Davranış Kurallarını uygular."
                        },
                        "badge": {
                            "title": "",
                            "description": "Trust & Safety ekip üyeleri, masum kullanıcıları korumak ve 'kötü adamlara' göz kulak olmak için WR platformunu özel olarak izleyen koruyuculardır. Onları benzersiz rozetlerinden tanıyabilirsiniz."
                        }
                    },
                    "short": "",
                    "long": "Trust & Safety Ekibi"
                },
                "dev": {
                    "long": "Development Ekibi",
                    "description": "Devolopment ekibi, Warsaw Revamped'in geliştirilmesinden sorumludur."
                },
                "verified": {
                    "description": "Warsaw Revampedi'de doğrulanmış hesap rozeti, bu hesabın gerçek olduğunu ve Warsaw Revampen topluluğunda önemli bir rol oynayan üyelere verildiğini gösterir",
                    "info": {
                        "badge": {
                            "description": "Doğrulanmış accountlar, profillerindeki doğrulama onay işaretiyle tanınabilir, bu da Warsaw Revamped topluluğundan önemli bir kişiyi anında tanımanıza olanak tanır.",
                            "title": "Tanıma"
                        },
                        "active": {
                            "title": "Aktif",
                            "description": "Doğrulanmış accountlar, Warsaw Revamped topluluğuna aktif olarak katılır. Hesaplarını aktif olarak kullanırlar ve çoğu durumda içerik oluştururlar."
                        }
                    },
                    "short": "",
                    "long": ""
                },
                "qa": {
                    "long": "",
                    "description": "Quality Assurance ekibi, son teknoloji yapılardaki hataları test eder.",
                    "short": ""
                },
                "admin": {
                    "short": "",
                    "long": "",
                    "description": "Yöneticiler çeşitli idari görevlerden sorumludur ve günlük işlemleri denetler."
                },
                "staff": {
                    "short": "Personel",
                    "long": "Warsaw Revamped personel",
                    "description": "Personel"
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "Daha oku",
                "more-news": "Daha haber"
            }
        },
        "sentry": {
            "alert-bar": {
                "send_feedback": "Geribildirim yolla",
                "description": "Warsaw Dragon ile ilgili bir sorun tespit ettik, geri bildirim göndererek iyileştirmemize yardımcı olun"
            }
        },
        "error": {
            "general_error_title": "Hata...!",
            "page_not_found": {
                "title": "Sayfa bulunamadı",
                "description": "Ulaşmaya çalıştığınız sayfa mevcut değil."
            },
            "buttons": {
                "return": "Dönüş",
                "go_home": "Ana sayfaya dön",
                "sign_in": ""
            },
            "profile_unavailable": {
                "title": "Profil mevcut değil",
                "account_suspended": "Bu account şu anda Warsaw Revamped'den askıya alındı.",
                "load_api_error": "Aradığınız profil yüklenemedi."
            },
            "require_sign_in": {
                "title": "Oturum açılmamış",
                "description": "Bu içeriği görüntülemek için Warsaw kimliğinizle oturum açmalısınız."
            }
        },
        "notifications": {
            "email-not-activated": "Warsaw Revamped'de oynayabilmek için e-postanızın doğrulanması gerekir."
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "description": "Gezinme çubuğundaki ay veya güneşi tıklayarak bu deneysel özelliği etkinleştirin",
                "name": "karanlık mod"
            }
        },
        "general": {
            "buttons": {
                "learn-more": "",
                "see-more-feedback": "",
                "other-platforms": "Diğer platformlar",
                "download": "Indir",
                "help": "Yardim",
                "retry": "Yeniden dene"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Geri bildirim sunucularına bağlanılamadı, lütfen daha sonra tekrar deneyin."
                }
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "stats": "{badge}: {percentage} oyuncu tarafından elde edildi",
                    "obtained": "{time} önce alındı"
                },
                "type": {
                    "exclusive": {
                        "description": "Bu badge elde edilemez."
                    },
                    "achievement": {
                        "title": "Kazanım"
                    }
                }
            },
            "error": {
                "banned": {
                    "title": "Bu kullanıcı, Warsaw Revamped'den uzaklaştırıldı",
                    "description": "Bu kullanıcı şu anda {code-of-conduct} ve {terms-of-service} ihlal ettiği için askıya alındı."
                },
                "not_found": {
                    "title": "Üzgünüz, bu ada sahip kullanıcı yok"
                }
            }
        }
    }
}
