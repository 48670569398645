{
    "general": {
        "buttons": {
            "return-to-home": "Вернуться на главную страницу"
        }
    },
    "header": {
        "my-account": {
            "my-id": "Мой Warsaw ID",
            "my-profile": "Мой профиль",
            "preferences": "Мои настройки",
            "signout": "Выйти"
        },
        "services": {
            "wr-help": "Помощь WR",
            "wr": "Домашняя страница",
            "wr-labs": "Лаборатории"
        },
        "tooltips": {
            "sign-in": "Войти или создать аккаунт"
        }
    },
    "error": {
        "error-404": {
            "info": "Извините, страница, которую вы пытаетесь открыть, не существует.",
            "title": "Страница не найдена",
            "page-title": "Страница не найдена"
        }
    },
    "footer": {
        "about": {
            "open-positions": "Вакансии",
            "title": "О нас",
            "us-on-gitlab": "Warsaw Revamped на Gitlab",
            "team": "Наша Команда",
            "join-team": "Присоединиться к команде"
        },
        "legal": {
            "legal_c": "НИ ОДИН ПРОИЗВОДИТЕЛЬ ОРУЖИЯ, ТЕХНИКИ ИЛИ СНАРЯЖЕНИЯ НЕ ИМЕЕТ ОТНОШЕНИЯ К ЭТОЙ ИГРЕ ИЛИ НЕ ЯВЛЯЕТСЯ СПОНСОРОМ ЛИБО РЕКЛАМОДАТЕЛЕМ ЭТОЙ ИГРЫ, ИСКЛЮЧАЯ ЯВНО УКАЗАННЫЕ СЛУЧАИ.",
            "legal_b": "Мы ни в какой форме не связаны с Electronic Arts Inc. или EA Digital Illusions CE AB (также известной как DICE). Battlefield® и Battlefield 4® являются зарегистрированными товарными знаками Electronic Arts Inc. Все названия компаний, продуктов и сервисов, логотипы и бренды, а также любые другие материалы, защищенные авторским правом, являются собственностью их соответствующих владельцев и используются только в целях идентификации. Использование этих материалов не означает одобрения.",
            "legal_a": "© {year} Warsaw Revamped. Все права защищены"
        },
        "logo": {
            "title": "Создано"
        },
        "game": {
            "play": "Играть",
            "insider": "WR Insiders"
        },
        "eula": {
            "title": "Пользовательские соглашения",
            "terms-of-service": "Условия обслуживания",
            "privacy-policy": "Политика конфиденциальности",
            "code-of-conduct": "Кодекс поведения",
            "cookie-policy": "Политика Cookie"
        },
        "dragon": {
            "title": "Работает на"
        },
        "help": {
            "title": "Помощь Warsaw",
            "contact-us": "Связаться с нами",
            "faq": "FAQ",
            "feedback": "Обратная связь",
            "status": "Статус"
        }
    }
}
