{
    "page": {
        "home": {
            "title": "Startseite"
        },
        "download": {
            "title": "Herunterladen"
        }
    },
    "home": {
        "title": "Startseite",
        "description": "Die neusten Nachrichten und Updates für alles rund um Warsaw Revamped: die Modding-Plattform für Battlefield 4™."
    },
    "play": {
        "title": "Spielen",
        "description": "Erstelle dein eigenes Battlefield 4™ Spielerlebnis. Spiele, entdecke und erstelle."
    },
    "insider": {
        "title": "WR-Insider",
        "description": "Entwickelt, um die Battlefield™ Community zusammenzubringen. Content-Creator und engagierte Spieler sind herzlich eingeladen, unserem WR-Insider-Programm beizutreten."
    },
    "roadmap": {
        "title": "Roadmap",
        "description": "Die Warsaw Revamped Roadmap beschreibt die Inhalte ab dem ersten Tag, einschließlich des Spielens gegen KI-Soldaten für Solo, Co-op und Multiplayer."
    },
    "401": {
        "title": "Nicht autorisiert",
        "description": "Um diese Seite zu sehen musst du mit deiner Warsaw-ID angemeldet sein, und die erforderlichen Berechtigungen haben."
    },
    "404": {
        "title": "Nicht gefunden",
        "description": "Die Seite, die du suchst, konnte nicht gefunden werden. Brauchst du Hilfe? Dann gehe auf help.warsaw-revamped.com für weitere Informationen."
    },
    "legal": {
        "cookie-policy": {
            "title": "Cookie-Richtlinien",
            "description": "Erfahre, welche Cookies wir Sammeln und auf deinem Gerät speichern, wenn du unsere Webseite besuchst und auf unsere Dienste zugreifst."
        },
        "privacy-policy": {
            "title": "Datenschutz-Bestimmungen",
            "description": "Erfahre, wie wir sensible Daten handhaben, speichern und schützen, und die Integrität unserer Plattform für unsere Nutzer schützen."
        },
        "code-of-conduct": {
            "title": "Verhaltenskodex"
        },
        "terms-of-service": {
            "title": "Allgemeine Geschäftsbedingungen"
        },
        "ugc-policy": {
            "title": "Richtlinie für benutzergenerierte Inhalte"
        },
        "video-policy": {
            "title": "Videorichtlinie"
        }
    },
    "launcher": {
        "authenticate": {
            "title": "Authentifizierung"
        }
    },
    "open-positions": {
        "title": "Offene Stellen"
    }
}
