{
    "page": {
        "home": {
            "title": "Ev"
        },
        "download": {
            "title": "Indir"
        }
    }
}
