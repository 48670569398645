export var SLOT_NAME_ADD_BUTTON_TEXT = 'add-button-text';
export var SLOT_NAME_APPEND = 'append';
export var SLOT_NAME_ASIDE = 'aside';
export var SLOT_NAME_BADGE = 'badge';
export var SLOT_NAME_BOTTOM_ROW = 'bottom-row';
export var SLOT_NAME_BUTTON_CONTENT = 'button-content';
export var SLOT_NAME_CUSTOM_FOOT = 'custom-foot';
export var SLOT_NAME_DECREMENT = 'decrement';
export var SLOT_NAME_DEFAULT = 'default';
export var SLOT_NAME_DESCRIPTION = 'description';
export var SLOT_NAME_DISMISS = 'dismiss';
export var SLOT_NAME_DROP_PLACEHOLDER = 'drop-placeholder';
export var SLOT_NAME_ELLIPSIS_TEXT = 'ellipsis-text';
export var SLOT_NAME_EMPTY = 'empty';
export var SLOT_NAME_EMPTYFILTERED = 'emptyfiltered';
export var SLOT_NAME_FILE_NAME = 'file-name';
export var SLOT_NAME_FIRST = 'first';
export var SLOT_NAME_FIRST_TEXT = 'first-text';
export var SLOT_NAME_FOOTER = 'footer';
export var SLOT_NAME_HEADER = 'header';
export var SLOT_NAME_HEADER_CLOSE = 'header-close';
export var SLOT_NAME_ICON_CLEAR = 'icon-clear';
export var SLOT_NAME_ICON_EMPTY = 'icon-empty';
export var SLOT_NAME_ICON_FULL = 'icon-full';
export var SLOT_NAME_ICON_HALF = 'icon-half';
export var SLOT_NAME_IMG = 'img';
export var SLOT_NAME_INCREMENT = 'increment';
export var SLOT_NAME_INVALID_FEEDBACK = 'invalid-feedback';
export var SLOT_NAME_LABEL = 'label';
export var SLOT_NAME_LAST_TEXT = 'last-text';
export var SLOT_NAME_LEAD = 'lead';
export var SLOT_NAME_LOADING = 'loading';
export var SLOT_NAME_MODAL_BACKDROP = 'modal-backdrop';
export var SLOT_NAME_MODAL_CANCEL = 'modal-cancel';
export var SLOT_NAME_MODAL_FOOTER = 'modal-footer';
export var SLOT_NAME_MODAL_HEADER = 'modal-header';
export var SLOT_NAME_MODAL_HEADER_CLOSE = 'modal-header-close';
export var SLOT_NAME_MODAL_OK = 'modal-ok';
export var SLOT_NAME_MODAL_TITLE = 'modal-title';
export var SLOT_NAME_NAV_NEXT_DECADE = 'nav-next-decade';
export var SLOT_NAME_NAV_NEXT_MONTH = 'nav-next-month';
export var SLOT_NAME_NAV_NEXT_YEAR = 'nav-next-year';
export var SLOT_NAME_NAV_PEV_DECADE = 'nav-prev-decade';
export var SLOT_NAME_NAV_PEV_MONTH = 'nav-prev-month';
export var SLOT_NAME_NAV_PEV_YEAR = 'nav-prev-year';
export var SLOT_NAME_NAV_THIS_MONTH = 'nav-this-month';
export var SLOT_NAME_NEXT_TEXT = 'next-text';
export var SLOT_NAME_OVERLAY = 'overlay';
export var SLOT_NAME_PAGE = 'page';
export var SLOT_NAME_PLACEHOLDER = 'placeholder';
export var SLOT_NAME_PREPEND = 'prepend';
export var SLOT_NAME_PREV_TEXT = 'prev-text';
export var SLOT_NAME_ROW_DETAILS = 'row-details';
export var SLOT_NAME_TABLE_BUSY = 'table-busy';
export var SLOT_NAME_TABLE_CAPTION = 'table-caption';
export var SLOT_NAME_TABLE_COLGROUP = 'table-colgroup';
export var SLOT_NAME_TABS_END = 'tabs-end';
export var SLOT_NAME_TABS_START = 'tabs-start';
export var SLOT_NAME_TEXT = 'text';
export var SLOT_NAME_THEAD_TOP = 'thead-top';
export var SLOT_NAME_TITLE = 'title';
export var SLOT_NAME_TOAST_TITLE = 'toast-title';
export var SLOT_NAME_TOP_ROW = 'top-row';
export var SLOT_NAME_VALID_FEEDBACK = 'valid-feedback';