import {InMemoryCache} from "@apollo/client/core";

export const cache = new InMemoryCache({
    typePolicies: {
        Product: {
            // In an inventory management system, products might be identified
            // by their UPC.
            keyFields: ["upc"],
        },
        Person: {
            // In a user account system, the combination of a person's name AND email
            // address might uniquely identify them.
            keyFields: ["name", "email"],
        },
        Book: {
            // If one of the keyFields is an object with fields of its own, you can
            // include those nested keyFields by using a nested array of strings:
            keyFields: ["title", "author", ["name"]],
        },
        AllProducts: {
            // Singleton types that have no identifying field can use an empty
            // array for their keyFields.
            keyFields: [],
        },
    },
});
