/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

// @ts-ignore
/**
 * Sample configuration file used by the Gitlab CI/CD and automatically populated on deployment.
 * You can fully customize this on your own workstation or add GitLab CI/CD variables.
 * @author Firjen <firjen@warsaw-revamped.com>
 * @copyright Warsaw Revamped (c)
 */
export const Config = {
    general: {
      websiteName: "Warsaw Revamped"
    },

    /**
     * Configuration related to debug
     */
    debug: {
        /**
         * Force maintenance page
         */
        eventMaintenance: false
    },

    /**
     * Authentication using Keycloak
     */
    authentication: {
        /**
         * Enable user authentication
         */
        enabled: true,

        server: "https://id.warsaw-revamped.com",

        onload: 'check-sso',

        realm: "warsaw-revamped",

        clientId: "warsaw-website"
    },

    /**
     * Client storage data
     */
    clientLocalStorage: {
        /**
         * Prefix for Warsaw related stuff
         */
        storagePrefix: "wr"
    },

    /**
     * Configuration related to version control
     */
    versionControl: {
        versionControlKey: "dragon.version_control",

        /**
         * Clear storage settings on new release.
         * Will not reset persistent storage data.
         */
        clearStorageOnNewRelease: {
            /**
             * Enable to remove all storage keys on new update
             */
            enabled: true,

            /**
             * List of storage keys to keep across new updates
             */
            persistentStorage: ["wr:account.token.bearer"] // TODO: The storage prefix has to be removed here and where this is used it must use the clientLocalStorage.storagePrefix variable
        }
    },

    /**
     * Sentry allows the dev team to catch JS exceptions and forward it to Gitlab
     * Documentation is available at https://docs.sentry.io/
     */
    sentry: {
        /**
         * Enable Sentry
         */
        enable: true,

        /**
         * User Sentry features
         */
        userFeatures: {
            /**
             * Ask for feedback from the user when an error occurs.
             */
            askForFeedback: false
        },

        /**
         * Data Source name
         */
        dsn: "https://c850cad8fec844608b564690906110e1@sentry.warsaw-revamped.com/9",

        /**
         * Origins to capture from
         */
        allowedOrigins: ["localhost", "127.0.0.1:1234", "127.0.0.1", "http://dev.warsaw-revamped.com", "https://warsaw-revamped.com"],

        /**
         * Set to 1.0 to capture 100% of transactions.
         * Adjusting this value will decrease transaction capture rate.
         */
        sampleRate: 1.0 // TODO: Check with Neutron about the Sentry sample rate.
    },

    /**
     * Google analytics settings
     */
    analytics: {
        /**
         * Enable Google Analytics
         */
        enable: false,

        /**
         * Analytics ID
         */
        tag: "G-ZRHV3572DG"
    },

    /**
     * Specific features
     */
    Features: {
        /**
         * Layout related
         */
        Layout: {
            /**
             * Allow the website to automatically detect the current color scheme of the user
             * and enable dark mode if they have it enabled in their browser or OS.
             */
            allowAutomaticDarkMode: false,
        },

        /**
         * Play/download feature
         */
        play: {
            enabled: false
        }
    },

    /**
     * Configuration related to the Unleash implementation
     */
    Unleash: {
        /**
         * Is Unleash Enabled?
         */
        Enable: false,

        /**
         * Url of the Unleash server
         */
        URL: "https://unleash.warsaw-revamped.com/proxy",

        /**
         * App name
         */
        app_name: "dragon",

        /**
         * Client key of the Unleash server
         */
        client_key: "gvtpk84vkIi3pr5q1C9ww1vK9JJ0nYhNykaugpss"
    },

    API: {
        poseidon: {

        }
    }
};