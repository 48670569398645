{
    "en": {
        "nav": {
            "primary": {
                "home": "Startseite",
                "mode": {
                    "dark_on": "Nachtmodus aktivieren (experimentell)",
                    "dark_off": "Nachtmodus deaktivieren (experimentell)"
                },
                "play_button": "Spielen"
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Anmelden oder Konto erstellen"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "Trust & Safety",
                    "long": "Trust & Safety-Team",
                    "description": "Das Trust & Safety-Team trägt die entscheidende Verantwortung, die Nutzer von Warsaw Revamped zu schützen, indem es die Online-Sicherheit gewährleistet und missbräuchliches Verhalten auf allen Warsaw Revamped Plattformen bestraft.",
                    "info": {
                        "help": {
                            "title": "Spieler-Support",
                            "description": "Um allen Nutzern den bestmöglichen Service bieten zu können, verfügen wir über ein erfahrenes Spieler-Support-Team, welches Eure Tickets zu Fragen in Bezug auf Konto, Sicherheit und Features beantworten wird."
                        },
                        "protect": {
                            "title": "Moderieren",
                            "description": "Moderatoren setzen den Verhaltenskodex durch, indem sie ihren Zugriff auf spezielle Tools nutzen, mit denen sie Nutzer, die gegen Regeln verstoßen, warnen oder von der WR-Plattform bannen können."
                        },
                        "badge": {
                            "title": "Erkennung",
                            "description": "Trust & Safety-Teammitglieder sind Wächter, welche die WR-Plattform überwachen, um unschuldige Nutzer zu schützen und um die \"Bösen\" im Auge zu behalten. Man kann sie an einem einzigartigen Abzeichen erkennen."
                        },
                        "coc": {
                            "title": "Änderung des Verhaltenskodex",
                            "description": "Trust & Safety-Teammitglieder arbeiten eng mit dem WR-Team zusammen, um den Verhaltenskodex einzuhalten. Das bedeutet, dass die Regeln immer auf dem neusten Stand gehalten werden und dass die Plattform ein sicherer und angenehmer Ort für alle ist."
                        }
                    }
                },
                "admin": {
                    "short": "Administrator",
                    "long": "Warsaw Revamped Administrator",
                    "description": "Die Administratoren sind für verschiedenste Verwaltungsaufgaben zuständig und beaufsichtigen den alltäglichen Betrieb."
                },
                "qa": {
                    "short": "Qualitätssicherung",
                    "long": "Qualitätssicherung",
                    "description": "Das Qualitätssicherungsteam testet die neusten Versionen nach Bugs."
                },
                "dev": {
                    "short": "Entwickler",
                    "long": "Entwicklerteam",
                    "description": "Das Entwicklerteam ist für die Entwicklung von Warsaw Revamped zuständig."
                },
                "staff": {
                    "short": "Mitarbeiter",
                    "long": "Warsaw Revamped Mitarbeiter",
                    "description": "Mitarbeiter"
                },
                "verified": {
                    "short": "Verifiziert",
                    "long": "Verifiziertes Konto",
                    "description": "Das Abzeichen \"verifiziertes Konto\" zeigt, dass das Konto authentisch ist und wird Mitgliedern verliehen, die eine wichtige Rolle in der Warsaw Revamped Community spielen",
                    "info": {
                        "active": {
                            "title": "Aktiv",
                            "description": "Verifizierte Konten sind aktiv in der Warsaw Revamped Community involviert. Sie nutzen ihr Konto aktiv und erstellen in vielen Fällen Inhalte."
                        },
                        "badge": {
                            "title": "Erkennung",
                            "description": "Verifizierte Konten können an dem Prüfzeichen \"Verifiziertes Konto\" auf ihrem Profil erkannt werden, welches es dir erlaubt, sofort eine wichtige Person der Warsaw Revamped Community zu erkennen."
                        }
                    }
                }
            }
        },
        "page": {
            "insider": {
                "faq": {
                    "who": {
                        "title": "Für wen ist das WR-Insider-Programm reserviert?",
                        "description": "Das Ziel des WR-Insider-Programms ist es, jedem die Möglichkeit zu geben, als ein WR-Insider einem Spiel beizutreten. Falls du also extrem motiviert bist, immer noch regelmäßig Battlefield 4™ spielst und/oder dem Franchise einen Discord-Server, eine Fanseite, ein Forum oder eine Redditseite gewidmet hast, dann qualifizierst du dich als WR-Insider!\n\nTwitch-Streamer und YouTube-Creator sind auch als WR-Insider qualifiziert und herzlich eingeladen, Warsaw Revamped früher als andere auszutesten und ihren Content mit ihren Zuschauern und anderen zu teilen.\n\nNeben leidenschaftlichen Battlefield 4™-Spielern, Streamern und Video-Creator, sind auch engagierte Modder als WR-Insider qualifiziert!"
                    },
                    "what": {
                        "title": "Was ist das WR-Insider Programm?",
                        "description": "Das WR-Insider-Programm wurde entwickelt, um die gespaltene Battlefield™ -Community zusammenzubringen und um zu zeigen, wozu WR in der Lage ist. Als Gamer haben wir eines gemeinsam: Wir lieben dieses Franchise. Um unsere Liebe für Battlefield 4™ zu zeigen und um einen Blick hinter die Kulissen zu ermöglichen, haben wir das WR-Insider-Programm ins Leben gerufen."
                    },
                    "advantages": {
                        "title": "Welche Vorteile bringt es, ein WR-Insider zu sein?",
                        "description": "WR-Insider haben Zugriff auf einen exklusiven Discord-Bereich, in dem wir regelmäßig nach Feedback zu neuen Features, Designs oder Implementierungsideen fragen.\n\nWR-Insider bekommen exklusiv Early-Access auf neue Features, die Modding-API eingeschlossen, um frühzeitig mit dem Erstellen von Mods zu beginnen.\n\nWR-Insider haben exklusiv Early-Access auf Playtests während der Closed-Alpha und in Zukunft auf die Closed-Beta, sobald Warsaw Revamped die Beta-Phase erreicht hat. WR-Insider erhalten als erstes Early-Access auf Warsaw Revamped."
                    }
                },
                "description": "Hilf uns Warsaw Revamped aufzubauen",
                "join": {
                    "title": "Bist du interessiert, dem WR-Insider-Programm beizutreten?",
                    "description": "Sende uns eine E-Mail und erkläre, warum du ein WR-Insider werden solltest. Sende eine Bewerbung, die jegliche vorherige Erfahrung und deine Motivation beinhaltet, an {email}. Vergiss nicht, uns deinen Discord Namen und Tag zu schreiben, damit wir dich über Discord erreichen können."
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "Wir haben ein Problem mit Warsaw Dragon entdeckt. Hilf uns beim Verbessern, indem du uns Feedback sendest",
                "send_feedback": "Sende Feedback"
            },
            "modal": {
                "placeholder": "Ich habe ... angeklickt und dann ... gedrückt"
            }
        },
        "error": {
            "general_error_title": "Hoppla...!",
            "page_not_found": {
                "title": "Seite nicht gefunden",
                "description": "Die Seite, auf die du zugreifen willst, existiert nicht."
            },
            "profile_unavailable": {
                "title": "Profil nicht verfügbar",
                "account_suspended": "Dieses Konto ist derzeit von Warsaw Revamped suspendiert.",
                "load_api_error": "Das gesuchte Profil konnte nicht gefunden werden."
            },
            "require_sign_in": {
                "title": "Nicht angemeldet",
                "description": "Um diese Inhalte sehen zu können, musst du dich mit deiner Warsaw-ID anmelden."
            },
            "buttons": {
                "return": "Zurück",
                "go_home": "Zurück zur Startseite",
                "sign_in": "Anmelden"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "Konto-Einschränkungen",
                    "description": "Konten können basierend auf ihrem Gesundheitsstatus vorübergehende Einschränkungen erhalten, dies beinhaltet die Kontoaktivierung und die Aktivierung der Multi-Faktor-Authentifizierung. Der Erhalt von Sperrungen, Warnungen oder anderen Sanktionen kann auch dazu führen, dass dein Konto eingeschränkt wird."
                },
                "features": {
                    "host": {
                        "title": "Hoste deinen eigenen Warsaw Revamped Server",
                        "suspended": "Dein Zugriff zum Server-Hosting auf Warsaw Revamped wurde vom Trust & Safety-Team aufgrund von Verletzungen der Nutzungsbedingungen gesperrt.",
                        "no_discord": "Du musst dein Discord-Konto mit deiner Warsaw-ID verknüpfen.",
                        "ok": "Du kannst öffentliche und private Warsaw Revamped Server hosten."
                    },
                    "play": {
                        "title": "Spiele auf Warsaw Revamped Servern",
                        "suspended": "Dein Spielzugriff auf die Warsaw Revamped Server wurde vom Trust & Safety-Team aufgrund von Verletzungen der Nutzungsbedingungen gesperrt.",
                        "ok": "Du kannst auf öffentlichen und privaten Warsaw Revamped Servern spielen."
                    }
                },
                "alert": {
                    "title": "Dein Konto wurde eingeschränkt!",
                    "more-information": "Weitere Informationen",
                    "suspension": {
                        "any": {
                            "permanent": "Dein Konto wurde aufgrund von {reason} dauerhaft von Warsaw Revamped gesperrt.",
                            "temporarily": "Dein Konto wurde aufgrund von {reason} für {time} von Warsaw Revamped gesperrt."
                        },
                        "host": {
                            "permanent": "Dein Konto wurde aufgrund von {reason} dauerhaft vom hosten von Servern ausgeschlossen.",
                            "temporarily": "Dein Konto wurde aufgrund von {reason} für {time} vom hosten von Servern ausgeschlossen."
                        }
                    }
                }
            },
            "navigator": {
                "settings": "Einstellungen",
                "eula": "Vereinbarungen"
            },
            "settings": {
                "page_title": "Mein Konto",
                "navigation": {
                    "settings": "Einstellungen",
                    "experimental_features": "Experimentelle Features"
                },
                "experimental_features": {
                    "title": "Experimentelle Features",
                    "description": "Wir veröffentlichen neue Features oft nur teilweise, um Rückmeldungen zu sammeln, bevor wir sie für alle veröffentlichen. Die unten aufgeführten Features sind derzeit in einer experimentellen Phase und sind möglicherweise nicht endgültig. Wir freuen uns über Rückmeldungen zu diesen Features.",
                    "no_features_available": "Derzeit sind keine experimentellen Features für dein Konto aktiviert.",
                    "warning_test_feature": "Dieses experimentelle Feature wird nur an eine begrenzte Anzahl an Nutzern herausgegeben, damit wir vor der vollständigen Veröffentlichung nach Problemen suchen können."
                }
            },
            "eula": {
                "page_title": "Nutzungsvereinbarungen",
                "title": "Endnutzer-Lizenzvereinbarung",
                "description": "Sieh und lies dir die Nutzungsvereinbarungen durch, denen du zugestimmt hast",
                "documents": {
                    "terms": "Allgemeine Geschäftsbedingungen",
                    "coc": "Verhaltenskodex"
                },
                "accepted": "Du hast dieses Dokument am {time} akzeptiert"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Nachtmodus",
                "description": "Aktiviere dieses experimentelle Feature, indem du in der Navigationsleiste auf den Mond oder die Sonne klickst"
            }
        },
        "news": {
            "mini": {
                "read-more": "Mehr lesen",
                "more-news": "Weitere Neuigkeiten"
            }
        },
        "notifications": {
            "email-not-activated": "Du musst deine E-Mail verifizieren, bevor du Warsaw Revamped spielen kannst."
        },
        "general": {
            "buttons": {
                "learn-more": "Weitere Informationen",
                "see-more-feedback": "Weiteres Feedback anzeigen",
                "other-platforms": "Andere Plattformen",
                "download": "Herunterladen",
                "help": "Hilfe",
                "retry": "Erneut versuchen",
                "ok": "OK",
                "continue": "Weiter"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Fehler beim Verbinden zu den Feedback-Servern. Versuche es später erneut."
                }
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "stats": "{badge}: Erreicht von {percentage} der Spieler",
                    "obtained": "vor {time} erhalten",
                    "levels": "{badge} Level"
                },
                "type": {
                    "exclusive": {
                        "description": "Dieses Abzeichen ist nicht erhältlich.",
                        "title": "Exklusiv"
                    },
                    "achievement": {
                        "title": "Errungenschaft"
                    }
                }
            },
            "error": {
                "banned": {
                    "title": "Dieser Nutzer wurde von Warsaw Revamped gesperrt",
                    "description": "Dieser Nutzer ist zurzeit wegen Verstößen gegen unseren {code-of-conduct} und unsere {terms-of-service} gesperrt."
                },
                "not_found": {
                    "title": "Es gibt keine Nutzer mit diesem Namen",
                    "description": "Wir konnten keine Nutzer mit diesem Namen finden."
                },
                "general": {
                    "title": "Hoppla! Etwas ist schiefgelaufen",
                    "description": "Dieses Profil kann zurzeit nicht angezeigt werden. Versuche es später erneut."
                },
                "server_not_found": {
                    "title": "Dieses Server-Profil ist nicht verfügbar",
                    "description": "Entweder ist dieser Server privat, oder er existiert nicht mehr."
                }
            }
        },
        "home": {
            "important-features": {
                "title": "Spannende Funktionen",
                "more": "Sieh dir an, was wir noch geplant haben",
                "host-server": "Starte mit nur einem Klick in unserem Launcher einen Server auf deinem eigenen PC. Oder hoste Ihn dauerhaft überall unter Windows, mit unserem Docker-Container oder Pterodactyl unter Linux.",
                "ai-bots": "Volle Unterstützung für Bots. Lass sie frei herumlaufen, passe Ihr Verhalten nach Bedarf an oder übernimm selbst die Kontrolle durch unsere Modding-API. Lass deiner Fantasie freien Lauf. Sei kreativ!",
                "modding-api": "Voll funktionsfähige Modding-API in Lua mit integrierten Werkzeugen. Ändere das gesamte Spielverhalten, erstelle völlig neue Spielmodi oder komplett neue Inhalte mit Rime."
            },
            "roadmap": {
                "items": {
                    "bot": {
                        "title": "KI-Bots",
                        "description": "Lass sie frei herumlaufen, passe ihr Verhalten nach Bedarf an, oder übernehmen die volle Kontrolle mithilfe der Modding-API, um deine eigenen Erlebnisse zu erschaffen.",
                        "short-description": "Erstklassiger Support für intelligente Bots"
                    },
                    "dedicated-servers": {
                        "title": "Dedizierte Server",
                        "short-description": "Anpassbare, dedizierte Server, die von jedem gestartet werden können, mit bis zu 128 Spielern",
                        "description": "Starte mit nur einem Klick in unserem Launcher einen Server auf deinem eigenen PC. Oder hoste Ihn dauerhaft überall unter Windows, mit unserem Docker-Container oder Pterodactyl unter Linux."
                    },
                    "modding-api": {
                        "short-description": "Individualisiere das Spiel und erweitere es nach Herzenslust mithilfe von Lua, C# oder JavaScript",
                        "title": "Modding-API"
                    },
                    "anti-cheat": {
                        "title": "Anti-Cheat",
                        "short-description": "Verbesserte und innovative Anti-Cheat-Maßnahmen"
                    },
                    "core-game": {
                        "title": "Eigenes Backend & Zuverlässigkeit des Kernspiels",
                        "short-description": "Vollständig angepasstes Backend für verbesserte Ausfallsicherheit und DDoS-Schutz im Spiel",
                        "description": "Unser selbst erstelltes Backend namens Poseidon, Ausgestattet mit Statistiken und freischaltbarer Unterstützung, erlaubt es uns, dir ein besseres und zuverlässigeres Spielerlebnis bieten zu können, und neue Funktionen schneller zu entwickeln. Unser eigener DDoS-Schutz ermöglicht eine höhere Abwehrstärke gegen Angriffe."
                    }
                },
                "status": {
                    "planned": "Geplant",
                    "in_progress": "fortschritt",
                    "done": "Fertiggestellt",
                    "scoping": "Aussortiert"
                },
                "title": "",
                "release-note": {
                    "v1-release": "Für Erstveröffentlichung geplant",
                    "post-release": "Nach der Erstveröffentlichung/ Live-Support"
                }
            },
            "insiders": {
                "description": "Dieses Programm wurde entwickelt, um die gespaltene Battlefield 4™-Community zusammenbringen und um zu zeigen, wozu WR fähig ist. Reserviert für diejenigen, die extrem motiviert sind, regelmäßig Battlefield 4™ spielen und extrem engagiert sind. Twitch-Streamer und YouTube-Creator sind auch als WR-Insider qualifiziert.",
                "title": "WR-Insider-Programm"
            }
        },
        "play": {
            "hardware-requirements": {
                "requirements": {
                    "memory": {
                        "title": "Speicher",
                        "description": "Mindestens 4 GB RAM, 8 GB empfohlen"
                    },
                    "graphics": {
                        "description": "Mindestens NVIDIA GeForce GTX 660 oder AMD Radeon HD 7870",
                        "title": "Grafikkarte"
                    },
                    "internet": {
                        "title": "Internetverbindung",
                        "description": "Wird für Anmeldung, Mehrspielerspiele und Servererstellung benötigt"
                    },
                    "os": {
                        "description": "Mindestens Windows 8, empfohlen wird Windows 10. Einige Linuxversionen werden unterstützt.",
                        "title": "Betriebssystem"
                    },
                    "hdd": {
                        "description": "Mindestens 30 GB Festplattenspeicher, SSD empfohlen.",
                        "title": "Speicherplatz"
                    },
                    "processor": {
                        "title": "Prozessor",
                        "description": "Intel Quad-Core CPU oder AMD Six-Core CPU empfohlen"
                    }
                },
                "title": "Hardwarevoraussetzungen"
            },
            "download": {
                "unavailable": {
                    "title": "Warsaw Revamped ist noch nicht verfügbar!",
                    "description": "Warsaw Revamped befindet sich noch in Entwicklung, wir veranstalten jedoch häufig Technical-Playtests, damit unsere Community die neusten Features und Änderungen testen kann."
                },
                "downloading": {
                    "fail": {
                        "description": "Fehler beim Download, bitte kontrolliere deine Internetverbindung und versuche es erneut."
                    },
                    "progress": {
                        "prepare": "Dein Download wird vorbereitet...",
                        "description": "Der Launcher wird im Hintergrund heruntergeladen. Verlasse diese Seite nicht, sonst wird dein Download abgebrochen."
                    },
                    "success": {
                        "description": "Dein Download wurde fertiggestellt. Öffne {exe} um die Anwendung zu Installieren"
                    }
                },
                "available": {
                    "alert": {
                        "title": "Entwicklerversion von Warsaw Revamped",
                        "description": "Du hast exklusiven zugriff auf eine Entwicklerversion von Warsaw Revamped. Diese Version wird oft aktualisiert und wird Bugs und Fehler enthalten. Wir würden uns Freuen, wenn du uns die Probleme meldest, damit wir uns darum kümmern können. Das teilen des Launchers mit Dritten Ohne Erlaubnis von Warsaw Revamped ist nicht gestattet."
                    },
                    "title": "Lade Warsaw Revamped herunter",
                    "title_progress": "Warsaw Revamped wird heruntergeladen",
                    "description": "Blablabla",
                    "select_platform": "Unterstützung für Linux is noch in einer sehr frühen Phase und funktioniert möglicherweise nicht richtig.",
                    "in_progress": "Download läuft"
                }
            }
        },
        "open-positions": {
            "title": "Offene Stellen",
            "who-we-are": {
                "title": "Wer sind Wir?",
                "prg1": "Warsaw Revamped wird von einem Team geleitet, welches von der ganzen Welt aus zusammenarbeitet und dessen Entwicklung vorantreibt. Von professionellen Programmierern bzw. Entwicklern bis hin zu Newcommern ist alles dabei. Unsere freiwilligen Mitarbeiter vereinen verschiedene Hintergründe, aber alle haben eins gemeinsam: Die Leidenschaft für Battlefield 4. Natürlich wird die Community dabei nicht vergessen da sie ein elementarer Teil ist.",
                "prg2": "Zurzeit sind wir ein Team von mehr als 10 Personen, und decken alle Rollen der Spieleentwicklung, Design, Community-Management usw. ab."
            },
            "tips": {
                "title": "Einige Tipps, bevor du dich bewirbst",
                "tip1": "Zeige uns, was du in der Position bis jetzt schon geleistet hast. Das kannst du uns inform von YouTube-Videos, Screenshots, Links zu GitHub oder GitLab usw. schicken. Wir werden bei der ersten Überprüfung von Bewerbern keinen Code überprüfen.",
                "tip2": "Unser Team besteht zu 100% aus freiwilligen Helfern, die keine Vergütung für ihre Arbeit an Warsaw Revamped bekommen. Wir sind nicht kommerziell und erhalten oder sammeln kein Geld.",
                "tip3": "Wenn du keine Antwort erhältst, bedeutet das, dass wir kein Interesse haben deine Bewerbung weitergehend zu verfolgen. Aufgrund der hohen Bewerberanzahl können wir nicht alle Bewerbungen per Hand beantworten."
            },
            "list": {
                "none": {
                    "title": "Keine offenen Stellen",
                    "description": "Zurzeit gibt es keine offenen Stellen."
                }
            }
        },
        "launcher": {
            "auth": {
                "title": "Angemeldet",
                "description": "Du wurdest angemeldet, weiter zum Warsaw Revamped Launcher."
            }
        },
        "team": {
            "meet": {
                "descriptions": {
                    "mrelectrify": "Ich habe das WR-Projekt 2018 mit PolloLoco ins Leben gerufen, nachdem ich ständige DDoS- und Absturzattacken auf meinen Community-Servern ohne Unterstützung von DICE erlebt hatte. Nach Gesprächen über ein Rebalancing mit einigen kompetitiven Spielern war die Modding-Idee geboren. Ich habe während meines Studiums immer wieder an dem Projekt gearbeitet und im August 2020 unsere erste Alpha-Version veröffentlicht. Ich bin verantwortlich für das Spiel und das Backend. Heute bin ich Software-Ingenieur in einem großen Unternehmen und arbeite in meiner Freizeit zum Spaß an WR.",
                    "neutron": "Ich bin seit 2018 dabei und habe MrElectrify dabei geholfen, das Projekt 2020 auf den Weg zu bringen, indem ich die erste Website und Web-API erstellt habe. Seitdem wurde er zum Entwickler für alles Mögliche, vor allem für das Backend, modernisierte Prozesse, machte DevOps-Arbeiten und unterstützte allgemein die anderen Entwickler bei ihren Projekten.",
                    "firjen": "Ich trat dem Projekt Ende 2021 zusammen mit Sjoerd als Webentwickler bei. Seitdem bin ich der leitende Entwickler für den Warsaw Revamped Launcher und Warsaw Dragon, ein Framework, das für alle WR-Websites verwendet wird.",
                    "sjoerd": "Ich bin dem Team von Warsaw Revamped am 24. September 2021 als Community Manager beigetreten. Seit meiner Ankunft bin ich für die Kampagne rund um die Open Alpha 2021 verantwortlich, habe weitere soziale Kanäle wie Twitter und Twitch hinzugefügt, unsere Online-Reichweite vergrößert und mehrere Initiativen wie die wöchentlichen Q&A's auf Reddit, das WR-Insider-Programm und unsere WR-Feedback-Plattform ins Leben gerufen. Als Community Manger halte ich den Kontakt zu den Entwicklern und der Community und betreue verschiedene Teams, organisiere Kampagnen, kümmere mich um die Platzierung von Inhalten und bin für die Öffentlichkeitsarbeit zuständig."
                },
                "title": "Lerne das Team kennen",
                "more": "Und das sind nur einige Leute, die teil von unserem tollen Team sind! Es gibt noch viele weitere, die einen Großteil ihrer Freizeit opfern, um Warsaw Revamped zu verbessern. Sie alle sind an ihrem speziellen Warsaw-Staff Abzeichen zu erkennen. Vergiss nicht Hallo zu sagen, wenn du sie treffen solltest.",
                "fonctions": {
                    "game-lead-dev": "Spiel- und Backend-Entwickler, Gründer",
                    "backend-lead-dev": "Backend-Entwickler & DevOps",
                    "web-ui-ux-lead-dev": "Web & UI/UX Hauptentwickler",
                    "community-manager": "",
                    "game-designer": "Spiele-Designer",
                    "qa-member": "Qualitätssicherung, Tester",
                    "qa-lead": "Qualitätssicherung, Leiter"
                },
                "contributors": "Mitwirkende",
                "core_team": "Kernteam"
            },
            "about": {
                "description": "Warsaw Revamped wird von einem Team geleitet, welches von der ganzen Welt aus zusammenarbeitet und dessen Entwicklung vorantreibt. Von professionellen Programmierern bzw. Entwicklern bis hin zu Newcommern ist alles dabei. Unsere freiwilligen Mitarbeiter vereinen verschiedene Hintergründe, aber alle haben eins gemeinsam: Die Leidenschaft für Battlefield 4. Natürlich wird die Community dabei nicht vergessen da sie ein elementarer Teil ist.",
                "title": "Wer steht hinter Warsaw Revamped?"
            },
            "recruiting": {
                "title": "Tritt dem Warsaw Revamped-Team bei!",
                "description": "Wir suchen ständig nach neuen Leuten, die unsere Leidenschaft für Battlefield 4™ und programmieren teilen. Unser Ziel ist es, eine sichere und inspirierende Modding-Plattform zur Verfügung zu stellen, bei welcher die Community an erster Stelle steht. Unser Projekt hat keinen kommerziellen Hintergrund, wir verdienen damit kein Geld. Es geht lediglich um die Leidenschaft für das Battlefield-Franchise.",
                "join": "Tritt dem Team bei"
            },
            "title": "Lerne das Team kennen"
        }
    }
}
