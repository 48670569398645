/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import LoggerModuleUtil from './utils/logger.module.util';

import store from '../../../store';

/**
 * Warsaw Dragon Module
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export default abstract class IModule {
    /**
     * Warsaw Module logger
     */
    mod_logger: LoggerModuleUtil;

    /**
     * Name of the module
     */
    mod_name: string;

    /**
     * Timestamp when this module was started
     */
    private mod_start: number;

    /**
     * Is the module enabled?
     */
    protected is_enabled: boolean = false;

    protected constructor(name: string) {
        this.mod_name = name;
        this.mod_logger = new LoggerModuleUtil(this);
    }

    /**
     * Populated by each module
     */
    protected async enable() {}

    /**
     * Private function called on enabling module.
     * @private
     */
    __enable() {
        if (this.is_enabled) {
            this.mod_logger.warn("Refused to enable. Module is already enabled");
            return;
        }

        /* Log module start time */
        this.mod_start = Date.now();

        /* Set start */
        this.is_enabled = true;

        this.enable().then(() => {
            this.mod_logger.info(`Module enabled in ${(Date.now() - this.mod_start)} ms`)
        }).catch((error) => {
            store.state.dragon.stopscreenDetails = `dragon_module_${this.mod_name}_enable_failure`;
            store.state.dragon.stopscreen = error;

            this.mod_logger.error(`Failed to start because ${error}.`)
        }).finally(() => {
            /// dragonModLoadTime(this.mod_name, (Date.now() - this.mod_start))
        });
    }

}