import Vue from "vue";
// @ts-ignore
import App from "./app.vue";
import { BootstrapVue } from 'bootstrap-vue'
import router from './router';
import store from './store';

import Meta from 'vue-meta'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './plugins/i18n'
import apollo from './plugins/graphql' // apollo
import Dragon from "./ext/base/dragon/dragon";
import ImagingUtil from "./ext/base/imaging/imaging.util";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import {Deployment} from "./dragon/config/deployment";
import sentryError from "./dragon/modules/sentry/sentry.static";
import VueGtag from "vue-gtag";
import {Config} from "./dragon/config/config";
import {Storage} from "./dragon/config/storage";

Vue.use(BootstrapVue);
Vue.use(Meta);

Vue.use(VueGtag, {
    config: { id: Config.analytics.tag },
    pageTrackerScreenviewEnabled: true
});

Vue.prototype.$dragon = new Dragon();
Vue.prototype.$config = Config;
Vue.prototype.$storage = Storage;
Vue.prototype.$img = ImagingUtil;
Vue.prototype.$apollo = apollo;

Sentry.init({
    Vue,
    release: "dragon@" + Deployment.commit_sha, // Latest commit SHA
    environment: (Deployment.deployment_branch === "master") ? "production" : (Deployment.deployment_branch === "dev") ? "development" : "development-local", // Production environment when deployed on the master branch, otherwise development
    dsn: Config.sentry.dsn,
    integrations: [
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: Config.sentry.allowedOrigins,
        }),
    ],
    tracesSampleRate: Config.sentry.sampleRate,
    beforeSend(event) {
        sentryError(event);
        return event;
    },
});


// @ts-ignore
const vueApp = new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
});


function setup() {
    vueApp.$mount("#app");
}

document.addEventListener("DOMContentLoaded", setup);

// GTAG shit
export function gtagDefaultEvent(event_name) {
    Vue.prototype.$gtag.event(event_name);
}

// GTAG shit
export function dragonModLoadTime(module_name, time) {
    Vue.prototype.$gtag.time('timing_complete', {
        'name' : 'load',
        'value' : time,
        'event_category' : module_name
    });
}