{
    "en": {
        "nav": {
            "primary": {
                "home": "Home",
                "mode": {
                    "dark_on": "Donkere modus inschakelen",
                    "dark_off": "Donkere modus uitschakelen"
                },
                "play_button": "Speel"
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Log in of maak een account"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "Trust & Safety",
                    "long": "Trust & Safety Team",
                    "description": "Het Trust and Safety Team heeft de cruciale verantwoordelijkheid om de gebruikers van Warsaw Revamped te beschermen door te zorgen voor online veiligheid door misbruik te bestrijden op alle platforms van Warsaw Revamped.",
                    "info": {
                        "help": {
                            "title": "Speler Support",
                            "description": "Om gebruikers de best mogelijke speelervaring te bieden, hebben wij een ervaren Player Support team dat je tickets zal beantwoorden voor vragen over jouw account, beveiliging en functies."
                        },
                        "protect": {
                            "title": "Moderatie",
                            "description": "Moderatoren beschermen de Code of Conduct door gebruik te maken van hun toegang tot hulpmiddelen waarmee zij gebruikers kunnen waarschuwen die de regels overtreden of hen zelfs van het WR-platform kunnen verbannen."
                        },
                        "badge": {
                            "title": "Herkenning",
                            "description": "Trust & Safety medewerkers zijn bewakers die specifiek toezicht houden op het WR-platform om onschuldige gebruikers te beschermen en de 'slechteriken' in de gaten te houden. Je kunt ze herkennen aan hun unieke badge."
                        },
                        "coc": {
                            "title": "Wijzigingen van de Gedragscode",
                            "description": "De medewerkers van het Trust & Safety team werken nauw samen met de WR Staff om de Gedragscode te handhaven. Zo blijven de regels altijd actueel en wordt ervoor gezorgd dat het platform een veilige en plezierige plek is voor iedereen."
                        }
                    }
                },
                "admin": {
                    "short": "Administrator",
                    "long": "Warsaw Revamped Administrator",
                    "description": "De administrators zijn verantwoordelijk voor diverse administratieve taken en zorgen dat alles op rolletjes blijft lopen."
                },
                "qa": {
                    "short": "Quality Assurance",
                    "long": "Quality Assurance",
                    "description": "De administrators zijn verantwoordelijk voor diverse administratieve taken en zorgen dat alles op rolletjes blijft lopen."
                },
                "dev": {
                    "short": "Ontwikkelaar",
                    "long": "Development Team",
                    "description": "Het Development Team is verantwoordelijk voor het ontwikkelen van Warsaw Revamped."
                },
                "staff": {
                    "short": "Medewerker",
                    "long": "Warsaw Revamped Staff",
                    "description": "Medewerkers"
                },
                "verified": {
                    "short": "Geverifiëerd",
                    "long": "Geverifieerd account",
                    "description": "Geverifieerde accounts op Warsaw Revamped toont aan dat een account authentiek is en wordt toegekend aan gebruikers die een belangrijke rol spelen binnen de Warsaw Revamped community",
                    "info": {
                        "active": {
                            "title": "Actief",
                            "description": "Geverifieerde accounts zijn actief betrokken binnen de Warsaw Revamped community. Ze maken actief gebruik van hun account en in veel gevallen creëren ze content."
                        },
                        "badge": {
                            "title": "Herkenning",
                            "description": "Geverifieerde accounts zijn te herkennen aan het verificatievinkje op hun profiel, waardoor je direct een belangrijk persoon uit de Warsaw Revamped community kunt herkennen."
                        }
                    }
                }
            }
        },
        "page": {
            "insider": {
                "faq": {
                    "who": {
                        "description": "Het doel van het WR Insiders Program is om iedereen de kans te geven om lid te worden als WR Insider. Dus ben je extreem gemotiveerd, speel je Battlefield 4™ nog regelmatig en/of beheer je een Discord-server, fansite, forum of Reddit-pagina gewijd aan Battlefield™ of Battlefield 4™? Dan kom je in aanmerking als WR Insider!\n\nStreamers en makers van videocontent op YouTube en Twitch zijn ook gekwalificeerd als WR Insiders en zijn meer dan welkom om Warsaw Revamped vroegtijdig uit te proberen en hun content te delen met hun publiek en anderen.\n\nNaast gepassioneerde Battlefield 4™-spelers, streamers en makers van videocontent, komen ook toegewijde modders in aanmerking als WR Insiders!",
                        "title": "Voor wie is het WR Insiders Program bedoeld?"
                    },
                    "what": {
                        "description": "Het WR Insiders Program is ontworpen om de verdeelde Battlefield™-community samen te brengen en te laten zien waartoe WR in staat is. Als gamers hebben we één ding gemeen: we houden van deze franchise. Om onze liefde voor Battlefield 4™ te verspreiden en te laten zien waar we achter de schermen aan werken, hebben we WR Insiders in het leven geroepen.",
                        "title": "Wat is de WR Insiders Program?"
                    },
                    "advantages": {
                        "title": "Wat zijn de voordelen wanneer je een WR Insider bent?",
                        "description": "WR Insiders hebben toegang tot een exclusieve Discord sectie waarin we regelmatig feedback vragen over nieuwe features, ontwerp of implementatie ideeën.\n\nWR Insiders krijgen exclusief vroegtijdige toegang tot nieuwe features, inclusief de Modding API, om vroeg te kunnen beginnen met het maken van mods.\n\nWR Insiders hebben exclusief vroegtijdige toegang bij Playtests door middel van een Closed Alpha en in de toekomst tijdens de Closed Beta wanneer Warsaw Revamped de bèta fase heeft bereikt. WR Insiders zijn de eersten die vroegtijdig toegang krijgen tot Warsaw Revamped."
                    }
                },
                "description": "Help ons met het bouwen van Warsaw Revamped",
                "join": {
                    "description": "Stuur ons een mail waarin je uitlegt waarom je een WR Insider zou moeten worden. Vermeld eventuele ervaring uit het verleden en je motivatie naar {email}. Vergeet niet om ons je Discord naam en tag te geven zodat we met je kunnen chatten op Discord.",
                    "title": "Ben je geïnteresseerd om deel te nemen aan het WR Insiders Program?"
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "We hebben een probleem ontdekt met Warsaw Dragon, help ons om dit te verbeteren door feedback te geven",
                "send_feedback": "Stuur feedback"
            },
            "modal": {
                "placeholder": "Ik klikte op ... en toen drukte ik op ..."
            }
        },
        "error": {
            "general_error_title": "Oeps...!",
            "page_not_found": {
                "title": "Pagina niet gevonden",
                "description": "De pagina die je probeert te bereiken bestaat niet."
            },
            "profile_unavailable": {
                "title": "Profiel niet beschikbaar",
                "account_suspended": "Dit account is momenteel verbannen van Warsaw Revamped.",
                "load_api_error": "Het profiel dat je zoekt kon niet worden geladen."
            },
            "require_sign_in": {
                "title": "Niet aangemeld",
                "description": "Om deze inhoud te bekijken moet je inloggen met je Warsaw ID."
            },
            "buttons": {
                "return": "Terug",
                "go_home": "Terug naar de homepage",
                "sign_in": "Log in"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "Account beperkingen",
                    "description": "Accounts kunnen tijdelijke beperkingen krijgen op basis van hun status. Dit omvat - maar is niet beperkt - tot aan het activeren van je account en het inschakelen van multi-factor authenticatie. Het ontvangen van een blokkering, waarschuwing of andere sancties kan er ook voor zorgen dat je geen toegang hebt tot specifieke onderdelen met je account."
                },
                "features": {
                    "host": {
                        "title": "Hosten van je eigen Warsaw Revamped server",
                        "suspended": "Je toegang tot de Warsaw Revamped hosting servers is opgeschort door het Trust & Safety team wegens overtreding van de servicevoorwaarden.",
                        "no_discord": "Je moet je Discord account linken met je Warsaw ID.",
                        "ok": "Het is voor jouw account mogelijk om zowel publieke als privé Warsaw Revamped servers te hosten."
                    },
                    "play": {
                        "title": "Spelen op een Warsaw Revamped server",
                        "suspended": "Je toegang om te spelen op Warsaw Revamped servers is opgeschort door het Trust & Safety team wegens overtreding van de servicevoorwaarden.",
                        "ok": "Het is voor jouw account mogelijk om zowel publieke als privé Warsaw Revamped servers te spelen."
                    }
                },
                "alert": {
                    "title": "Je account is verbannen!",
                    "more-information": "Meer informatie",
                    "suspension": {
                        "any": {
                            "permanent": "Je account is permanent verbannen van Warsaw Revamped vanwege {reason}.",
                            "temporarily": "Je account is verbannen voor {time} om {reason}."
                        },
                        "host": {
                            "permanent": "Je account is geblokkeerd om servers te hosten, de volgende reden is opgegeven: {reason}.",
                            "temporarily": "Je account is geblokkeerd om servers te hosten voor {time}, de volgende reden is opgegeven: {reason}."
                        }
                    }
                }
            },
            "navigator": {
                "settings": "Instellingen",
                "eula": "Overeenkomsten"
            },
            "settings": {
                "page_title": "Mijn account",
                "navigation": {
                    "settings": "Instellingen",
                    "experimental_features": "Experimentele features"
                },
                "experimental_features": {
                    "title": "Experimentele features",
                    "description": "We geven nieuwe functies vaak gedeeltelijk vrij om feedback te verzamelen voordat we ze aan iedereen vrijgeven. De functies hieronder zijn momenteel in een experimentele fase en zijn mogelijk nog niet het eindproduct. We waarderen de feedback op deze functies.",
                    "no_features_available": "Er zijn momenteel geen experimentele functies ingeschakeld voor je account.",
                    "warning_test_feature": "Deze experimentele functie wordt slechts aan een beperkt aantal gebruikers aangeboden, zodat we kunnen controleren op problemen voordat we de functie volledig vrijgeven."
                }
            },
            "eula": {
                "page_title": "Gebruikersovereenkomsten",
                "title": "Licentieovereenkomsten voor eindgebruikers",
                "description": "Bekijk en lees de gebruikersovereenkomsten die je hebt aanvaard",
                "documents": {
                    "terms": "Algemene voorwaarden",
                    "coc": "Gedragscode"
                },
                "accepted": "Je hebt dit document geaccepteerd op {time}"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Donkere modus",
                "description": "Schakel deze experimentele functie in door op de maan of zon icon op de navigatiebalk te klikken"
            }
        },
        "notifications": {
            "email-not-activated": "Je moet je e-mailadres bevestigen middels de verzonden verificatiemail voordat je Warsaw Revamped kunt spelen."
        },
        "news": {
            "mini": {
                "read-more": "Lees meer",
                "more-news": "Meer nieuws"
            }
        },
        "general": {
            "buttons": {
                "learn-more": "Ontdek meer",
                "see-more-feedback": "Zie meer feedback",
                "other-platforms": "Andere platformen",
                "download": "Download",
                "help": "Help",
                "retry": "Probeer opnieuw",
                "ok": "Ok",
                "continue": "Doorgaan"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Verbinding met de feedbackservers is mislukt, probeer het later nog eens."
                }
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "levels": "{badge} levels",
                    "stats": "{badge}: bereikt door {percentage} van de spelers",
                    "obtained": "verkregen {tijd} geleden"
                },
                "type": {
                    "exclusive": {
                        "title": "Exclusief",
                        "description": "Deze badge is niet te verkrijgen."
                    },
                    "achievement": {
                        "title": "Achievement"
                    }
                }
            },
            "error": {
                "general": {
                    "title": "Oeps! Er is iets fout gegaan",
                    "description": "Sorry, we kunnen dit profiel nu niet laten zien. Probeer het later nog eens."
                },
                "server_not_found": {
                    "title": "Sorry, dit serverprofiel is niet beschikbaar",
                    "description": "Deze server is privé of bestaat niet meer."
                },
                "banned": {
                    "title": "Deze gebruiker is verbannen van Warsaw Revamped",
                    "description": "Deze gebruiker is verbannen voor het breken van de {gedragscode} en onze {dienstvoorwaarden}."
                },
                "not_found": {
                    "title": "Sorry, er zijn geen gebruikers met deze naam",
                    "description": "We konden geen gebruikers vinden met deze gebruikersnaam."
                }
            }
        },
        "open-positions": {
            "list": {
                "none": {
                    "title": "Geen openstaande posities",
                    "description": "Er zijn op dit moment geen openstaande posities."
                }
            },
            "title": "Open posities",
            "who-we-are": {
                "title": "Wie zijn we?",
                "prg1": "Warsaw Revamped wordt gerund door een team dat van over de hele wereld bijdraagt en samenwerkt, met leden variërend van professionele programmeurs en ontwikkelaars tot nieuwkomers. Onze vrijwilligers hebben uiteenlopende achtergronden, maar delen een gezamenlijke passie voor Battlefield 4™ en zorgen ervoor dat onze community gelukkig is.",
                "prg2": "We zijn momenteel een team dat 10+ sterk is, en we bestrijken alle traditionele rollen van spelontwikkeling, ontwerp, communitybeheer enzovoort."
            },
            "tips": {
                "title": "Enkele tips voordat je solliciteert",
                "tip1": "Laat ons zien wat je hebt gedaan in de relevante functie waar je naar solliciteert. Dit kan door middel van YouTube-video's, screenshots, links naar GitHub of Gitlab, enz. We zullen geen code uitvoeren voor de eerste beoordelingen van sollicitanten.",
                "tip2": "Wij zijn een team van vrijwilligers en wij ontvangen geen enkele financiële vergoeding voor ons werk aan Warsaw Revamped. Wij zijn niet-commercieel en innen of ontvangen geen geld.",
                "tip3": "Als je geen antwoord krijgt, betekent dit helaas dat wij niet geïnteresseerd zijn. Wegens het grote aantal sollicitaties dat wij binnenkrijgen kunnen wij niet elke sollicitatie met de hand beantwoorden."
            }
        },
        "launcher": {
            "auth": {
                "description": "Je bent aangemeld, ga verder naar de Warsaw Revamped launcher.",
                "title": "Ingelogd",
                "launched": "launched beschrijving"
            }
        },
        "home": {
            "important-features": {
                "title": "Toffe functies",
                "host-server": "Start eenvoudig met één klik via onze launcher een server op jouw eigen PC of host je eigen dedicated server op Windows of met behulp van onze Docker container of Pterodactyl egg op Linux.",
                "modding-api": "Volledig uitgeruste Modding API in Lua, C# en JavaScript met alles wat je nodig hebt om alles uit WR te halen. Pas het spelgedrag aan, creëer geheel nieuwe game modes en creëer nieuwe inhoud met Rime.",
                "ai-bots": "Eersteklas ondersteuning voor bots. Laat ze vrij rondlopen, pas hun gedrag aan als dat nodig is of neem de volledige controle over via de Modding API om je eigen ervaringen te creëren.",
                "more": "Kijk wat we nog meer in petto hebben"
            },
            "roadmap": {
                "title": "Roadmap",
                "items": {
                    "bot": {
                        "title": "AI Soldiers",
                        "description": ""
                    }
                }
            }
        }
    }
}
