/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import BaseTranslated from './content/base.translated.json';
import {Config} from "../config/config.template";

let ENABLE_IMAGE_LOGGING: boolean = false;

/**
 * Set the imaging util
 * @param status
 */
export function setImageLogging(status: boolean) {
    console.log(`%cCORE:%c Image logging: ${status}`, "color:blue;font-size:1.5vh;font-weight:900;", "color:blue");
    ENABLE_IMAGE_LOGGING = status;
}

/**
 * Generate a Warsaw Revamped image
 * @param img_id {string} image id from base.map.json
 * @param ext
 */
export default function img(img_id: string, ext: string) {
    // Check if the file exists in the translation folder.
    if (BaseTranslated[`${img_id}.${ext}`] === undefined) {

        if (ENABLE_IMAGE_LOGGING)
            console.log(`🐉 %c[IMAG]%c Failed to translate '${img_id}', not in translation map!`, 'color:red;font-weight:bold;', '');

        // If the fallback CDN is enabled, return the fallback CDN image.
        if (Config.imaging.defaultUseCDN) {
            console.log(`🐉 %c[IMAG]%c Return fallback URL for '${img_id}', not in translation map!`, 'color:red;font-weight:bold;', '');
            return "https://cdn.warsaw-revamped.com/" + img_id + ".png"; // TODO: We are using the PNG extension here, but what if it's a different one?
        }

        return;
    }

    if (ENABLE_IMAGE_LOGGING)
        console.log(`🐉 %c[IMAG]%c Translated '${img_id}' to ${BaseTranslated[`${img_id}.${ext}`]}`, 'color:green;font-weight:bold;', '');

    return BaseTranslated[`${img_id}.${ext}`];
}