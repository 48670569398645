{
    "footer": {
        "logo": {
            "title": "Erstellt von"
        },
        "dragon": {
            "title": "Bereitgestellt von"
        },
        "eula": {
            "title": "Nutzungsvereinbarungen",
            "terms-of-service": "Allgemeine Geschäftsbedingungen",
            "privacy-policy": "Datenschutz-Bestimmungen",
            "code-of-conduct": "Verhaltenskodex",
            "cookie-policy": "Cookie-Richtlinien"
        },
        "help": {
            "faq": "FAQ",
            "title": "Warsaw Hilfe",
            "contact-us": "Kontaktiere uns",
            "feedback": "Feedback",
            "status": "Status"
        },
        "about": {
            "title": "Über uns",
            "open-positions": "Offene Stellen",
            "us-on-gitlab": "Warsaw Revamped auf GitLab",
            "team": "Unser Team",
            "join-team": "Tritt dem Team bei"
        },
        "game": {
            "play": "Spielen",
            "insider": "WR-Insider"
        },
        "legal": {
            "legal_c": "SOFERN NICHT AUSDRÜCKLICH ERWÄHNT, IST KEIN WAFFEN-, FAHRZEUG- ODER AUSRÜSTUNGSHERSTELLER MIT DIESEM SPIEL VERBUNDEN, HAT ES GESPONSERT ODER UNTERSTÜTZT.",
            "legal_a": "© {year} Warsaw Revamped. Alle Rechte vorbehalten",
            "legal_b": "Wir sind in keiner Weise mit Electronic Arts Inc. oder EA Digital Illusions CE AB (DICE) verbunden. Battlefield® und Battlefield 4® sind eingetragene Warenzeichen von Electronic Arts Inc.. Alle Firmen-, Produkt-, und Dienstleistungsnamen, Logos und Marken sowie alle anderen urheberrechtlich geschützten Materialien gehören ihren rechtmäßigen Eigentümern und werden nur zu Identifikationszwecken verwendet. Das Verwenden dieser Materialien impliziert keine Billigung."
        }
    },
    "header": {
        "services": {
            "wr": "Startseite",
            "wr-help": "WR-Hilfe",
            "wr-labs": "Laboratorien"
        },
        "my-account": {
            "my-profile": "Mein Profil",
            "preferences": "Meine Vorlieben",
            "my-id": "Meine Warsaw-ID",
            "signout": "Abmelden"
        },
        "tooltips": {
            "sign-in": "Anmelden oder Account erstellen"
        }
    },
    "error": {
        "error-404": {
            "title": "Seite nicht gefunden",
            "page-title": "Seite nicht gefunden",
            "info": "Entschuldigung, die Seite, auf die du zugreifen willst, existiert nicht."
        }
    },
    "general": {
        "buttons": {
            "return-to-home": "Zurück zur Startseite"
        }
    }
}
