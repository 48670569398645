{
    "en": {
        "general": {
            "buttons": {
                "learn-more": "Learn more",
                "see-more-feedback": "See more feedback",
                "download": "Download",
                "help": "Help",
                "retry": "Retry",
                "continue": "Continue",
                "ok": "Ok",
                "other-platforms": "Other platforms",
                "join-discord": "Join Discord"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Failed to connect to the feedback servers, please try again later."
                }
            }
        },
        "open-positions": {
            "title": "Open Positions",
            "who-we-are": {
                "title": "Who are we?",
                "prg1": "Warsaw Revamped is ran by a team that contribute and collaborate remotely from around the world, with members ranging from professional programmers and developers to newcomers. Our volunteers have a diverse set of backgrounds, but share a common passion for Battlefield 4 and ensuring that our community is happy.",
                "prg2": "We are currently a team that is 10+ strong, and we cover all the traditional roles of game development, design, community management and so on."
            },
            "tips": {
                "title": "Some tips before applying",
                "tip1": "Show us what you've done in the relevant position you're applying for. This can be done via YouTube videos, screenshots, links to GitHub or Gitlab, etc. We will not run any code for initial reviews of applicants.",
                "tip2": "We are a 100% volunteer team and we do not receive financial compensation for our work on Warsaw Revamped. We are non-commercial and do not collect or receive any money.",
                "tip3": "Failing to get a response unfortunately means we are not interested in pursuing your application. Due to the high volume of applicants we can’t reply to each by hand."
            },
            "list": {
                "none": {
                    "title": "No open positions",
                    "description": "There are currently no open positions."
                }
            },
            "view-position": {
                "description": "Team position description",
                "see-more": "See what else is available",
                "info": {
                    "title": "Join the team",
                    "posted": "Posted on {date}",
                    "closes": "Closes on {date}",
                    "voluntary-pos": "Voluntary position",
                    "apply": "Apply"
                },
                "not-found": {
                    "title": "Team position not found",
                    "description": "Sorry, this team position is not available. It may have closed, expired or it doesn't exist."
                }
            }
        },
        "team": {
            "title": "Meet the team",
            "about": {
                "title": "Who is behind Warsaw Revamped?",
                "description": "Warsaw Revamped is ran by a team that contribute and collaborate remotely from around the world, with members ranging from professional programmers and developers to newcomers. We share a common passion for Battlefield 4™ and modding. Our goal is to ensure that the community can share our passion too."
            },
            "recruiting": {
                "title": "Join the Warsaw Revamped team!",
                "description": "We are always looking for new people who share the same passion for Battlefield 4™ and programming. Our goal is to provide a safe and inspiring modding platform that puts the community first before anything. Our project is non-commerical, we do not make any money from this, it's just pure passion for the Battlefield franchise.",
                "join": "Join the team"
            },
            "meet": {
                "title": "Meet the team",
                "core_team": "Core team",
                "contributors": "Contributors",
                "more": "And these are just some people part of our awesome team! There are many more who spent a lot of their free time to improve Warsaw Revamped, they who can be identified by their special Warsaw Staff badge. Don't forget to say hello when you see them.",
                "fonctions": {
                    "game-lead-dev": "Game & Backend developer, Founder",
                    "backend-lead-dev": "Backend developer & DevOps",
                    "web-ui-ux-lead-dev": "Web & UI/UX lead developer",
                    "community-manager": "Community Manager",
                    "qa-lead": "QA Lead",
                    "game-designer": "Game Designer",
                    "qa-member": "QA Tester"
                },
                "descriptions": {
                    "mrelectrify": "I started the WR project in 2018 with PolloLoco after experiencing constant DDoS and crash attacks with my community servers with no support from DICE. After talks of rebalancing with some competitive players, the modding goal was born. I worked on the project on-and-off throughout university, and held our first alpha in August 2020. I am responsible for the game and backend. Nowadays I am a Software Engineer for a large company and work on WR for fun in free time.",
                    "neutron": "Joined back in 2018 and helped MrElectrify get the project off the ground in 2020, building the first website and web API. Since then became a developer for anything, especially the backend, modernising process, doing DevOps work and generally assisting the other developers with their projects.",
                    "firjen": "I joined the project at the end of 2021 together with Sjoerd as a web developer. Since then I became the lead developer for the Warsaw Revamped launcher and Warsaw Dragon, a framework used for all WR websites.",
                    "sjoerd": "I joined the team of Warsaw Revamped on September 24, 2021, as Community Manager. Since my arrival, I have been responsible for the campaign surrounding the 2021 Open Alpha, added more social channels including Twitter and Twitch, expanded our online reach and rolled out multiple initiatives including Weekly Q&As on Reddit, the WR Insiders Program and our WR Feedback platform. As Community Manger, I keep in touch with the devs and community and supervise various teams, organise campaigns, take care of content placement and am responsible for public relations."
                }
            }
        },
        "home": {
            "important-features": {
                "title": "Exciting Features",
                "host-server": "Easily start a server on your own PC with just one click from our launcher or host your own permanently anywhere on Windows or using our Docker container or Pterodactyl egg on Linux.",
                "ai-bots": "First-class support for bots. Let them roam free, adjust their behaviour as needed or take full control through the modding API to create your own experiences.",
                "modding-api": "Fully featured modding API in Lua with batteries-included tooling. Modify all game behaviour, create entirely new game modes, or create new content with Rime.",
                "more": "See what else we have planned"
            },
            "roadmap": {
                "title": "Roadmap",
                "items": {
                    "dedicated-servers": {
                        "title": "Dedicated servers",
                        "short-description": "Customizable, dedicated servers that can be started by anyone with up to 128 players",
                        "description": "Easily start a server on your own PC with just one click from our launcher or host your own permanently anywhere on Windows or using our Docker container or Pterodactyl egg on Linux."
                    },
                    "core-game": {
                        "title": "Custom backend & core game reliability",
                        "short-description": "Fully custom backend for improved resilience against downtime and better DDoS protection for the game",
                        "description": "We have fully featured support for stats and support for progression with our custom in-house backend, dubbed Poseidon, allows us give you a better and more reliable playing experience and to develop new features more quickly. Our custom DDoS protection system allows higher resilience against attacks."
                    },
                    "modding-api": {
                        "title": "Modding API",
                        "short-description": "Customize and extend the game to your heart's content with Lua",
                        "description": "Fully featured modding API in Lua and possibly other languages in the future with batteries-included tooling. Modify all game behaviour, create entirely new game modes, or create new content with Rime."
                    },
                    "bot": {
                        "title": "AI Bots",
                        "short-description": "First-class support for smart bots",
                        "description": "Let them roam free, adjust their behaviour as needed or take full control through the modding API to create your own experiences."
                    },
                    "anti-cheat": {
                        "title": "Anti-Cheat",
                        "short-description": "Improved & innovative anti-cheat measures",
                        "description": ""
                    }
                },
                "release-note": {
                    "v1-release": "Planned for initial release",
                    "post-release": "After initial release / live support"
                },
                "status": {
                    "in_progress": "In progress",
                    "scoping": "Being scoped out",
                    "planned": "Planned",
                    "done": "Completed"
                }
            },
            "insiders": {
                "title": "WR Insiders Program",
                "description": "This program is designed to bring together the divided Battlefield 4™ community and showcase what WR is capable of. Reserved for those who are extremely motivated, playing Battlefield 4™ on a regular basis and are extremely dedicated. Streamers and video content creators on YouTube and Twitch also qualifies as WR Insiders."
            }
        },
        "launcher": {
            "auth": {
                "title": "Signed in",
                "description": "You have been signed in, continue to the Warsaw Revamped launcher.",
                "launched": "launched description"
            }
        },
        "play": {
            "download": {
                "downloading": {
                    "fail": {
                        "description": "Failed to complete the download, please check your internet connection and try again."
                    },
                    "progress": {
                        "prepare": "We are currently preparing your download...",
                        "description": "The launcher is downloading in the background. Do not leave this page or your download will be canceled."
                    },
                    "success": {
                        "description": "Your download has been completed. You can open {exe} to the installation process"
                    }
                },
                "unavailable": {
                    "title": "Warsaw Revamped is not available yet!",
                    "description": "Warsaw Revamped is still under development, however we do host frequent technical playtests allowing our community to test out latest features and changes."
                },
                "available": {
                    "alert": {
                        "title": "Development version of Warsaw Revamped",
                        "description": "You have exclusive access to a development version of Warsaw Revamped. This version is updated frequently and will contain bugs and issues, we'd appreciate it if you report these issues to us so we can resolve them. Sharing the launcher with a 3rd party without permission from Warsaw Revamped is not allowed."
                    },
                    "title": "Download Warsaw Revamped",
                    "title_progress": "Downloading Warsaw Revamped",
                    "description": "Blablabla",
                    "select_platform": "Linux support is still in a very early stage and might not work properly.",
                    "in_progress": "Download in progress"
                }
            },
            "hardware-requirements": {
                "title": "Hardware Requirements",
                "requirements": {
                    "memory": {
                        "title": "Memory",
                        "description": "Minimum 4 GB of RAM, 8 GB recommended"
                    },
                    "os": {
                        "title": "Operating System",
                        "description": "Minimum is Windows 8, Windows 10 recommended. Some Linux distributions are supported."
                    },
                    "hdd": {
                        "title": "Storage",
                        "description": "30 GB of storage. SSD recommended."
                    },
                    "processor": {
                        "title": "Processor",
                        "description": "Intel Quad-core CPU, or AMD Six-core CPU recommended"
                    },
                    "graphics": {
                        "title": "Graphics",
                        "description": "Minimum NVIDIA GeForce GTX 660, or AMD Radeon HD 7870"
                    },
                    "internet": {
                        "title": "Internet",
                        "description": "Required for signing in, playing multiplayer and creating a server"
                    }
                }
            }
        },
        "nav": {
            "primary": {
                "home": "Home",
                "play_button": "Play",
                "mode": {
                    "dark_on": "Turn on dark mode (experimental)",
                    "dark_off": "Turn off dark mode (experimental)"
                }
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Sign in or create an account"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "Trust & Safety",
                    "long": "Trust & Safety Team",
                    "description": "The trust and safety team has the critical responsibility of protecting Warsaw Revamped's users by ensuring online safety by fighting abusive behavior across all Warsaw Revamped platforms.",
                    "info": {
                        "help": {
                            "title": "Player Support",
                            "description": "In order to provide users with the best possible experience, we have an experienced Player Support team that will answer tickets regarding your account, security and features."
                        },
                        "protect": {
                            "title": "Moderating",
                            "description": "Moderators enforce the Code of Conduct by using their access to tools that allow them to warn users who break the rules or even ban them from the WR platform."
                        },
                        "badge": {
                            "title": "Recognition",
                            "description": "Trust & Safety team members are guardians who specifically monitor the WR platform to protect innocent users and keep an eye on the 'bad guys'. You can recognise them by their unique badge."
                        },
                        "coc": {
                            "title": "Amendments to the Code of Conduct",
                            "description": "Trust & Safety team members work closely with the WR Staff to maintain the Code of Conduct. This means that the rules are always kept up to date and it is ensured that the platform is a safe and enjoyable place for everyone."
                        }
                    }
                },
                "admin": {
                    "short": "Administrator",
                    "long": "Warsaw Revamped Administrator",
                    "description": "The administrators are in charge of various administrative duties and oversee day-to-day operations."
                },
                "qa": {
                    "short": "Quality Assurance",
                    "long": "Quality Assurance",
                    "description": "The Quality Assurance team tests for bugs in cutting edge builds."
                },
                "dev": {
                    "short": "Developer",
                    "long": "Development Team",
                    "description": "The Development team are responsible for the development of Warsaw Revamped."
                },
                "staff": {
                    "short": "Staff",
                    "long": "Warsaw Revamped staff",
                    "description": "Staff"
                },
                "verified": {
                    "short": "Verified",
                    "long": "Verified account",
                    "description": "Verified account badge on Warsaw Revamped shows that this account is authentic and is awarded to members who play an important role in the Warsaw Revamped community",
                    "info": {
                        "active": {
                            "title": "Active",
                            "description": "Verified accounts are actively involved in the Warsaw Revamped community. They actively use their account and in many cases create content."
                        },
                        "badge": {
                            "title": "Recognition",
                            "description": "Verified accounts can be recognised by the verification check mark on their profile, which allows you to instantly recognise an important person from the Warsaw Revamped community."
                        }
                    }
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "Read more",
                "more-news": "More news"
            }
        },
        "page": {
            "insider": {
                "description": "Help us build Warsaw Revamped",
                "faq": {
                    "what": {
                        "title": "What is the WR Insiders Program?",
                        "description": "The WR Insiders Program is designed to bring together the divided Battlefield™ community and showcase what WR is capable of. As gamers we have one thing in common, we love this franchise. To spread our love for Battlefield 4™ and show what we are working on behind the scenes, we have created WR Insiders."
                    },
                    "who": {
                        "title": "Who is the WR Insiders Program reserved for?",
                        "description": "The goal of the WR Insiders Program is to give everyone the opportunity to join as a WR Insider. So are you extremely motivated, still playing Battlefield 4™ on a regular basis and/or managing a Discord server, fansite, forum or Reddit page dedicated to Battlefield™ or Battlefield 4™? Then you qualify as a WR Insider!\n\nStreamers and video content creators on YouTube and Twitch are also qualified as WR Insiders and are more than welcome to try out Warsaw Revamped early and share their content with their audience and others.\n\nBesides passionate Battlefield 4™ players, streamers and video content creators, dedicated modders are also qualified as WR Insiders!"
                    },
                    "advantages": {
                        "title": "What are the advantages of being an WR Insider?",
                        "description": "WR Insiders have access to an exclusive Discord section where we ask frequent feedback on new features, design or implementation ideas.\n\nWR Insiders get exclusive early access to new features, including the Modding API, to get started creating mods early.\n\nWR Insiders have exclusive early access at Playtests through a Closed Alpha and in the future during the Closed Beta when Warsaw Revamped has reached the betà phase. WR Insiders are the first to get early access to Warsaw Revamped."
                    }
                },
                "join": {
                    "title": "Are you interested in joining the WR Insiders program?",
                    "description": "Send us an email explaining why you should become a WR Insider. Include any past experience and your motivation to {email}. Don't forget to give us your Discord name and tag so we can chat with you on Discord."
                }
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "levels": "{badge} levels",
                    "stats": "{badge}: achieved by {percentage} of players",
                    "obtained": "obtained {time} ago"
                },
                "type": {
                    "exclusive": {
                        "title": "Exclusive",
                        "description": "This badge is not obtainable."
                    },
                    "achievement": {
                        "title": "Achievement",
                        "description": "Achievement badge"
                    }
                }
            },
            "error": {
                "banned": {
                    "title": "This user has been suspended from Warsaw Revamped",
                    "description": "This user is currently suspended for breaking the {code-of-conduct} and our {terms-of-service}."
                },
                "not_found": {
                    "title": "Sorry, there aren't any users with this name",
                    "description": "We couldn't find any users with this username."
                },
                "server_not_found": {
                    "title": "Sorry, this server profile is not available",
                    "description": "Either this server is private or doesn't exist anymore."
                },
                "general": {
                    "title": "Oops! Something went wrong",
                    "description": "Sorry, we can't show this profile right now. Please try again later."
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "We've detected an issue with Warsaw Dragon, help us to improve by submitting feedback",
                "send_feedback": "Send feedback"
            },
            "modal": {
                "description": "In order to improve Warsaw Revamped, we automatically collect any technical issues that occur on the website.  You can help us to resolve this by telling us what caused this issue and what you did before it occured. A couple of words suffice that help us to reproduce this issue.",
                "placeholder": "I clicked on ... and then I pressed ..."
            }
        },
        "error": {
            "general_error_title": "Oops...!",
            "page_not_found": {
                "title": "Page not found",
                "description": "The page you are trying to reach doesn't exist."
            },
            "profile_unavailable": {
                "title": "Profile not available",
                "account_suspended": "This account is currently suspended from Warsaw Revamped.",
                "load_api_error": "The profile you are looking for could not be loaded."
            },
            "require_sign_in": {
                "title": "Not signed in",
                "description": "In order to view this content you must sign in with your Warsaw ID."
            },
            "buttons": {
                "return": "Return",
                "go_home": "Return to homepage",
                "sign_in": "Sign in"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "Account limitations",
                    "description": "Accounts can receive temporary restrictions based on their health status, this includes but is not limited to account activation and having multi-factor authentication enabled. Receiving a suspension, warning or other sanction can also cause your account to be limited."
                },
                "features": {
                    "host": {
                        "title": "Hosting your own Warsaw Revamped server",
                        "suspended": "Your access to hosting Warsaw Revamped servers has been suspended by the Trust & Safety team because of terms of service violations.",
                        "no_discord": "You must link your Discord account with your Warsaw ID.",
                        "ok": "You can host public and private Warsaw Revamped servers."
                    },
                    "play": {
                        "title": "Playing on Warsaw Revamped server",
                        "suspended": "Your access to play on Warsaw Revamped servers has been suspended by the Trust & Safety team because of terms of service violations.",
                        "ok": "You can play on public and private Warsaw Revamped servers."
                    }
                },
                "alert": {
                    "title": "Your account has been limited!",
                    "more-information": "More information",
                    "suspension": {
                        "any": {
                            "permanent": "Your account has been permanently suspended from Warsaw Revamped because of {reason}.",
                            "temporarily": "Your account has been suspended for {time} because of {reason}."
                        },
                        "host": {
                            "permanent": "Your account has been permanently suspended from hosting servers because of {reason}.",
                            "temporarily": "Your account has been suspended from hosting servers for {time} because of {reason}."
                        }
                    }
                }
            },
            "navigator": {
                "settings": "Settings",
                "eula": "Agreements"
            },
            "settings": {
                "page_title": "My account",
                "navigation": {
                    "settings": "Settings",
                    "experimental_features": "Experimental features"
                },
                "experimental_features": {
                    "title": "Experimental features",
                    "description": "We frequently partially release new features to allow us to collect feedback on them before releasing it to everyone. The features listed below are currently in an experimental stage and may not be a finished product. We appreciate the feedback on these features.",
                    "no_features_available": "No experimental features are currently enabled for your account.",
                    "warning_test_feature": "This experimental feature is only rolled out to a limited amount of users to allow us to check for issues before releasing it fully."
                }
            },
            "eula": {
                "page_title": "User agreements",
                "title": "End user license agreements",
                "description": "View and read the user agreements you've accepted",
                "documents": {
                    "terms": "Terms of Service",
                    "coc": "Code of Conduct"
                },
                "accepted": "You accepted this document on {time}"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Dark mode",
                "description": "Enable this experimental feature by clicking the moon or sun on the navigation bar"
            }
        },
        "notifications": {
            "email-not-activated": "Your email must be validated before being able to play on Warsaw Revamped."
        }
    }
}
