/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import ISubModule from "../../ISubModule";
import LayoutMod from "./layout.mod";
import i18n from "../../../../../plugins/i18n"
import store from '../../../../../store';
import {getStoredLanguage, setStorageLanguage} from "./utils/language-storage.util";

/**
 * Language Manager
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export default class LanguageSub extends ISubModule {
    constructor(parent: LayoutMod) {
        super("Language", parent);
    }

    /**
     * On enabling language, set the user's default language either currently set by the browser
     * or what is saved in the local storage
     */
    protected async enable(): Promise<any> {
        // Get default browser language
        const default_browser_language = navigator.language.substring(0, 2).toLowerCase();

        // Set in store
        store.state.layout.language_user_browser = default_browser_language;

        // If no default is set, set automatic browser language
        if (getStoredLanguage() === null) {
            // Check if it's a supported language, if not, ask the user to translate.
            if (supported_languages[default_browser_language] !== null) {
                this.setLanguage(default_browser_language, false); // Set default language to browser language
            }
            else if (navigator.language === "pt-BR") {
                this.setLanguage("pt_BR", false); // Set default language to browser language
            } else {
                store.state.layout.language_user_browser_unsupported = true; // Keep language to default (en)
            }
        } else {
            this.setLanguage(getStoredLanguage()['language'], getStoredLanguage()['manual']);
        }
    }


    setLanguage(language: any, manual: boolean) {
        // check for duplicate
        if (language === i18n.locale) {
            this.mod_logger.warn("Refused to change language to '" + language + "' as it's the same as the current selected language.");
            return;
        }

        this.mod_logger.info("Warsaw Dragon language has been set to '" + language + "'");
        setStorageLanguage(language, manual, store.state.layout.language_user_browser);
        i18n.locale = language;
    }
}

/**
 * List of supported languages.
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export enum supported_languages {
    "en",
    "fr",
    "ru",
    "es",
    "de",
    "nl",
    "pt",
    "pt_BR",
    "zh",
    "zh_Hant",
    "tr",
    "pl",
    "bg"
}