/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    { path: '/',
        name: "home",
        meta: {
            title: 'Home'
        },
        component: () => import("../views/home.vue")},

    { path: '/play',
        name: "play",
        component: () => import("../views/play.vue")},

    { path: '/insider',
        name: "insider",
        component: () => import("../views/insider.vue")},

    { path: '/team',
        name: "team",
        component: () => import("../views/team/team.vue")},

    { path: '/team/join',
        name: "join-team",
        component: () => import("../views/team/team-join.vue")},

    { path: '/team/join/:position',
        name: "view-team-position",
        component: () => import("../views/team/team-join-view.vue")},

    { path: '/event/playtest',
        name: "event-playtest",
        component: () => import("../views/event/technical-playtest.vue")},

    { path: '/user/:user',
        name: "user-profile",
        meta: {
            sign_in_required: true
        },
        component: () => import("../views/account/profile/user-profile.vue")},

    { path: '/server/:server',
        name: "server-profile",
        meta: {
            sign_in_required: true
        },
        component: () => import("../views/server/server-profile.vue")},

    { path: '/account/preferences',
        name: "account-preferences",
        meta: {
            sign_in_required: true
        },
        component: () => import("../views/account/preferences/home.vue")},

    { path: '/legal',
        name: "legal",
        component: () => import("../views/legal/legal.vue")},

    { path: '/legal/code-of-conduct',
        name: "code-of-conduct",
        component: () => import("../views/legal/code-of-conduct.vue")},

    { path: '/legal/terms-of-service',
        name: "terms-of-service",
        component: () => import("../views/legal/terms-of-service.vue")},

    { path: '/legal/privacy-policy',
        name: "privacy-policy",
        component: () => import("../views/legal/privacy-policy.vue")},

    { path: '/legal/cookie-policy',
        name: "cookie-policy",
        component: () => import("../views/legal/cookie-policy.vue")},

    { path: '/legal/video-policy',
        name: "video-policy",
        component: () => import("../views/legal/video-policy.vue")},

    { path: '/legal/ugc-policy',
        name: "ugc-policy",
        component: () => import("../views/legal/ugc-policy.vue")},

    { path: '/article',
        name: "news-list",
        component: () => import("../views/news/news-list.vue")},

    { path: '/article/:article_id/:article_slug',
        name: "news-article-view",
        component: () => import("../views/news/view-news-article.vue")},

    { path: '/launch/authenticate/:action',
        name: "launch-authenticate",
        component: () => import("../views/launch/authenticate.vue")},

    { path: '/:catchAll(.*)',
        name: "error-404",
        component: () => import("../views/error/error-404.vue")}
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

export default router;