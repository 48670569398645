[
  {"id": "en", "name": "English", "flag": "gb", "localised": "English", "beta": false, "codes": ["en", "en-GB", "en-US", "en_001", "en_150"]},
  {"id": "fr", "name": "French", "flag": "fr", "localised": "Français", "beta": true, "codes": ["fr", "fr-BE", "fr-BF", "fr-BI", "fr-BJ", "fr-BL", "fr-CA", "fr-CD", "fr-CF", "fr-CG", "fr-CH", "fr-CI", "fr-CM", "fr-DJ", "fr-DZ", "fr-FR", "fr-GA", "fr-GF", "fr-GN", "fr-GP", "fr-GQ", "fr-HT", "fr-KM", "fr-LU", "fr-MA", "fr-MC", "fr-MF", "fr-MG", "fr-ML", "fr-MQ", "fr-MR", "fr-MU", "fr-NC", "fr-NE", "fr-PF", "fr-PM", "fr-RE", "fr-RW", "fr-SC", "fr-SC", "fr-SN", "fr-SY", "fr-TD", "fr-TG", "fr-TN", "fr-VU", "fr-WF", "fr-YT"]},
  {"id": "ru", "name": "Russian", "flag": "ru", "localised": "Pусский", "beta": false, "codes": ["ru", "ru-BY", "ru-KG", "ru-KZ", "ru-MD", "ru-RU", "ru-UA"]},
  {"id": "de", "name": "German", "flag": "de", "localised": "Deutsch", "beta": false, "codes": ["de", "de-AT", "de-BE", "de-CH", "de-DE", "de-IT", "de-LI", "de-LU"]},
  {"id": "nl", "name": "Dutch", "flag": "nl", "localised": "Nederlands", "beta": false, "codes": ["nl", "nl-AW", "nl-BE", "nl-BQ", "nl-CW", "nl-NL", "nl_SR", "nl-SX"]},
  {"id": "pt", "name": "Portuguese", "flag": "pt", "localised": "Português", "beta": true, "codes": ["pt", "pt-PT"]},
  {"id": "pt_BR", "name": "Portuguese (Brazil)", "flag": "br", "localised": "Português (Brasil)", "beta": true, "codes": ["pt-BR"]},
  {"id": "zh", "name": "Chinese (simplified)", "flag": "cn", "localised": "简体中文", "beta": true, "codes": ["zh", "zh-Hans", "zh-Hans_CN", "zh-Hans_HK", "zh-Hans_MO", "zh-Hans_SG"]},
  {"id": "zh_Hant", "name": "Chinese (traditional)", "flag": "cn", "localised": "中國傳統的", "beta": true, "codes": ["zh-hant", "zh-Hant_HK", "zh-Hant_MO", "zh-Hant_TW"]},
  {"id": "sv", "name": "Swedish", "flag": "se", "localised": "Svenska", "beta": false, "codes": ["sv", "sv-AX", "sv-FI", "sv-SE"]},
  {"id": "it", "name": "Italian", "flag": "it", "localised": "Italiano", "beta": true, "codes": ["it", "it-CH", "it-IT", "it-SM", "it-VA"]},
  {"id": "tr", "name": "Turkish", "flag": "tr", "localised": "Türkçe", "beta": false, "codes": ["tr", "tr-CY", "tr-TR"]},
  {"id": "pl", "name": "Polish", "flag": "pl", "localised": "Polskie", "beta": true, "codes": ["pl", "pl-PL"]},
  {"id": "bg", "name": "Bulgarian", "flag": "bg", "localised": "български", "beta": false, "codes": ["bg", "bg-BG"]},
  {"id": "fi", "name": "Finnish", "flag": "fi", "localised": "Suomi", "beta": true, "codes": ["fi", "fi-FI"]}
]