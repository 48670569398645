/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import ISubModule from "../ISubModule";
import IModule from "../IModule";

/**
 * List of debug levels.
 */
export enum LogLevel {
    /**
     * Log all messages no matter the log-level.
     */
    ALL = "ALL",
    /**
     * Used to capture all the details about the behavior of the website or application, for a granular and finer level of debugging.
     */
    VERBOSE = "VERBOSE",
    /**
     * Used to log information related to the normal behavior of Dragon.
     */
    INFO = "INFO",
    /**
     * Used when detecting an unexpected Dragon issue. No module, service or Dragon itself should run other than normal unless intended.
     */
    WARN = "WARN",
    /**
     *  Used for important failures in Dragon but still allow Dragon to run.
     */
    ERROR = "ERROR",
}

/**
 * Array of the levels to log. Use LogLevel.ALL to see everything and an empty array to disable.
 */
let LOG_LEVEL = [LogLevel.ERROR, LogLevel.WARN, LogLevel.ALL];

/**
 * Set the log level
 * @param loglevel {Array<LogLevel} array containing levels to log, use LogLevel.ALL to log everything and an empty array to log nothing.
 */
export function setLogLevel(loglevel: Array<LogLevel>) {
    console.log(`%cCORE:%c Log level has been set to ${loglevel.toString()}`, "color:blue;font-size:1.5vh;font-weight:900;", "color:blue");
    LOG_LEVEL = loglevel;
}

/**
 * Logger for modules and sub-modules. Solely to be included in the 'IModule' and 'ISubModule' abstract classes.
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */

export default class LoggerModuleUtil {
    private readonly module: IModule;

    private readonly submodule: ISubModule | null;

    /**
     * Create a new logger
     * @param Module
     * @param SubModule
     */
    constructor(Module: IModule, SubModule?: ISubModule) {
        this.module = Module;
        this.submodule = SubModule;
        this.verbose("New module logger created")
    }

    /**
     * Log a verbose, used to capture all the details about the behavior of the website or application. It should be used mostly for diagnostic for a more granular and finer level of debugging.
     * @param message {string} Message to show in the console.
     */
    verbose(message: string) {
        if (!LOG_LEVEL.includes(LogLevel.VERBOSE) && !LOG_LEVEL.includes(LogLevel.ALL))
            return;

        if (this.submodule === undefined) {
            console.log(`🐉 %c[VERB]%c ${this.module.mod_name}%c ${message}`, 'color:lightblue;font-weight:bold;', 'font-weight:bold;text-transform:uppercase;', '');
            return;
        }

        console.log(`🐉 %c[VERB]%c ${this.module.mod_name} > ${this.submodule.mod_name}%c ${message}`,'color:lightblue;font-weight:bold;','font-weight:bold;text-transform:uppercase;','color:darkgray;');
    }


    /**
     * Use it for normal behavior of Dragon.
     * @param message {string} Message to show in the console.
     */
    info(message: string) {
        if (!LOG_LEVEL.includes(LogLevel.INFO) && !LOG_LEVEL.includes(LogLevel.ALL))
            return;

        if (this.submodule === undefined) {
            console.log(`🐉 %c[INFO]%c ${this.module.mod_name}%c ${message}`, 'color:lightgreen;font-weight:bold;', 'font-weight:bold;text-transform:uppercase;', '');
            return;
        }

        console.log(`🐉 %c[INFO]%c ${this.module.mod_name} > ${this.submodule.mod_name}%c ${message}`,'color:lightgreen;font-weight:bold;','font-weight:bold;text-transform:uppercase;','color:darkgray;');
    }

    /**
     * Used when detecting an unexpected Dragon issue. No module, service or Dragon itself should run other than normal unless intended.
     * @param message {string} Message to show in the console.
     */
    warn(message: string) {
        if (!LOG_LEVEL.includes(LogLevel.WARN) && !LOG_LEVEL.includes(LogLevel.ALL))
            return;

        if (this.submodule === undefined) {
            console.log(`🐉 %c[WARN]%c ${this.module.mod_name}%c ${message}`,'color:orange;font-weight:bold;','font-weight:bold;text-transform:uppercase;','');
            return;
        }

        console.log(`🐉 %c[WARN]%c ${this.module.mod_name} > ${this.submodule.mod_name}%c ${message}`,'color:orange;font-weight:bold;','font-weight:bold;text-transform:uppercase;','color:darkgray;');
    }

    /**
     * Used for important failures in Dragon but still allow Dragon to run.
     * @param message {string} Message to show in the console.
     */
    error(message: string) {
        if (!LOG_LEVEL.includes(LogLevel.ERROR) && !LOG_LEVEL.includes(LogLevel.ALL))
            return;

        if (this.submodule === undefined) {
            console.log(`🐉 %c[ERRO]%c ${this.module.mod_name}%c ${message}`, 'color:red;font-weight:bold;', 'font-weight:bold;', '');
            return;
        }

        console.log(`🐉 %c[ERRO]%c ${this.module.mod_name} > ${this.submodule.mod_name}%c ${message}`, 'color:red;font-weight:bold;', 'font-weight:bold', 'color:darkgray;');
    }
}
