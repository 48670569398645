{
    "en": {
        "general": {
            "buttons": {
                "continue": "Fortsätt",
                "learn-more": "Läs mer",
                "see-more-feedback": "Se mer feedback",
                "other-platforms": "Övriga plattformar",
                "download": "Ladda ner",
                "help": "Hjälp",
                "retry": "Försök igen",
                "ok": "Ok",
                "join-discord": "Ansluta dig till Discord"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Det gick inte att ansluta till återkopplings servrarna, försök igen senare."
                }
            }
        },
        "open-positions": {
            "list": {
                "none": {
                    "title": "Inga lediga tjänster",
                    "description": "Det finns för närvarande inga lediga tjänster."
                }
            },
            "title": "Lediga tjänster",
            "who-we-are": {
                "title": "Vilka är vi?",
                "prg2": "Vi är för närvarande ett team som är mer än 10 personer och vi täcker alla traditionella roller inom spelutveckling, design, community management och så vidare.",
                "prg1": "Warsaw Revamped drivs av ett team som bidrar och samarbetar på distans från hela världen, med medlemmar som sträcker sig från professionella programmerare och utvecklare till nybörjare. Våra volontärer har olika bakgrunder, men delar en gemensam passion för Battlefield 4 och för att se till att vår community är nöjd."
            },
            "tips": {
                "title": "Några tips innan du ansöker",
                "tip1": "Visa oss vad du har gjort i den relevanta tjänsten du söker. Detta kan göras med hjälp av YouTube-videor, skärmdumpar, länkar till GitHub eller Gitlab osv. Vi kommer inte att köra någon kod vid den första granskningen av de sökande.",
                "tip2": "Vi är ett team av 100 % frivilliga och vi får ingen ekonomisk ersättning för vårt arbete med Warsaw Revamped. Vi är icke-kommersiella och samlar inte in eller tar emot några pengar.",
                "tip3": "Om du inte får något svar betyder det tyvärr att vi inte är intresserade av att gå vidare med denna ansökan. På grund av det stora antalet sökande kan vi inte svara på varje enskild ansökan för hand."
            }
        },
        "home": {
            "roadmap": {
                "items": {
                    "bot": {
                        "description": "Förstklassigt stöd för robotar. Låt dem vandra fritt, justera deras beteende efter behov eller ta full kontroll med hjälp av modifiering API för att skapa dina egna upplevelser.",
                        "short-description": "Förstklassigt stöd för smarta bottar",
                        "title": "AI-bottar"
                    },
                    "anti-cheat": {
                        "title": "Anti Fusk",
                        "short-description": "Förbättrade och innovativa åtgärder mot fusk"
                    },
                    "core-game": {
                        "title": "Anpassad backend och tillförlitlighet i huvudspelet",
                        "short-description": "Helt anpassad backend för bättre motståndskraft mot driftstopp och bättre DDoS-skydd för spelet",
                        "description": "Vi har fullt utbyggt stöd för statistik och stöd för utveckling med vår anpassade interna backend, kallad Poseidon, som gör att vi kan ge dig en bättre och mer tillförlitlig spelupplevelse och utveckla nya funktioner snabbare. Vårt särskilda DDoS-skyddssystem ger högre motståndskraft mot attacker."
                    },
                    "modding-api": {
                        "short-description": "Anpassa och utöka spelet efter eget tycke och smak med Lua, C# eller JavaScript",
                        "title": "API modifiering",
                        "description": "Fullt modifierings API i Lua och eventuellt andra språk i framtiden med verktyg för batterier. Ändra alla spel beteenden, skapa helt nya spellägen eller skapa nytt innehåll med Rime."
                    },
                    "dedicated-servers": {
                        "title": "Dedikerade servrar",
                        "short-description": "Anpassningsbara, dedikerade servrar som kan startas av vem som helst med upp till 128 spelare",
                        "description": "Starta enkelt en server på din egen dator med ett enda klick från vår lanserings programvara eller var värd för din egen server var som helst i Windows eller med hjälp av vår Docker-behållare eller vårt Pterodactyl-egg i Linux."
                    }
                },
                "status": {
                    "in_progress": "På gång",
                    "planned": "Planerat",
                    "scoping": "Undersökning av området",
                    "done": "Slutfört"
                },
                "release-note": {
                    "v1-release": "Planerad för första lanseringen",
                    "post-release": "Efter den första lanseringen/live support"
                },
                "title": "Färdplan"
            },
            "important-features": {
                "title": "Spännande funktioner",
                "host-server": "Starta enkelt en server på din egen dator med ett enda klick från vår lanserings programvara eller var värd för din egen server var som helst i Windows eller med hjälp av vår Docker-behållare eller vårt Pterodactyl-egg i Linux.",
                "ai-bots": "Förstklassigt stöd för robotar. Låt dem vandra fritt, justera deras beteende efter behov eller ta full kontroll med hjälp av modifiering API för att skapa dina egna upplevelser.",
                "modding-api": "Fullt utrustad modifierings API i Lua, C# och JavaScript med verktyg. Ändra alla spel beteenden, skapa helt nya spellägen eller skapa nytt innehåll med Rime.",
                "more": "Se vad vi har planerat mer"
            },
            "insiders": {
                "description": "Det här programmet är utformat för att samla den delade Battlefield 4™-communityn och visa upp vad WR är kapabel till. Reserverat för dem som är extremt motiverade, spelar Battlefield 4™ regelbundet och är extremt hängivna. Streamers och skapare av videoinnehåll på YouTube och Twitch kvalificerar sig också som WR Insiders.",
                "title": "WR Insiders-program"
            }
        },
        "launcher": {
            "auth": {
                "title": "inloggad",
                "launched": "beskrivning av lanseringen",
                "description": "Du har loggat in, fortsätt till lanseringen av Warsaw Revamped."
            }
        },
        "permission": {
            "status-badge": {
                "dev": {
                    "description": "Utvecklingsteamet ansvarar för utvecklingen av Warsaw Revamped.",
                    "short": "Utvecklare",
                    "long": "Utvecklingsgrupp"
                },
                "mod": {
                    "info": {
                        "help": {
                            "description": "För att ge användarna den bästa möjliga upplevelsen har vi ett erfaret spelarsupportteam som svarar på frågor om ditt konto, säkerhet och funktioner.",
                            "title": "Stöd för spelare"
                        },
                        "coc": {
                            "title": "Ändringar av uppförandekoden",
                            "description": "Medlemmarna i Trust & Safety-teamet har ett nära samarbete med personalen på WR för att upprätthålla uppförandekoden. Detta innebär att reglerna alltid hålls uppdaterade och att det säkerställs att plattformen är en säker och trevlig plats för alla."
                        },
                        "badge": {
                            "description": "Medlemmarna i Trust & Safety-teamet är väktare som särskilt övervakar WR-plattformen för att skydda oskyldiga användare och hålla ett öga på \"skurkarna\". Du kan känna igen dem på deras unika märke.",
                            "title": "Erkännande"
                        },
                        "protect": {
                            "description": "Moderatorer upprätthåller uppförandekoden genom att använda sina verktyg som gör det möjligt för dem att varna användare som bryter mot reglerna eller till och med stänga av dem från WR-plattformen.",
                            "title": "Moderation"
                        }
                    },
                    "long": "Teamet för förtroende och säkerhet",
                    "description": "Teamet för förtroende och säkerhet har det viktiga ansvaret för att skydda Warszawa Revampeds användare genom att garantera online säkerhet genom att bekämpa missbruk på Warszawa Revampeds alla plattformar.",
                    "short": "Förtroende och säkerhet"
                },
                "verified": {
                    "info": {
                        "badge": {
                            "description": "Verifierade konton kan kännas igen genom att de har en kontroll markering på sin profil, vilket gör att du omedelbart kan känna igen en viktig person från Warsaw Revamped-communityt.",
                            "title": "Erkännande"
                        },
                        "active": {
                            "title": "Aktiv",
                            "description": "Verifierade konton är aktivt involverade i Warsaw Revamped-gemenskapen. De använder aktivt sitt konto och skapar i många fall innehåll."
                        }
                    },
                    "description": "Verifierat konto på Warszawa Revamped visar att kontot är autentiskt och tilldelas medlemmar som spelar en viktig roll i Warszawa Revamped-gemenskapen.",
                    "short": "Verifierad",
                    "long": "Verifierat konto"
                },
                "admin": {
                    "short": "Administratör",
                    "description": "Administratörerna ansvarar för olika administrativa uppgifter och övervakar den dagliga verksamheten.",
                    "long": "Warsaw Revamped Administratör"
                },
                "qa": {
                    "long": "Kvalitetssäkring",
                    "short": "Kvalitetssäkring",
                    "description": "Kvalitetssäkrings teamet testar för att hitta fel i de senaste byggnaderna."
                },
                "staff": {
                    "short": "Personal",
                    "long": "Warsaw Revamped personal",
                    "description": "Personal"
                }
            }
        },
        "page": {
            "insider": {
                "faq": {
                    "who": {
                        "title": "Vem är WR Insiders-programmet reserverat för?",
                        "description": "Målet med WR Insiders-programmet är att ge alla möjlighet att bli WR Insider. Så är du extremt motiverad, spelar fortfarande Battlefield 4™ regelbundet och/eller hanterar en Discord-server, fansite, forum eller Reddit-sida dedikerad till Battlefield™ eller Battlefield 4™? Då kvalificerar du dig som WR Insider!\n\nStreamers och skapare av videoinnehåll på YouTube och Twitch är också kvalificerade som WR Insiders och är mer än välkomna att prova Warsaw Revamped tidigt och dela sitt innehåll med sin publik och andra.\n\nFörutom passionerade Battlefield 4™-spelare, streamers och skapare av videoinnehåll är även hängivna moddare kvalificerade som WR Insiders!"
                    },
                    "advantages": {
                        "title": "Vilka är fördelarna med att vara WR Insider?",
                        "description": "WR Insiders har tillgång till en exklusiv Discord-sektion där vi ofta ber om feedback på nya funktioner, design eller idéer för implementering.\n\nWR Insiders får exklusiv tidig tillgång till nya funktioner, inklusive Modding API, så att du kan börja skapa moddar tidigt.\n\nWR Insiders har exklusiv tidig tillgång till playtester genom en stängd alfa och i framtiden under den stängda betan när Warsaw Revamped har nått betafasen. WR Insiders är de första som får tidig tillgång till Warszawa Revamped."
                    },
                    "what": {
                        "description": "WR Insiders-programmet är utformat för att sammanföra den delade Battlefield™-communityn och visa upp vad WR är kapabel till. Som spelare har vi en sak gemensamt, vi älskar den här serien. För att sprida vår kärlek till Battlefield 4™ och visa vad vi arbetar med bakom kulisserna har vi skapat WR Insiders.",
                        "title": "Vad är WR Insiders-programmet?"
                    }
                },
                "description": "Hjälp oss att bygga Warszawa Revamped",
                "join": {
                    "title": "Är du intresserad av att delta i WR Insiders-programmet?",
                    "description": "Skicka oss ett e-postmeddelande där du förklarar varför du borde bli WR Insider. Ange eventuella tidigare erfarenheter och din motivation till {email}. Glöm inte att ge oss ditt Discord-namn och din tagg så att vi kan chatta med dig på Discord."
                }
            }
        },
        "nav": {
            "primary": {
                "play_button": "Spela",
                "home": "Hem",
                "mode": {
                    "dark_on": "Aktivera mörkt läge (experimentell)",
                    "dark_off": "Stäng av mörkt läge (experimentell)"
                }
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Logga in eller skapa ett konto"
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "Läs mer",
                "more-news": "Fler nyheter"
            }
        },
        "team": {
            "meet": {
                "descriptions": {
                    "firjen": "Jag gick med i projektet i slutet av 2021 tillsammans med Sjoerd som webbutvecklare. Sedan dess har jag blivit huvudutvecklare för lanseringen av Warsaw Revamped och Warsaw Dragon, ett ramverk som används för alla WR-webbplatser.",
                    "mrelectrify": "Jag startade WR-projektet 2018 tillsammans med PolloLoco efter att ha upplevt ständiga DDoS- och krasch attacker på mina gemenskaps servrar utan stöd från DICE. Efter samtal om om balansering med några konkurrenskraftiga spelare föddes moddingmålet. Jag arbetade med projektet till och från under hela universitetstiden och höll vår första alfa i augusti 2020. Jag är ansvarig för spelet och backend. Numera är jag mjukvaruingenjör på ett stort företag och jobbar på WR på fritiden för skojs skull.",
                    "neutron": "Jag gick med 2018 och hjälpte MrElectrify att starta projektet 2020 och byggde den första webbplatsen och webb-API:n. Sedan dess har jag blivit utvecklare för allt, särskilt backend, moderniserat processen, gjort DevOps-arbete och allmänt hjälpt de andra utvecklarna med deras projekt.",
                    "sjoerd": "Jag började arbeta för Warsaw Revamped den 24 september 2021 som Community Manager. Sedan min ankomst har jag varit ansvarig för kampanjen kring 2021 års Open Alpha, lagt till fler sociala kanaler, inklusive Twitter och Twitch, utökat vår räckvidd online och lanserat flera initiativ, inklusive veckovisa frågor och svar på Reddit, WR Insiders-programmet och vår WR Feedback-plattform. Som Community Manger håller jag kontakten med utvecklarna och gemenskapen och övervakar olika team, organiserar kampanjer, tar hand om innehållsplacering och ansvarar för public relationerna."
                },
                "fonctions": {
                    "web-ui-ux-lead-dev": "Web & UI/UX huvudutvecklare",
                    "community-manager": "Community Manager",
                    "qa-lead": "QA ledare",
                    "game-designer": "Spel Designer",
                    "game-lead-dev": "Utvecklare av spel, Grundare",
                    "backend-lead-dev": "Utvecklare & DevOps",
                    "qa-member": "QA Testare"
                },
                "contributors": "Bidragsgivare",
                "title": "Möt teamet",
                "more": "Och det här är bara några av de personer som ingår i vårt fantastiska team! Det finns många fler som spenderar mycket av sin fritid på att förbättra Warszawa Revamped, de som kan identifieras med sin speciella Warsaw Staff-märke. Glöm inte att säga hej när du ser dem.",
                "core_team": "Core teamet"
            },
            "title": "Möt teamet",
            "about": {
                "title": "Vem står bakom Warsaw Revamped?",
                "description": "Warsaw Revamped drivs av ett team som bidrar och samarbetar på distans från hela världen, med medlemmar som sträcker sig från professionella programmerare och utvecklare till nybörjare. Vi delar en gemensam passion för Battlefield 4™ och modding. Vårt mål är att se till att gemenskapen också kan dela vår passion."
            },
            "recruiting": {
                "title": "Gå med i Warsaw Revamped-teamet!",
                "description": "Vi letar alltid efter nya människor som delar samma passion för Battlefield 4™ och programmering. Vårt mål är att tillhandahålla en säker och inspirerande moddingplattform som sätter gemenskapen först och främst. Vårt projekt är icke-kommersiellt, vi tjänar inga pengar på detta, det är bara ren passion för Battlefield-franchisen.",
                "join": "Gå med i teamet"
            }
        },
        "play": {
            "download": {
                "downloading": {
                    "progress": {
                        "description": "launcher laddas ner i bakgrunden. Lämna inte denna sida, annars avbryts nedladdningen.",
                        "prepare": "Vi förbereder för närvarande din nedladdning..."
                    },
                    "fail": {
                        "description": "Nedladdningen kunde inte slutföras, kontrollera din internetanslutning och försök igen."
                    },
                    "success": {
                        "description": "Din nedladdning har slutförts. Du kan öppna {exe} för installationsprocessen"
                    }
                },
                "available": {
                    "alert": {
                        "title": "Utvecklingsversion av Warszawa Revamped",
                        "description": "Du har exklusiv tillgång till en utvecklingsversion av Warsaw Revamped. Den här versionen uppdateras ofta och kommer att innehålla buggar och problem, vi skulle uppskatta om du rapporterar dessa problem till oss så att vi kan lösa dem. Det är inte tillåtet att dela lanseringen med en tredje part utan tillstånd från Warsaw Revamped."
                    },
                    "title": "Ladda ned Warszawa Revamped",
                    "title_progress": "Laddar ned Warszawa Revamped",
                    "select_platform": "Stödet för Linux är fortfarande i ett mycket tidigt skede och kanske inte fungerar korrekt.",
                    "in_progress": "Nedladdning pågår"
                },
                "unavailable": {
                    "description": "Warsaw Revamped är fortfarande under utveckling, men vi har ofta tekniska speltester som gör det möjligt för vår community att testa de senaste funktionerna och förändringarna.",
                    "title": "Warsaw Revamped är inte tillgängligt för tillfället!"
                }
            },
            "hardware-requirements": {
                "requirements": {
                    "processor": {
                        "title": "Processor",
                        "description": "Intel fyrkärnig CPU eller AMD sexkärnig CPU rekommenderas"
                    },
                    "graphics": {
                        "description": "Minst NVIDIA GeForce GTX 660 eller AMD Radeon HD 7870",
                        "title": "Grafik"
                    },
                    "internet": {
                        "title": "Internet",
                        "description": "Krävs för att logga in, spela med flera spelare och skapa en server"
                    },
                    "os": {
                        "title": "Operativsystem",
                        "description": "Minst Windows 8, Windows 10 rekommenderas. Vissa Linux-distributioner stöds."
                    },
                    "memory": {
                        "title": "Minne",
                        "description": "Minst 4 GB RAM, 8 GB rekommenderas"
                    },
                    "hdd": {
                        "title": "Lagring",
                        "description": "30 GB lagringsutrymme. SSD rekommenderas."
                    }
                },
                "title": "Systemkrav"
            }
        },
        "profile": {
            "error": {
                "general": {
                    "description": "Tyvärr kan vi inte visa den här profilen just nu. Försök igen senare.",
                    "title": "Oops! Något gick fel"
                },
                "not_found": {
                    "title": "Tyvärr finns det inga användare med detta namn",
                    "description": "Vi kunde inte hitta några användare med detta användarnamn."
                },
                "banned": {
                    "title": "Den här användaren har stängts av från Warsaw Revamped",
                    "description": "Den här användaren är för närvarande avstängd för att ha brutit mot {uppförandekoden} och våra {Tjänstevillkor}."
                },
                "server_not_found": {
                    "title": "Den här serverprofilen är tyvärr inte tillgänglig",
                    "description": "Denna server är antingen privat eller existerar inte längre."
                }
            },
            "badge": {
                "view": {
                    "obtained": "erhållen för {tid} sedan",
                    "stats": "{märke}: uppnås av {procent} av spelarna",
                    "levels": "{märke} nivåer"
                },
                "type": {
                    "exclusive": {
                        "description": "Detta märke kan inte erhållas.",
                        "title": "Exklusivt"
                    },
                    "achievement": {
                        "title": "Prestationer"
                    }
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "Vi har upptäckt ett problem med Warsaw Dragon, hjälp oss att förbättra genom att skicka in din feedback",
                "send_feedback": "Skicka feedback"
            }
        },
        "account": {
            "limited": {
                "alert": {
                    "suspension": {
                        "any": {
                            "permanent": "Ditt konto har stängts av permanent från Warsaw Revamped på grund av {grund}.",
                            "temporarily": "Ditt konto har stängts av i {tid} på grund av {sak}."
                        },
                        "host": {
                            "temporarily": "Ditt konto har stängts av från hostingservrarna i {tid} på grund av {sak}.",
                            "permanent": "Ditt konto har stängts av permanent från hostingservrarna på grund av {grund}."
                        }
                    },
                    "more-information": "Mer information",
                    "title": "Ditt konto har begränsats!"
                },
                "features": {
                    "host": {
                        "title": "Ansvara för din egen Warsaw Revamped-server",
                        "ok": "Du kan vara värd för offentliga och privata Warsaw Revamped-servrar.",
                        "suspended": "Din tillgång till Warsaw Revamped-servrarna har stängts av Trust & Safety-teamet på grund av brott mot användarvillkoren.",
                        "no_discord": "Du måste koppla ditt Discord-konto till ditt Warsaw-ID."
                    },
                    "play": {
                        "ok": "Du kan spela på offentliga och privata Warsaw Revamped-servrar.",
                        "title": "Spelar på Warsaw Revamped server",
                        "suspended": "Din tillgång till Warsaw Revamped-servrarna har stängts av Trust & Safety-teamet på grund av brott mot användarvillkoren."
                    }
                },
                "page": {
                    "title": "Konto begränsningar",
                    "description": "Konton kan få tillfälliga begränsningar baserat på deras hälsostatus, vilket inkluderar men inte är begränsat till konto aktivering och aktivering av flerfaktorsautentisering. Att få en avstängning, varning eller annan sanktion kan också leda till att ditt konto begränsas."
                }
            },
            "eula": {
                "title": "Licensavtal för slutanvändare",
                "accepted": "Du godkände detta dokument den {tid}",
                "documents": {
                    "terms": "Villkor för tjänsten",
                    "coc": "Uppförandekodex"
                },
                "page_title": "Användaravtal",
                "description": "Visa och läsa de användaravtal som du har accepterat"
            },
            "settings": {
                "page_title": "Mitt konto",
                "navigation": {
                    "settings": "Inställningar",
                    "experimental_features": "Experimentella funktioner"
                },
                "experimental_features": {
                    "title": "Experimentella funktioner",
                    "no_features_available": "Vi släpper ofta nya funktioner delvis för att samla in feedback innan vi släpper dem för alla. De funktioner som anges nedan är för närvarande i ett experimentellt skede och kanske inte är en färdig produkt. Vi uppskattar feedback på dessa funktioner.",
                    "warning_test_feature": "Den här experimentella funktionen är endast tillgänglig för ett begränsat antal användare för att vi ska kunna kontrollera om det finns några problem innan den släpps fullt ut.",
                    "description": "Vi släpper ofta nya funktioner delvis för att samla in feedback innan vi släpper dem för alla. De funktioner som anges nedan är för närvarande i ett experimentellt skede och kanske inte är en färdig produkt. Vi uppskattar feedback på dessa funktioner."
                }
            },
            "navigator": {
                "settings": "Inställningar",
                "eula": "Avtal"
            }
        },
        "error": {
            "require_sign_in": {
                "description": "För att kunna se detta innehåll måste du logga in med ditt Warsaw-ID.",
                "title": "Inte inloggad"
            },
            "general_error_title": "Oops...!",
            "page_not_found": {
                "title": "Sidan hittas inte",
                "description": "Sidan du försöker nå finns inte."
            },
            "buttons": {
                "sign_in": "Logga in",
                "go_home": "Gå tillbaka till hemsidan",
                "return": "Tillbaka"
            },
            "profile_unavailable": {
                "title": "Profil inte tillgänglig",
                "account_suspended": "Det här kontot är för närvarande avstängt från Warsaw Revamped.",
                "load_api_error": "Profilen du letar efter kunde inte laddas."
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "description": "Aktivera denna experimentella funktion genom att klicka på månen eller solen i navigerings fältet",
                "name": "Mörkt läge"
            }
        },
        "notifications": {
            "email-not-activated": "Din e-post måste bekräftas innan du kan spela på Warsaw Revamped."
        }
    }
}
