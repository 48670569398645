<template>
    <div class="navigation-alert" :class="source">
        <div class="animate__animated animate__flash animate__repeat-2 container d-flex flex-md-row" style="align-items: center;">

            <!-- Flashing warning symbol -->
            <div class="icon" v-if="icon" style="font-size:20px;">
                <i :class="icon"></i>
            </div>

            <!-- Text to inform the user -->
            <div>
                {{ $t(text) }}
            </div>

            <!-- Close button -->
            <div class="ml-auto" v-if="this.dismissable" @click="this.dismiss" style="font-size:21px;padding-left:8px;">
                <i class="fas fa-times dismiss-button" style="vertical-align: middle;"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "dragon page alert",
        props: {
            uuid: { type: String, required: true },
            text: { type: String, required: true },
            source: { type: String, required: true },
            icon: { type: String, required: true },
            animation: { type: String, required: true },
            dismissable: { type: Boolean, required: true }
        },
        methods: {
            dismiss() {
                this.$dragon.notifications.removeNotification(this.uuid);
            }
        }
    };
</script>

<style>
    .navigation-alert {
        padding-top: 0.75vh;
        padding-bottom: 0.75vh;
        background-color: var(--wr-color-primary-light);
        width:100%;
        color:white;
    }

    .dismiss-button:hover {
        color: var(--wr-color-primary);
        cursor: pointer;
    }

    .navigation-alert.secondary {
        background-color: var(--wr-color-secondary) !important;
    }

    .navigation-alert.tertiary {
        background-color: var(--wr-color-tertiary) !important;
    }

    .navigation-alert.info {
        background-color: var(--wr-color-info) !important;
    }

    .navigation-alert .icon {
       padding-right: 10px;
   }

</style>