<template>
    <div id="app" :class="{ 'dark' : this.$store.state.layout.dark_mode}">
        <nav_account_bar v-if="!isError" />

        <navigation_primary v-if="!isError" />

        <router-view v-if="!isError" />

        <warsaw_footer v-if="!isError">
            <div class="footer-block header">
                <i class="fas fa-gamepad fa-fw"></i> {{ $t('base.footer.game.title') }}
            </div>
            <router-link tag="div" :to="{ name: 'play' }" class="footer-block text" exact-active-class="active">{{ $t('base.footer.game.play') }}</router-link>
            <router-link tag="div" :to="{ name: 'insider' }" class="footer-block text" exact-active-class="active">{{ $t('base.footer.game.insider') }}</router-link>
        </warsaw_footer>

        <dragon_stopscreen v-if="isError" />
    </div>
</template>
<script lang="ts">
    import warsaw_footer from './ext/base/vue_components/footer/footer.vue';
    import dragon_stopscreen from './ext/base/views/dragon-stopscreen';
    import nav_account_bar from './ext/base/vue_components/nav-bar/account-bar.vue';
    import navigation_primary from './components/v2/layout/navigation-bar.vue';
    import wr_side_block from './ext/base/vue_components/dragon/layout/side-block.vue';
    import wr_account_side_block from './ext/base/vue_components/dragon/layout/side-blocks/s-account-block.vue';

    export default {
        name: 'app',
        components: {
            navigation_primary,
            nav_account_bar,
            warsaw_footer,
            dragon_stopscreen,
            wr_side_block,
            wr_account_side_block
        },
        computed: {
            isError() {
                return this.$store.state.dragon.stopscreenDetails !== null;
            },
        },
        metaInfo: {
            titleTemplate: '%s · Warsaw Revamped',
            meta: [
                { name: 'viewport', content: 'width=device-width, initial-scale=1' }
            ]
        }
    };
</script>
<style type="less">
    #app {
        position:relative;
        transition: var(--dragon-layout-color-switch-animation);
    }

    /* Header dark theme */
    #app.dark {
        color: var(--dragon-layout-color-light);
        background-color: var(--wr-app-dark-background-color);
    }

    .blurred {
        filter: blur(4px) brightness(25%);
    }
</style>