/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import store from "../store";
import {ApolloClient, ApolloLink, createHttpLink} from "@apollo/client";
import {cache} from "../dragon/modules/graphql/cache";
const httpLink = createHttpLink({
    uri: 'http://poseidon.dev.warsaw-revamped.com/graphql',
});

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
        headers: {
            authorization: `Bearer ${store.state.dragon.dragon.account.authentication.keycloak.token}`
        }
    });

    return forward(operation)
});

/**
 * Client that has been authenticated with an account bearer.
 */
export default new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache
});