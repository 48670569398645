/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import Vue from "vue";
import VueI18n from "vue-i18n";
import index from '../locales/index';

Vue.use(VueI18n);

export default new VueI18n({
    locale: "en",
    messages: index()
});