<template>
    <div class="navigation-alert">
        <div class="animate__animated animate__flash animate__repeat-2 container d-flex flex-md-row" style="align-items: center;">

            <!-- Flashing warning symbol -->
            <div class="icon" style="font-size:20px;">
                <i class="fas fa-bug fa-fw"></i>
            </div>

            <!-- Text to inform the user -->
            <div>
                {{ $t('sentry.alert-bar.description') }}
            </div>

            <!-- Close button -->
            <div class="ml-auto d-flex align-items-center align-self-center" style="font-size:21px;padding-left:8px;height:100%;">
                <div v-b-modal.sentry-feedback style="height:100%;margin-right:15px;color:white;font-size:15px;">
                    {{ $t('sentry.alert-bar.send_feedback') }}
                </div>
                <i  @click="dismiss()" class="fas fa-times fa-fw dismiss-button" style="vertical-align: middle;"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sentry-error-warning",
        methods: {
            dismiss() {
                this.$store.state.sentry.sentry_event = null;
            }
        }
    };
</script>

<style scoped>
    .navigation-alert {
        padding-top: 0.75vh;
        padding-bottom: 0.75vh;
        background-color: var(--wr-color-danger);
        width:100%;
        color:white;
    }

    .dismiss-button:hover {
        color: var(--wr-color-primary);
        cursor: pointer;
    }

    .navigation-alert .icon {
        padding-right: 10px;
    }

</style>