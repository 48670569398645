<template>
    <div class="container">
        <div class="col-12 col-lg-10 offset-lg-1" style="font-size: 33px;padding-top:15vh;font-weight: bold;">
            <span class="icon" style="margin-right:15px;">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
            </span>
            <span class="title">
                Uh oh. Looks like Warsaw Dragon encountered an error.
            </span>
        </div>
        <div class="col-12 col-lg-10 offset-lg-1" style="font-size:19px;margin-top:25px;">
            Warsaw Dragon ran into an issue and needs to be reloaded. Details about this error are available below. If you encounter this often report it to the Warsaw Dragon team.
        </div>

        <!-- Error info title -->
        <div class="col-12 col-lg-10 offset-lg-1" style="font-size:22px;margin-top:50px;font-weight: bold">
            Details
        </div>

        <!-- Error details -->
        <div class="col-12 col-lg-10 offset-lg-1" style="font-size:16px;margin-top:10px;">
            <div>
                {{ this.$store.state.dragon.stopscreen }}
            </div>
            <div>
                {{ this.$store.state.dragon.stopscreenDetails }}
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "stopscreen",
        metaInfo () {
            return {
                titleTemplate: "Warsaw Revamped",
            }
        }
    };
</script>