<template>
    <b-modal id="sentry-feedback" title="Feedback" no-fade hide-footer>
        <div class="row" style="padding: 10px 22px;">
            <p style="font-size:14px;">{{ $t('sentry.modal.description') }}</p>

            <form id="sentry-feedback-form">
                <div class="form-group">
                    <label for="modal-sentry-feedback-description"></label>
                    <textarea class="form-control" id="modal-sentry-feedback-description" style="max-height:25vh;min-height:7.5vh;" maxlength="2000" :placeholder="this.$i18n.t('sentry.modal.placeholder')"></textarea>
                </div>
                <button type="submit" id="modal-sentry-submit" @click="submit()" class="btn btn-primary btn-block" disabled>Submit</button>
            </form>
        </div>
    </b-modal>
</template>
<script>
    export default {
        name: "sentry-feedback-modal",
        methods: {
            submit() {
                this.$store.state.sentry.sentry_event = null;
            }
        }
    };
</script>