/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

/**
 * Local storage key
 */
const WR_KEY_LANG = "wr:layout.setting.language";

/**
 * Fetch the JWT token from local storage.
 */
export const getStoredLanguage = () => {
    try {
        return JSON.parse(window.localStorage.getItem(WR_KEY_LANG))
    } catch (e) {
        console.warn(`Failed to load ${WR_KEY_LANG}. Resetting`);
        window.localStorage.removeItem(WR_KEY_LANG);
        return null;
    }
};

/**
 * Save a new token to the local storage.
 * @param new_language {string} new language to set to
 * @param manual {boolean}
 * @param browser_language
 */
export function setStorageLanguage(new_language, manual, browser_language) {
    window.localStorage.setItem(WR_KEY_LANG, JSON.stringify({language: new_language, manual: manual, browser_language: browser_language}));
};