/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import IModule from "../../IModule";
import { Setting } from './settings.e';
import ISetting from "./settings/setting";
import DarkmodeSetting from "./settings/darkmode.setting";
import LocaleSetting from "./settings/locale.setting";

/**
 * Settings Manager
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export default class SettingsMod extends IModule {
    Settings: Map<Setting, ISetting> = new Map<Setting, ISetting>();

    constructor() {
        super("Settings");
    }

    protected async enable(): Promise<any> {
        this.Settings.set(Setting.DarkMode, new DarkmodeSetting());
        this.Settings.set(Setting.Locale, new LocaleSetting());
    }
}