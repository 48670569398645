{
    "footer": {
        "about": {
            "join-team": "Rejoins l'équipe",
            "team": "Notre équipe",
            "title": "À propos de nous"
        },
        "eula": {
            "cookie-policy": "Politique relative aux cookies",
            "terms-of-service": "Conditions d'utilisation",
            "privacy-policy": "Politique de confidentialité",
            "code-of-conduct": "Code de conduite",
            "title": "Accords de l'utilisateur"
        },
        "logo": {
            "title": "Créé par"
        },
        "dragon": {
            "title": "Alimenté par"
        },
        "help": {
            "faq": "FAQ",
            "title": "Warsaw Aide",
            "contact-us": "Contactez-nous",
            "feedback": "Feedback",
            "status": "Statut"
        },
        "legal": {
            "legal_a": "© {year} Warsaw Revamped. Tous les droits sont réservés",
            "legal_b": "Nous ne sommes en aucun cas affiliés à Electronic Arts Inc. ou EA Digital Illusions CE AB (également connu sous le nom de DICE). Battlefield® et Battlefield 4® sont des marques déposées d'Electronic Arts Inc. Tous les noms, logos et marques de sociétés, de produits et de services, ainsi que tout autre matériel protégé par des droits d'auteur sont la propriété de leurs propriétaires respectifs et sont utilisés à des fins d'identification uniquement. L'utilisation de ces matériaux n'implique pas l'approbation.",
            "legal_c": "SAUF EXPRESSÉMENT CRÉDITÉ, AUCUN FABRICANT D'ARMES, DE VÉHICULES OU D'ÉQUIPEMENTS N'EST AFFILIÉ OU N'A PARRAINÉ OU APPROUVÉ CE JEU."
        },
        "game": {
            "play": "Jouer",
            "insider": "WR Insiders"
        }
    },
    "header": {
        "tooltips": {
            "sign-in": "Connectez-vous ou créez un compte"
        },
        "services": {
            "wr": "Accueil",
            "wr-help": "WR Aide",
            "wr-labs": "Labo"
        },
        "my-account": {
            "preferences": "Mes préférences",
            "signout": "Déconnection",
            "my-profile": "Mon profil",
            "my-id": "Mon Warsaw ID"
        }
    },
    "general": {
        "buttons": {
            "return-to-home": "Retour à la page d'accueil"
        }
    },
    "error": {
        "error-404": {
            "title": "Page non trouvée",
            "page-title": "Page non trouvée"
        }
    }
}
