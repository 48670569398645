{
    "page": {
        "home": {
            "title": "Home"
        },
        "download": {
            "title": "Download"
        }
    },
    "home": {
        "title": "Home",
        "description": "Het laatste nieuws en updates voor alles wat te maken heeft met Warsaw Revamped: het mod platform voor Battlefield 4&trade;."
    },
    "play": {
        "title": "Speel",
        "description": "Creëer je eigen Battlefield 4&trade; game-ervaring. Speel, ontdek en creëer."
    },
    "insider": {
        "title": "WR Insiders",
        "description": "Ontworpen om de Battlefield&trade; community samen te brengen. Content creators en toegewijde spelers zijn welkom bij ons WR Insiders Program."
    },
    "roadmap": {
        "title": "Roadmap",
        "description": "De Warsaw Revamped Roadmap beschrijft de inhoud bij de release vanaf dag één, inclusief het spelen tegen AI Soldiers voor Solo, Co-op en Multiplayer."
    },
    "401": {
        "title": "Geen toegang",
        "description": "Om deze pagina te bekijken moet je ingelogd zijn met jouw WR ID en de juiste rechten hebben."
    },
    "404": {
        "title": "Niet gevonden",
        "description": "De pagina die je zoekt is niet gevonden. Heb je hulp nodig? Ga dan naar help.warsaw-revamped.com voor meer informatie."
    },
    "legal": {
        "cookie-policy": {
            "title": "Cookiebeleid",
            "description": "Speel Battlefield 4&trade; op jouw manier. Host dedicated servers, Modding, speel Ranked servers en stort je in gloednieuwe grootschalige gevechten voor 128-spelers."
        },
        "privacy-policy": {
            "title": "Privacyverklaring",
            "description": "Speel Battlefield 4&trade; op jouw manier. Host dedicated servers, Modding, speel Ranked servers en stort je in gloednieuwe grootschalige gevechten voor 128-spelers."
        }
    },
    "open-positions": {
        "title": "Open posities"
    }
}
