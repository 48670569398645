/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import store from '../../../store';
import {Deployment} from "../../../dragon/config/deployment";
import dragonConfig from '../../../dragon.json';
import { Deployment as baseDeployment } from '../config/deployment';
import AccountMod from "./modules/account/account.mod";
import LayoutMod from "./modules/layout/layout.mod";
import SettingsMod from "./modules/settings/settings.mod";

/**
 * Warsaw Dragon
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 *
 * @copyright Warsaw Revamped (c)
 */
export default class Dragon {
    account: AccountMod;
    layout: LayoutMod;
    setting: SettingsMod;

    constructor() {
        store.state.dragon.dragon = this;

        console.log(
            "    ,---,                                                         \n" +
            "  .'  .' `\\                                                       \n" +
            ",---.'     \\   __  ,-.                        ,---.        ,---,  \n" +
            "|   |  .`\\  |,' ,'/ /|            ,----._,.  '   ,'\\   ,-+-. /  | \n" +
            ":   : |  '  |'  | |' | ,--.--.   /   /  ' / /   /   | ,--.'|'   | \n" +
            "|   ' '  ;  :|  |   ,'/       \\ |   :     |.   ; ,. :|   |  ,\"' | \n" +
            "'   | ;  .  |'  :  / .--.  .-. ||   | .\\  .'   | |: :|   | /  | | \n" +
            "|   | :  |  '|  | '   \\__\\/: . ..   ; ';  |'   | .; :|   | |  | | \n" +
            "'   : | /  ; ;  : |   ,\" .--.; |'   .   . ||   :    ||   | |  |/  \n" +
            "|   | '` ,/  |  , ;  /  /  ,.  | `---`-'| | \\   \\  / |   | |--'   \n" +
            ";   :  .'     ---'  ;  :   .'   \\.'__/\\_: |  `----'  |   |/       \n" +
            "|   ,.'             |  ,     .-./|   :    :          '---'        \n" +
            "'---'                `--`---'     \\   \\  /                        \n" +
            "                                   `--`-'                         \n" +
            "Running framework %cWarsaw Dragon %c" + baseDeployment.branch + "/" + baseDeployment.commit + "%c (c) - All rights reserved\n" +
            "Running service %c" + dragonConfig.siteName + " %c" + Deployment.deployment_branch + "/" + Deployment.commit_sha_short + "%c (c) - All rights reserved\n" +
            "\nWe are in no shape or form affiliated with Electronic Arts Inc. or EA Digital Illusions CE AB (also known as DICE). Battlefield® and Battlefield 4® are registered trademarks by Electronic Arts Inc. All company, product, and service names, logos, and brands, and any other copyrighted materials are property of their respective owners and are used for identification purposes only. Use of these materials does not imply endorsement." +
            "", "color:#ff9400;font-weight:bold;", "color:purple;font-weight:bold;", "", "color:#ff9400;font-weight:bold;", "color:purple;font-weight:bold;", "");

        // Big-ass alert on the console.
        console.log("\n%cWARNING:%c Do NOT paste anything in this console. Hackers can take over and STEAL your account if you do!\n", "color:red;font-size:2vh;font-weight:900;", "font-size:2vh;font-weight:900");


        try {
            this.account = new AccountMod();
            this.layout = new LayoutMod();
            this.setting = new SettingsMod();
        } catch (e: any) {
            this.stopscreen(e, "dragon_module_create");
            return;
        }

        this.enable();
    }

    /**
     * Enable all modules when the page finished loading.
     */
    enable() {
        try {
            if (dragonConfig.features.includes("warsaw-id"))
                this.account.__enable();

            if (dragonConfig.modules.includes("settings"))
                this.setting.__enable();

            this.layout.__enable();
        } catch (e: any) {
            this.stopscreen(e, "dragon_module_enable")
        }
    }

    /**
     * A critical error that prevents the user from browsing the website.
     * Must only be used for cases where the failure causes the website to become basically unusable.
     * @param error {exception} JS-compatible exception
     * @param location {String} location of the error (refer to documentation)
     */
    stopscreen(error: any, location: any) {
        store.state.dragon.stopscreenDetails = location;
        store.state.dragon.stopscreen = error;
    }
}