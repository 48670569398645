/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

/**
 * Deployment file which is automatically populated by CI/CD on deployment.
 * Contains non-confidential or secret information, such as latest commit and the tier of deployment.
 * This is used for eg. to know if it's on a production environnement, or staging, or QA.
 * @author Firjen <firjen@warsaw-revamped.com>
 * @copyright Warsaw Revamped (c)
 */
export const Deployment = {
    /**
     * Hash of the latest commit just before this deployment.
     */
    commit_sha: "fe1e609b3308bfd29e4cfb60085cfd733482b643",

    /**
     * The short hash of the latest commit just before this deployment.
     */
    commit_sha_short: "fe1e609b",

    /**
     * The timestamp of deployment in a ISO 8601 format.
     */
    commit_timestamp: "2023-11-09T14:01:23Z",

    /**
     * Current branch this was deployed to.
     * Usually "master" for production and "dev" for development / staging.
     * Anything other than master is not for production.
     */
    deployment_branch: "master"
};