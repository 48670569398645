{
    "en": {
        "nav": {
            "auth": {
                "tooltip": {
                    "sign_in": "登入或創建帳戶"
                }
            },
            "primary": {
                "home": "主頁面",
                "mode": {
                    "dark_on": "開啟黑暗模式",
                    "dark_off": "關閉黑暗模式"
                },
                "play_button": "遊玩"
            }
        },
        "general": {
            "buttons": {
                "learn-more": "了解更多",
                "see-more-feedback": "查看更多回饋",
                "other-platforms": "其他平台",
                "download": "下載",
                "help": "幫助",
                "retry": "重試",
                "ok": "確認"
            },
            "errors": {
                "feedback": {
                    "error-generic": "與回饋伺服器連結失敗，請稍後再試。"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "long": "信任與安全團隊",
                    "info": {
                        "help": {
                            "title": "玩家支援",
                            "description": "為了向用戶提供最佳體驗，我們有一支經驗豐富的玩家支援團隊，將會回答有關您的帳戶、安全性和功能的問題。"
                        },
                        "protect": {
                            "title": "監管",
                            "description": "監管員有權力執行行為準則，對違規用户提出警告甚至封禁。"
                        },
                        "badge": {
                            "description": "信任與安全團隊是專門監管WR平台的守護者，保護用戶並留意「壞人」。你可以透過專屬的徽章認出他們。",
                            "title": "認證"
                        },
                        "coc": {
                            "title": "修訂行為準則",
                            "description": "信任與安全團隊與WR工作人員密切合作，維護行為準則。這表示行為準則會隨時更新，確保平台是一個能讓人感到安全和愉快的地方。"
                        }
                    },
                    "short": "信任與安全",
                    "description": "信任與安全團隊的重要職責是保護Warsaw Revamped用戶，透過打擊所有Warsaw Revamped平台上的濫用行為來確保線上安全。"
                },
                "qa": {
                    "long": "品質保證",
                    "description": "品質保證團隊會對最新版本的漏洞進行測試。",
                    "short": "品質保證"
                },
                "dev": {
                    "short": "開發者",
                    "long": "開發團隊",
                    "description": "開發團隊負責Warsaw Revamped的開發工作。"
                },
                "staff": {
                    "short": "工作人員",
                    "long": "Warsaw Revamped工作人員",
                    "description": "工作人員"
                },
                "admin": {
                    "description": "管理員負責各種管理職責，監督日常運作。",
                    "short": "管理員",
                    "long": "Warsaw Revamped管理員"
                },
                "verified": {
                    "description": "Warsaw Revamped的已驗證帳戶徽章表示該帳戶身分的真實性，此徽章授予在Warsaw Revamped社群發揮重要作用的成員。",
                    "info": {
                        "active": {
                            "description": "已驗證帳戶是積極參與Warsaw Revamped社群的用戶。他們不僅十分活躍還在各個方面創作了許多內容。",
                            "title": "活躍"
                        },
                        "badge": {
                            "title": "認證",
                            "description": "已驗證帳戶可以從他們的個人檔案上的認證標記來識別，這讓你可以立刻識別出這是Warsaw Revamped社群的重要成員。"
                        }
                    },
                    "long": "已驗證帳戶",
                    "short": "已驗證"
                }
            }
        },
        "page": {
            "insider": {
                "faq": {
                    "what": {
                        "title": "什麼是WR Insiders計劃？",
                        "description": "WR Insiders計畫旨在將分散的《戰地風雲™》社群聚集起來，展示WR的能力。身為玩家，我們都有一個共同點，那就是我們熱愛這個系列。為了傳播我們對《戰地風雲4™》的熱愛並展示我們的幕後工作，我們創立了WR Insiders。"
                    },
                    "who": {
                        "description": "WR Insiders計畫的目的是讓每個人都有機會加入並成為WR Insiders。你是不是時常遊玩《戰地風雲4™》以及/或是管理《戰地風雲™》或《戰地風雲4™》的Discord群組、粉絲網站、論壇或Reddit頁面呢？那麼你就有資格成為WR Insider！\n\nYouTube和Twitch上的直播主以及影音創作者也都有資格成為WR Insiders，歡迎盡早試玩Warsaw Revamped並與觀眾分享。\n\n除了熱情的《戰地風雲4™》玩家、直播主以及影音創作者之外，模組製作者也有資格成為WR Insiders！",
                        "title": "WR Insiders計畫是為誰準備的？"
                    },
                    "advantages": {
                        "title": "成為WR Insider有哪些好處？",
                        "description": "WR Insiders可以進入專屬的Discord頻道，我們會在此經常詢問有關新功能、設計或實施想法的回饋。\n\nWR Insiders可以獨家提前獲得新功能，包含Modding API，以便儘早創作模組。\n\nWR Insiders可以搶先體驗Closed Alpha，當未來Warsaw Revamped達到Beta階段時搶先體驗Closed Beta遊戲測試。WR Insiders可以第一時間搶先體驗Warsaw Revamped。"
                    }
                },
                "description": "幫助我們打造Warsaw Revamped",
                "join": {
                    "title": "你是否有興趣加入WR Insiders計劃？",
                    "description": "給我們發一封電子郵件，解釋為什麼你應該是一名WR Insider。包含任何過去的經驗和你的動機到{email}。別忘了給我們你的Discord使用者名稱和標籤，這樣我們才可以在Discord上和你聊天。"
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "閱讀更多",
                "more-news": "更多新聞"
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "levels": "{badge}等級",
                    "stats": "{badge}：{percentage} 的玩家達成",
                    "obtained": "{time}前獲得"
                },
                "type": {
                    "exclusive": {
                        "title": "獨家",
                        "description": "此徽章無法獲得。"
                    },
                    "achievement": {
                        "title": "成就"
                    }
                }
            },
            "error": {
                "banned": {
                    "title": "此用戶已在Warsaw Revamped受到停權",
                    "description": "此用戶因違反{code-of-conduct}和{terms-of-service}，目前受到停權處分。"
                },
                "not_found": {
                    "title": "抱歉，沒有用戶使用這個名字",
                    "description": "我們找不到使用此用戶名的用戶"
                },
                "general": {
                    "title": "哎呀！出了點問題",
                    "description": "抱歉，我們現在無法顯示此個人檔案。請稍後再試。"
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "send_feedback": "提交回饋訊息",
                "description": "我們檢測到一個與Warsaw Dragon有關的問題，請提交回饋意見以協助我們改進"
            }
        },
        "error": {
            "general_error_title": "哎呀…！",
            "page_not_found": {
                "title": "找不到頁面",
                "description": "您訪問的頁面不存在。"
            },
            "profile_unavailable": {
                "account_suspended": "此帳號目前在Warsaw Revamped受到停權。",
                "load_api_error": "無法載入您要查看的個人檔案。",
                "title": "個人檔案不可用"
            },
            "require_sign_in": {
                "title": "未登入",
                "description": "您必須登入您的Warsaw ID來查看此內容。"
            },
            "buttons": {
                "return": "返回",
                "go_home": "返回主頁面",
                "sign_in": "登入"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "帳戶限制",
                    "description": "帳戶會依其表現狀況而受到臨時限制，這包括但不限於帳戶的啟動和啟用多重要素驗證。收到停權、警告或其他懲處也可能導致您的帳戶受到限制。"
                },
                "alert": {
                    "suspension": {
                        "any": {
                            "permanent": "您的帳戶已被Warsaw Revamped永久停權，原因：{reason}。",
                            "temporarily": "您的帳戶已被停權{time}，原因：{reason}。"
                        },
                        "host": {
                            "permanent": "您的帳戶已被永久禁止主持伺服器，原因：{reason}。",
                            "temporarily": "您的帳戶已被禁止主持伺服器{time}，原因：{reason}。"
                        }
                    },
                    "title": "您的帳戶已被限制！",
                    "more-information": "更多資訊"
                },
                "features": {
                    "play": {
                        "ok": "您可以在公開或私人的Warsaw Revamped伺服器上遊玩。",
                        "title": "正在Warsaw Revamped的伺服器上遊玩",
                        "suspended": "由於違反服務條款，信任與安全團隊已暫停您在Warsaw Revamped伺服器上遊玩的權限。"
                    },
                    "host": {
                        "title": "主持你自己的Warsaw Revamped伺服器",
                        "suspended": "由於違反服務條款，信任與安全團隊已暫停你主持Warsaw Revamped伺服器的權限。",
                        "ok": "您可以主持公開或私人的Warsaw Revamped伺服器。",
                        "no_discord": "你必須將你的Discord賬戶與Warsap ID綁定。"
                    }
                }
            },
            "eula": {
                "page_title": "使用者協議",
                "title": "終端使用者授權合約",
                "description": "查看並閱讀您已接受的使用者協議",
                "documents": {
                    "terms": "服務條款",
                    "coc": "行為準則"
                },
                "accepted": "您於{time}接受了此文件"
            },
            "navigator": {
                "settings": "設定",
                "eula": "協議"
            },
            "settings": {
                "experimental_features": {
                    "title": "實驗性功能",
                    "description": "我們經常發布新內容，以便在我們發布給所有人之前收集回饋意見。下列內容目前處於實驗階段，並非最終結果。歡迎提出您的想法。",
                    "no_features_available": "您的帳戶目前沒有啟用實驗性功能。",
                    "warning_test_feature": "這個實驗性功能只向有限的用戶開放，以便我們在正式發布之前檢查有無問題。"
                },
                "page_title": "我的帳戶",
                "navigation": {
                    "settings": "設定",
                    "experimental_features": "實驗性功能"
                }
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "description": "點擊導覽列上的月亮或太陽圖示來啟用此實驗性功能",
                "name": "黑暗模式"
            }
        },
        "notifications": {
            "email-not-activated": "你的電子郵件必須經過驗證，才能在Warsaw Revamped上遊玩。"
        }
    }
}
