<template>
    <div class="language-selection-box d-flex align-items-center" :id="this.data.id" :class="{ 'active' : this.$i18n.locale === this.data.id }">
        <div style="margin-left:10px;">
            <img height="21" width="28" :src="this.getFlagURL(this.data.flag)" alt="">
        </div>

        <div style="font-size:15px;margin-left:10px;">
            {{ data.localised }}
        </div>

        <div class="ml-auto" v-if="this.$i18n.locale === this.data.id" style="margin-right:10px;">
            <i class="fas fa-check"></i>
            <b-tooltip :target="this.data.id" triggers="hover" placement="bottom">You currently have this language selected</b-tooltip>
        </div>

        <div class="ml-auto" v-else-if="this.data.beta" style="margin-right:10px;">
            <i class="fas fa-bug"></i>
            <b-tooltip :target="this.data.id" triggers="hover" placement="bottom">This language has been recently added and has not been fully reviewed by us and can contain incorrect translations</b-tooltip>
        </div>

    </div>
</template>

<style>
    .language-selection-box {
        box-shadow: 0 0 1px 0 rgb(0 0 0);
        transition: all 0.5s ease;
        border:1px var(--wr-color-gray) solid;
        padding:15px;
        margin-top:25px;
    }

    .language-selection-box:hover {
        border:1px var(--wr-color-primary-dark) solid !important;
        cursor: pointer;
    }

    .language-selection-box.active {
        border:1px var(--wr-color-success) solid !important;
        cursor: not-allowed !important;
    }
</style>

<script>
    export default {
        name: "language-selection-option",
        props: {
            data: { type: Object, required: true }
        },
        methods: {
            getFlagURL(flag) {
                return `https://cdn.warsaw-revamped.com/warsawrevamped/dragon/flags/43/${flag}.svg`;
            },
        }
    };
</script>