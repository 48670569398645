/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import de from './de/dragon.json'
import en from './en/dragon.json'
import fr from './fr/dragon.json'
import nl from './nl/dragon.json'
import pl from './pl/dragon.json'
import ru from './ru/dragon.json'
import tr from './tr/dragon.json'
import bg from './bg/dragon.json'
import zh from './zh-CN/dragon.json'
import zh_HANT from './zh_Hant/dragon.json'
import pt_BR from './pt_BR/dragon.json'
import sv from './sv/dragon.json'

// Base shit
import enBASE from '../ext/base/locales/en/base.json';
import deBASE from '../ext/base/locales/de/base.json';
import frBASE from '../ext/base/locales/fr/base.json';
import nlBASE from '../ext/base/locales/nl/base.json';
import ruBASE from '../ext/base/locales/ru/base.json';
import svBASE from '../ext/base/locales/sv/base.json';

// SEO shit
import enSEO from "./en/seo.json";
import deSEO from "./de/seo.json";
import frSEO from "./fr/seo.json";
import nlSEO from "./nl/seo.json";
import ruSEO from "./ru/seo.json";
import zhSEO from "./zh-CN/seo.json";
import zhHANTSEO from "./zh_Hant/seo.json";
import plSEO from "./pl/seo.json";
import bgSEO from "./bg/seo.json";
import trSEO from "./tr/seo.json";
import ptBRSEO from "./pt_BR/seo.json";
import svSEO from "./sv/seo.json";

// Badge shit
import enBADGE from "../ext/base/locales/en/badge.json";
import deBADGE from "../ext/base/locales/de/badge.json";
import frBADGE from "../ext/base/locales/fr/badge.json";
import nlBADGE from "../ext/base/locales/nl/badge.json";
import svBADGE from "../ext/base/locales/sv/badge.json";
import ruBADGE from "../ext/base/locales/ru/badge.json";

/**
import esBADGE from "./es/badge.json";
import zhBADGE from "./zh-CN/badge.json";
import zhHANTBADGE from "./zh_Hant/badge.json";
import plBADGE from "./pl/badge.json";
import bgBADGE from "./bg/badge.json";
import trBADGE from "./tr/badge.json";
import ptBRBADGE from "./pt_BR/badge.json"; **/

import enLEGAL from './en/legal.json'

/**
 * Return a list of all compiled languages
 * @refactor this looks bad, should be made better
 */
export default function getLanguages() {
    // Get all languages
    let newFR = { fr: fr.en };
    let newDE = { de: de.en };
    let newNL = { nl: nl.en };
    let newRU = { ru: ru.en };
    let newZH = { zh: zh.en };
    let newZH_HANT = { zh_Hant: zh_HANT.en };
    let newPL = { pl: pl.en };
    let newBG = { bg: bg.en };
    let newTR = { tr: tr.en };
    let newSV = { sv: sv.en };
    let newpt_BR = { pt_BR: pt_BR.en };

    // Base languages
    en.en['base'] = enBASE;
    newDE.de['base'] = deBASE;
    newFR.fr['base'] = frBASE;
    newNL.nl['base'] = nlBASE;
    newRU.ru['base'] = ruBASE;
    newSV.sv['base'] = svBASE;

    // Add SEO to each language, please optimize
    en.en['seo'] = enSEO;
    newFR.fr['seo'] = frSEO;
    newDE.de['seo'] = deSEO;
    newNL.nl['seo'] = nlSEO;
    newRU.ru['seo'] = ruSEO;
    newZH.zh['seo'] = zhSEO;
    newZH_HANT.zh_Hant['seo'] = zhHANTSEO;
    newPL.pl['seo'] = plSEO;
    newBG.bg['seo'] = bgSEO;
    newTR.tr['seo'] = trSEO;
    newpt_BR.pt_BR['seo'] = ptBRSEO;

    // Add badge languages to each language, should also be optimized
    en.en['badge'] = enBADGE;
    newDE.de['badge'] = deBADGE;
    newFR.fr['badge'] = frBADGE;
    newNL.nl['badge'] = nlBADGE;
    newSV.sv['badge'] = svBADGE;
    newRU.ru['badge'] = ruBADGE;

    /**

    newZH.zh['badge'] = zhBADGE;
    newZH_HANT.zh_Hant['badge'] = zhHANTBADGE;
    newES.es['badge'] = esBADGE;
    newPL.pl['badge'] = plBADGE;
    newBG.bg['badge'] = bgBADGE;
    newTR.tr['badge'] = trBADGE;
    newpt_BR.pt_BR['badge'] = ptBRBADGE; **/

    en.en['legal'] = enLEGAL;

    return Object.assign(en, newFR, newDE, newNL, newRU, newZH, newZH_HANT, newPL, newBG, newTR, newpt_BR, newSV);
}