{
    "page": {
        "home": {
            "title": "Вкъщи"
        },
        "download": {
            "title": "Свали"
        }
    },
    "home": {
        "title": "Вкъщи"
    },
    "play": {
        "title": "Играй"
    },
    "401": {
        "title": "Неоторизиран"
    },
    "404": {
        "title": "Не е намерен"
    },
    "legal": {
        "cookie-policy": {
            "title": "Политика за бисквитки"
        },
        "privacy-policy": {
            "title": "Политика за поверителност"
        }
    }
}
