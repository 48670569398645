{
    "en": {
        "general": {
            "buttons": {
                "learn-more": "Saiba mais",
                "see-more-feedback": "Veja mais feedbacks"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Não foi possível estabelecer uma conexão com o fórum, por favor tente mais tarde."
                }
            }
        },
        "nav": {
            "primary": {
                "home": "Home",
                "mode": {
                    "dark_on": "Ativar modo escuro",
                    "dark_off": "Desligar modo escuro"
                },
                "play_button": "Jogar"
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Entre ou crie uma conta"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "description": "A equipe de segurança tem a responsabilidade crítica de proteger os usuários do Warsaw Revamped, garantindo a segurança online, combatendo o comportamento abusivo em todas as plataformas do Warsaw Revamped.",
                    "info": {
                        "help": {
                            "description": "Para fornecer aos usuários a melhor experiência possível, temos uma equipe experiente de suporte que responderá aos tickets sobre sua conta, segurança e recursos.",
                            "title": "Suporte ao Jogador"
                        },
                        "protect": {
                            "title": "Moderação",
                            "description": "Os moderadores aplicam o Código de Conduta usando ferramentas que permitem avisar os usuários que violam as regras ou até bani-los da plataforma WR."
                        },
                        "badge": {
                            "title": "Reconhecimento",
                            "description": "Os membros da equipe de Segurança são guardiões que monitoram especificamente a plataforma WR para proteger usuários inocentes e ficar de olho nos usuários mal-intensionados. Você pode reconhecê-los por seu emblema exclusivo."
                        },
                        "coc": {
                            "title": "Alterações ao Código de Conduta",
                            "description": "Os membros da equipe de Segurança trabalham em estreita colaboração com a Equipe WR para manter o Código de Conduta. Isso significa que as regras são sempre atualizadas e garante-se que a plataforma seja um local seguro e agradável para todos."
                        }
                    },
                    "short": "Credibilidade & Segurança",
                    "long": "Time de Credibilidade & Confiança"
                },
                "admin": {
                    "short": "Administrador",
                    "long": "Administrador do Warsaw Revamped",
                    "description": "Os administradores são responsáveis por várias tarefas e supervisionam as operações do dia-a-dia."
                },
                "qa": {
                    "short": "Garantia da Qualidade",
                    "long": "Garantia da Qualidade",
                    "description": "A equipe de Garantia de Qualidade testa o jogo buscando bugs e erros."
                },
                "dev": {
                    "short": "Desenvolvedor",
                    "long": "Equipe de desenvolvimento",
                    "description": "A Equipe de desenvolvimento é reponsável pelo desenvolvimento do Warsaw Revamped."
                },
                "staff": {
                    "short": "Equipe",
                    "long": "Equipe Warsaw Revamped",
                    "description": "Equipe"
                },
                "verified": {
                    "short": "Verificado",
                    "long": "Conta Verificada",
                    "info": {
                        "active": {
                            "title": "Ativo",
                            "description": "As contas verificadas estão ativamente envolvidas na comunidade Warsaw Revamped. São utilizadas ativamente e em muitos casos criam conteúdo."
                        },
                        "badge": {
                            "title": "Reconhecimento",
                            "description": "Contas verificadas podem ser reconhecidas pela marca de verificação em seu perfil, o que permite que você instantaneamente reconheça uma pessoa importante da comunidade Warsaw Revamped."
                        }
                    },
                    "description": "A insígnia de conta verificada no Warsaw Revamped mostra que esta conta é autêntica e é concedida a membros que tem um papel importante na comunidade Warsaw Revamped"
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "Ler mais",
                "more-news": "Mais notícias"
            }
        },
        "page": {
            "insider": {
                "join": {
                    "description": "Envie-nos um email para {email} explicando porque você deve se tornar um Insider WR. Inclua qualquer experiência anterior e sua motivação. Não esqueça de nos informar seu nome no Discord e a tag para que possamos conversar com você através do Discord.",
                    "title": "Você está interessado em entrar no programa WR Insiders?"
                },
                "faq": {
                    "what": {
                        "title": "O que é o Programa de Insiders do WR?",
                        "description": "O Programa WR Insiders foi criado para reunir a comunidade espalhada de Battlefield™ e mostrar do que o WR é capaz. Como jogadores, temos uma coisa em comum, amamos essa franquia. Queremos espalhar nosso amor por Battlefield 4™ e mostrar no que estamos trabalhando nos bastidores, criamos o WR Insiders."
                    },
                    "who": {
                        "title": "Para quem é o Programa WR Insiders?",
                        "description": "O objetivo do Programa WR Insiders é dar a todos a oportunidade de participar como parte da equipe. Se você está extremamente motivado, ainda jogando Battlefield 4™ regularmente e/ou gerenciando um servidor Discord, website, fórum ou página do Reddit dedicada a Battlefield™ ou Battlefield 4™, você se qualifica como um WR Insider!\n\nStreamers e criadores de conteúdo no YouTube e Twitch também são qualificados como WR Insiders e são mais do que bem-vindos para experimentar o Warsaw Revamped antecipadamente e compartilhar o conteúdo com seu público e outros.\n\nAlém de jogadores apaixonados de Battlefield 4™, streamers e criadores de conteúdo de vídeo, modders dedicados também são qualificados como WR Insiders!"
                    },
                    "advantages": {
                        "title": "Quais são as vantagens de ser um WR Insider?",
                        "description": "Você tem acesso à uma seção do Discord onde pedimos feedback frequente sobre novos recursos, esboço e implementação de ideias, você é o primeiro a participar de qualquer teste técnico e os melhores Insiders podem até ser convidados para participar do time oficial de Controle de Qualidade."
                    }
                },
                "description": "Ajude-nos a construir o Warsaw Revamped"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "description": "As contas podem receber restrições temporárias baseados em seus status de saúde, isso inclui porém não é limitado à ativação da conta ou ter a autenticação multi-fator habilitada. Ao receber uma suspensão, aviso ou outra sansão também pode acarretar em limitações da sua conta.",
                    "title": "Limitações da conta"
                },
                "features": {
                    "play": {
                        "suspended": "Seu acesso para jogar em servidores Warsaw Revamped foi suspenso pelo time de Credibilidade & Segurança devido à violações ao termos de serviço.",
                        "title": "Jogando no servidor de Warsaw Revamped",
                        "ok": "Você pode jogar nos servidores públicos e privados do Warsaw Revamped."
                    },
                    "host": {
                        "title": "Hospedando seu próprio servidor Warsaw Revamped",
                        "suspended": "Seu acesso à hospedagem de servidores Warsaw Revamped foi suspenso pelo time de Credibilidade & Segurança devido à violações dos termos de serviço.",
                        "no_discord": "Você deve associar sua conta do Discord à sua ID Warsaw.",
                        "ok": "Você pode hospedar servidores públicos e privados do Warsaw Revamped ."
                    }
                },
                "alert": {
                    "suspension": {
                        "host": {
                            "permanent": "Sua conta foi suspensa permanentemente para a hospedar servidores devido à {reason}.",
                            "temporarily": "Sua conta foi suspensa de hospedar servidores por {time} devido a {reason}."
                        },
                        "any": {
                            "permanent": "Sua conta foi suspensa permanentemente do Warsaw Revamped devido à {reason}.",
                            "temporarily": "Sua conta foi suspensa por {time} devido à {reason}."
                        }
                    },
                    "more-information": "Mais informações",
                    "title": "Sua conta foi limitada!"
                }
            },
            "settings": {
                "experimental_features": {
                    "description": "Nós lançamos novos recusos parciais frequentemente para permitir que se colhete feedback sobre eles antes de lançar tais recursos para todos. Os recursos listados abaixo estão atualmente em um estágio experimental e talvez não são um produto final. Nós apreciamos o feedback sobre tais recursos.",
                    "title": "Recursos experimentais",
                    "no_features_available": "Nenhum recurso experimental está atualmente habilitado para sua conta.",
                    "warning_test_feature": "Este recurso experimental está apenas disponível para uma quantidade limitada de usuários para permitir que possamos verificar problemas antes do seu lançamento completo."
                },
                "page_title": "Minha conta",
                "navigation": {
                    "settings": "Configurações",
                    "experimental_features": "Recursos experimentais"
                }
            },
            "navigator": {
                "settings": "Configurações",
                "eula": "Contratos"
            },
            "eula": {
                "documents": {
                    "terms": "Termos de Serviço",
                    "coc": "Código de Conduta"
                },
                "page_title": "Contratos de usuário",
                "title": "Contratos de licença para usuários finais",
                "description": "Visualize e leia os contratos de usuário que você aceitou",
                "accepted": "Você aceitou este documento em {time}"
            }
        },
        "error": {
            "general_error_title": "Oopa...!",
            "page_not_found": {
                "title": "Página não encontrada",
                "description": "A página que você está tentando acessar não existe."
            },
            "profile_unavailable": {
                "title": "Perfil indisponível",
                "account_suspended": "Essa conta está atualmente suspensa no Warsaw Revamped.",
                "load_api_error": "O perfil que você está procurando não pode ser carregado."
            },
            "require_sign_in": {
                "description": "Para que você possa ver este conteúdo você deve entrar com sua ID Warsaw.",
                "title": "Não está conectado"
            },
            "buttons": {
                "return": "Voltar",
                "go_home": "Voltar à página principal",
                "sign_in": "Entrar"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "description": "Habilite este recurso experimental clickando na lua ou sol na barra de navegação",
                "name": "Modo escuro"
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "Detectamos um problema com o Warsaw Dragon, ajude-nos a melhorar enviando um feedback",
                "send_feedback": "Envie seu feedback"
            }
        },
        "notifications": {
            "email-not-activated": "Seu e-mail deve ser validado antes de poder jogar no Warsaw Revamped."
        }
    }
}
