{
    "en": {
        "nav": {
            "primary": {
                "home": "Главная",
                "mode": {
                    "dark_on": "Включить dark mode (экспериментально)",
                    "dark_off": "Выключить dark mode (экспериментально)"
                },
                "play_button": "Играть"
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Войти или создать аккаунт"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "Политика Доверия и Безопасности",
                    "long": "Команда Доверия и Безопасности",
                    "description": "Команда Доверия и Безопасности несет основную ответственность за защиту пользователей Warsaw Revamped, обеспечивая безопасность в режиме онлайн путем борьбы с оскорбительным поведением на всех платформах Warsaw Revamed.",
                    "info": {
                        "help": {
                            "title": "Поддержка игроков",
                            "description": "Для того чтобы предоставить наилучший пользовательский опыт, у нас есть опытная команда поддержки игроков, которая ответит на ваши вопросы относительно вашего аккаунта, безопасности и функций."
                        },
                        "protect": {
                            "title": "Модерирование",
                            "description": "Модераторы обеспечивают соблюдение Кодекса поведения, используя доступ к инструментам, позволяющим предупреждать пользователей, нарушающих правила, или даже блокировать их с платформы WR."
                        },
                        "badge": {
                            "title": "Признанный",
                            "description": "Члены команды Доверия и Безопасности являются защитниками, которые контролируют платформу WR для защиты обычных пользователей, а также следят за «плохими парнями». Вы можете распознать их по уникальному значку."
                        },
                        "coc": {
                            "title": "Поправки в Правила поведения",
                            "description": "Члены команды Доверия и Безопасности тесно сотрудничают с Модераторами WR, в целях обеспечения соблюдения правил поведения. Это означает, что правила всегда актуальны и гарантируется, что платформа является безопасной и приятной для всех."
                        }
                    }
                },
                "admin": {
                    "short": "Администратор",
                    "long": "Администратор Warsaw Revamped",
                    "description": "Администраторы отвечают за различные административные обязанности и контролируют повседневную деятельность."
                },
                "qa": {
                    "short": "Контроль качества",
                    "long": "Контроль качества",
                    "description": "Команда Контроля качества проверяет последние сборки на наличие ошибок."
                },
                "dev": {
                    "short": "Разработчик",
                    "long": "Команда разработчиков",
                    "description": "Команда разработчиков несет ответственность за разработку Warsaw Revamped."
                },
                "staff": {
                    "short": "Персонал",
                    "long": "Сотрудники Warsaw Revamped",
                    "description": "Персонал"
                },
                "verified": {
                    "short": "Подтвержденные",
                    "long": "Подтверждённый аккаунт",
                    "description": "Значок подтвержденной учетной записи в Warsaw Revamped показывает, что этот аккаунт является подлинным и присуждается пользователям, играющим важную роль в сообществе Warsaw Revamped",
                    "info": {
                        "active": {
                            "title": "Активный",
                            "description": "Подтвержденные аккаунты активно участвуют в сообществе Warsaw Revamped. Они активно используют свою учетную запись и во многих случаях создают контент."
                        },
                        "badge": {
                            "title": "Признанный",
                            "description": "Подтвержденные учетные записи могут быть отмечены значком верификации в их профиле, что позволяет моментально распознать важную персону из WR сообщества."
                        }
                    }
                }
            }
        },
        "error": {
            "profile_unavailable": {
                "account_suspended": "Эта учетная запись в настоящее время приостановлена в Warsaw Revamped.",
                "title": "Профиль недоступен",
                "load_api_error": "Не удалось загрузить профиль, который вы ищете."
            },
            "general_error_title": "Упс...!",
            "page_not_found": {
                "title": "Страница не найдена",
                "description": "Страница, которую вы пытаетесь найти, не существует."
            },
            "require_sign_in": {
                "title": "Не авторизован",
                "description": "Чтобы просмотреть этот контент, вы должны войти с вашим Warsaw ID."
            },
            "buttons": {
                "return": "Вернуться",
                "go_home": "Вернуться на главную страницу",
                "sign_in": "Войти"
            }
        },
        "account": {
            "settings": {
                "navigation": {
                    "settings": "Настройки",
                    "experimental_features": "Экспериментальные функции"
                },
                "page_title": "Мой аккаунт",
                "experimental_features": {
                    "title": "Экспериментальные функции",
                    "description": "Мы часто выпускаем новые функции по частям, что позволяет нам собирать по ним обратную связь, прежде чем выпускать их для всех. В настоящее время перечисленные ниже функции находятся в экспериментальной стадии и могут не быть финальной версией. Мы ценим обратную связь об этих функциях.",
                    "no_features_available": "Никакие экспериментальные функции в настоящее время не включены для вашего аккаунта.",
                    "warning_test_feature": "Это экспериментальная функция доступна только ограниченному количеству пользователей, чтобы мы могли проверить на наличие ошибок и проблем перед ее полноценным релизом."
                }
            },
            "limited": {
                "page": {
                    "title": "Ограничения аккаунта",
                    "description": "Аккаунты могут получать временные ограничения, основанные на их статусе, включая, но не ограничиваясь, активацию учетной записи и наличие включенной многофакторной аутентификации. Получение приостановки, предупреждения или иных санкций также могут привести к ограничению вашего аккаунта."
                },
                "features": {
                    "host": {
                        "title": "Создавать ваш собственный сервер Warsaw Revamped",
                        "suspended": "Ваш доступ к созданию серверов Warsaw Revamped был приостановлен командой Доверия и Безопасности из-за нарушения условий использования.",
                        "no_discord": "Вы должны привязать ваш Discord аккаунт к вашему Warsaw ID.",
                        "ok": "Вы можете создавать публичные и приватные серверы Warsaw Revamped."
                    },
                    "play": {
                        "title": "Играть на сервере Warsaw Revamped",
                        "suspended": "Ваш доступ к игре на серверах Warsaw Revamped был приостановлен командой Доверия и Безопасности из-за нарушения условий использования.",
                        "ok": "Вы можете играть на публичных и приватных серверах Warsaw Revamped."
                    }
                },
                "alert": {
                    "title": "Ваш аккаунт заблокирован !",
                    "more-information": "Подробнее",
                    "suspension": {
                        "any": {
                            "permanent": "Ваша учетная запись была навсегда заблокирована на серверах Warsaw Revamped по причине {reason}.",
                            "temporarily": "Ваша учетная запись была приостановлена на {time} по причине {reason}."
                        },
                        "host": {
                            "permanent": "Ваш аккаунт был навсегда заблокирован от создания серверов Warsaw Revamped по причине {reason}.",
                            "temporarily": "Ваш аккаунт был отключён от создания серверов на {time} по причине {reason}."
                        }
                    }
                }
            },
            "navigator": {
                "settings": "Настройки",
                "eula": "Соглашения"
            },
            "eula": {
                "page_title": "Пользовательские соглашения",
                "title": "Лицензионные соглашения с конечным пользователем",
                "description": "Просмотр и прочтение пользовательских соглашений, которые вы приняли",
                "documents": {
                    "terms": "Условия использования",
                    "coc": "Правила поведения"
                },
                "accepted": "Вы приняли этот документ на {time}"
            }
        },
        "page": {
            "insider": {
                "faq": {
                    "who": {
                        "title": "Для кого предназначена программа WR Insiders?",
                        "description": "Цель программы WR Insiders дать возможность каждому присоединиться как WR Insider. Вы очень замотивированы, постоянно играете в Battlefield 4™ и/или управляете Discord сервером, сайтом, форумом или страничкой на Reddit, посвященной Battlefield™ или Battlefield 4™? Тогда вы квалифицируетесь как WR Insider!\n\nСтримеры и создатели видеоконтента на YouTube и Twitch также квалифицируются как WR Insiders и более чем приветствуются попробовать Warsaw Revamped раньше, чтобы поделиться их контентом со своей аудиторией и другими.\n\nПомимо страстных игроков в Battlefield 4™, стримеров и создателей видеоконтента, к WR Insiders также относятся преданные создатели модификаций!"
                    },
                    "advantages": {
                        "title": "Какие преимущества быть WR Insider?",
                        "description": "WR Insiders имеют доступ к эксклюзивному каналу Discord где мы просим частую обратную связь о новых функциях, дизайне или идеях реализации.\n\nWR Insiders получают эксклюзивный ранний доступ к новым функциям, включая API моддинга, чтобы начать создавать модификации раньше.\n\nWR Insiders получают эксклюзивный ранний доступ к Плейтестам в рамках Закрытой Альфы и в будущем во время Закрытой Беты когда Warsaw Revamped достигнет этой фазы. WR Insiders первыми получают доступ к Warsaw Revamped."
                    },
                    "what": {
                        "title": "Что за программа WR Insiders?",
                        "description": "Программа WR Insiders предназначена для того, чтобы собрать вместе разделенное сообщество Battlefield™ и показать, на что способна WR. У нас, геймеров, есть одна общая черта, мы любим эту франшизу. Чтобы поделиться своей любовью к Battlefield 4™ и показать, над чем мы работаем за кулисами, мы создали WR Insiders."
                    }
                },
                "description": "Помогите нам создать Warsaw Revamped",
                "join": {
                    "title": "Вы заинтересованы присоединиться к программе WR Insiders?",
                    "description": "Отправьте нам email с объяснением, почему вы должны стать WR Insider. Включите любой прошлый опыт и вашу мотивацию на {email}. Не забудьте написать нам имя и тег Discord, чтобы мы могли связаться с вами в Discord."
                }
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "Мы обнаружили ошибку с Warsaw Dragon, помогите нам её исправить, отправив отзыв",
                "send_feedback": "Оставить отзыв"
            },
            "modal": {
                "placeholder": "Я нажал на ... и затем нажал...",
                "description": "Чтобы улучшить Warsaw Revamped мы автоматически собираем информацию о любых технических неполадках, происходящих на веб-сайте. Вы можете помочь нам решить эту проблему, сообщив нам, что вызвало неполадку и что вы делали до её возникновения. Достаточно пары слов, которые помогут нам воспроизвести эту проблему."
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Темная тема",
                "description": "Включите эту экспериментальную функцию, нажав на луну или солнце на панели навигации"
            }
        },
        "news": {
            "mini": {
                "more-news": "Больше новостей",
                "read-more": "Подробнее"
            }
        },
        "general": {
            "buttons": {
                "other-platforms": "Другие платформы",
                "learn-more": "Подробнее",
                "download": "Скачать",
                "help": "Помощь",
                "retry": "Повторить",
                "ok": "Ок",
                "see-more-feedback": "Посмотреть больше отзывов",
                "continue": "Продолжить",
                "join-discord": "Присоединиться к Discord"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Не удалось подключиться к серверам обратной связи, пожалуйста, попробуйте позже."
                }
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "levels": "{badge} уровни",
                    "stats": "{badge}: достигли {percentage} игроков",
                    "obtained": "получен {time} назад"
                },
                "type": {
                    "exclusive": {
                        "title": "Эксклюзивный",
                        "description": "Этот бейдж не получить."
                    },
                    "achievement": {
                        "title": "Достижение"
                    }
                }
            },
            "error": {
                "banned": {
                    "title": "Этот пользователь был заблокирован в Warsaw Revamped",
                    "description": "Этот пользователь в данный момент заблокирован за нарушение {code-of-conduct} и наших {terms-of-service}."
                },
                "not_found": {
                    "title": "Извините, пользователей с таким именем нет",
                    "description": "Мы не можем найти пользователя с данным именем."
                },
                "general": {
                    "title": "Упс! Что-то пошло не так",
                    "description": "Извините, мы не можем показать этот профиль прямо сейчас. Пожалуйста, попробуйте позже."
                },
                "server_not_found": {
                    "title": "Извините, этот профиль сервера недоступен",
                    "description": "Либо это частный сервер, либо его больше нет."
                }
            }
        },
        "notifications": {
            "email-not-activated": "Ваш email должен быть подтвержден перед тем как играть на Warsaw Revamped."
        },
        "open-positions": {
            "title": "Вакансии",
            "who-we-are": {
                "title": "Кто мы?",
                "prg2": "В настоящее время в нашей команде более 10 человек, и мы охватываем все традиционные роли в разработке игр, дизайне, управлении сообществом и других.",
                "prg1": "Warsaw Revamped управляется командой, которая вносит свой вклад и сотрудничает удаленно со всего мира, в состав которой входят как профессиональные программисты и разработчики, так и новички. У наших добровольцев разный опыт, но их объединяет общая страсть к Battlefield 4 и забота о том, чтобы наше сообщество было счастливо."
            },
            "tips": {
                "tip2": "Мы на 100% команда волонтеров и не получаем финансового вознаграждения за нашу работу над Warsaw Revamped. Мы некоммерческая команда и не собираем и не получаем деньги.",
                "tip3": "К сожалению, отсутствие ответа означает, что мы не заинтересованы в рассмотрении вашего заявления. Из-за большого количества кандидатов мы не можем ответить каждому лично.",
                "title": "Несколько советов перед подачей заявки",
                "tip1": "Покажите нам, что вы сделали на соответствующей должности, на которую вы претендуете. Это можно сделать с помощью видеороликов на YouTube, скриншотов, ссылок на GitHub или Gitlab и т.д. Мы не будем запускать какой-либо код для первоначального рассмотрения кандидатов."
            },
            "list": {
                "none": {
                    "title": "Нет вакансий",
                    "description": "Сейчас вакансий нет."
                }
            }
        },
        "home": {
            "important-features": {
                "title": "Потрясающие возможности",
                "more": "Посмотрите, что еще мы запланировали",
                "host-server": "Легко запускайте сервер на своем компьютере одним щелчком мыши в нашем лаунчере или хостите свой собственный на постоянной основе в любом месте на Windows или с помощью нашего контейнера Docker или Pterodactyl egg на Linux.",
                "ai-bots": "Первоклассная поддержка ботов. Позвольте им свободно перемещаться, настраивайте их поведение по мере необходимости или получайте полный контроль с помощью API моддинга, чтобы создавать свой собственный опыт.",
                "modding-api": "Полнофункциональный API для моддинга на Lua с необходимым инструментарием. Модифицируйте все игровое поведение, создавайте совершенно новые игровые режимы или создавайте новый контент с помощью Rime."
            },
            "roadmap": {
                "items": {
                    "dedicated-servers": {
                        "description": "Легко запускайте сервер на своем компьютере одним щелчком мыши в нашем лаунчере или хостите свой собственный на постоянной основе в любом месте на Windows или с помощью нашего контейнера Docker или Pterodactyl egg на Linux.",
                        "title": "Выделенные серверы",
                        "short-description": "Настраиваемые выделенные серверы, которые может запустить любой с количеством игроков до 128"
                    },
                    "bot": {
                        "short-description": "Первоклассная поддержка умных ботов",
                        "title": "ИИ-боты",
                        "description": "Позвольте им свободно перемещаться, настраивайте их поведение по мере необходимости или получайте полный контроль с помощью API моддинга, чтобы создавать свой собственный опыт."
                    },
                    "anti-cheat": {
                        "title": "Античит",
                        "short-description": "Улучшенные и инновационные меры по борьбе с читами"
                    },
                    "core-game": {
                        "title": "Настраиваемый бэкенд и надежность основной игры",
                        "short-description": "Полностью настраиваемый бэкенд для повышения устойчивости к простоям и лучшей защиты от DDoS для игры",
                        "description": "У нас есть полнофункциональная поддержка статистики и поддержка прогресса с помощью нашего собственного внутреннего бэкенда, получившего название Poseidon, что позволяет нам предоставлять вам лучший и более надежный игровой опыт и быстрее разрабатывать новые функции. Наша настраиваемая система защиты от DDoS обеспечивает более высокую устойчивость к атакам."
                    },
                    "modding-api": {
                        "title": "Моддинг API",
                        "short-description": "Настройте и расширьте игру по своему вкусу с помощью Lua",
                        "description": "Полнофункциональный API для моддинга на Lua и, возможно, на других языках в будущем с необходимым инструментарием. Модифицируйте все игровое поведение, создавайте совершенно новые игровые режимы или создавайте новый контент с помощью Rime."
                    }
                },
                "title": "Дорожная карта",
                "status": {
                    "in_progress": "В процессе",
                    "planned": "Планируется",
                    "done": "Завершено",
                    "scoping": "Исключено"
                },
                "release-note": {
                    "v1-release": "Планируется к первоначальному релизу",
                    "post-release": "После релиза / онлайн поддержка"
                }
            },
            "insiders": {
                "title": "Программа WR Insiders",
                "description": "Эта программа призвана объединить разделенное сообщество Battlefield 4™ и продемонстрировать возможности WR. Зарезервировано для тех, кто чрезвычайно мотивирован, регулярно играет в Battlefield 4™ и очень предан игре. Стримеры и создатели видеоконтента на YouTube и Twitch также считаются инсайдерами WR."
            }
        },
        "team": {
            "meet": {
                "core_team": "Основная команда",
                "fonctions": {
                    "community-manager": "Менеджер сообщества",
                    "game-designer": "Гейм-дизайнер",
                    "qa-member": "QA-тестировщик",
                    "game-lead-dev": "Разработчик игры и бэкенда, Основатель",
                    "backend-lead-dev": "Бэкенд- и DevOps-разработчик",
                    "web-ui-ux-lead-dev": "Ведущий веб- и UI/UX-разработчик",
                    "qa-lead": "QA-лид"
                },
                "contributors": "Помощники",
                "title": "Познакомьтесь с командой",
                "descriptions": {
                    "sjoerd": "Я присоединился к команде Warsaw Revamped 24 сентября 2021 в качестве менеджера сообщества. С момента моего прихода я отвечал за кампанию, связанную с открытой альфой 2021 , добавил больше социальных каналов, включая Twitter и Twitch, расширил наш онлайн-охват и запустил несколько инициатив, включая еженедельные вопросы и ответы на Reddit, программу WR Insiders и нашу платформу обратной связи WR. . Как менеджер сообщества, я поддерживаю связь с разработчиками и сообществом, курирую различные команды, организую кампании, забочусь о размещении контента и несу ответственность за связи с общественностью.",
                    "mrelectrify": "Я начал проект WR в 2018 году с PolloLoco после постоянных DDoS-атак и краш-атак на мои серверы сообщества без поддержки от DICE. После переговоров с некоторыми конкурентоспособными игроками о ребалансировке родилась цель моддинга. Я работал над проектом время от времени в университете и провел нашу первую альфу в августе 2020 года. Я отвечаю за игру и бэкенд. В настоящее время я работаю инженером-программистом в крупной компании и работаю над WR для развлечения в свободное время.",
                    "firjen": "Я присоединился к проекту в конце 2021 года вместе со Sjoerd в качестве веб-разработчика. С тех пор я стал ведущим разработчиком лаунчера Warsaw Revamped и Warsaw Dragon, фреймворка, используемого для всех веб-сайтов WR.",
                    "neutron": "Присоединился еще в 2018 году и помог MrElectrify запустить проект в 2020 году, создав первый веб-сайт и веб-API. С тех пор стал разработчиком для чего угодно, особенно для бэкенда, модернизации процесса, выполнения работы DevOps и вообще помощи другим разработчикам в их проектах."
                },
                "more": "И это только часть нашей крутой команды! Есть еще много людей, которые потратили много своего свободного времени на улучшение Warsaw Revamped, и их можно узнать по их специальному значку \"Персонал Warsaw\". Не забудьте поздороваться, когда увидите их."
            },
            "about": {
                "title": "Кто стоит за Warsaw Revamped?",
                "description": "Warsaw Revamped управляется командой, которая вносит свой вклад и сотрудничает удаленно со всего мира, в состав которой входят как профессиональные программисты и разработчики, так и новички. Нас объединяет общая страсть к Battlefield 4™ и моддингу. Наша цель — сделать так, чтобы сообщество тоже могло разделить нашу страсть."
            },
            "title": "Познакомьтесь с командой",
            "recruiting": {
                "title": "Присоединяйтесь к команде Warsaw Revamped!",
                "description": "Мы всегда ищем новых людей, разделяющих страсть к Battlefield 4™ и программированию. Наша цель — предоставить безопасную и вдохновляющую платформу для моддинга, которая ставит сообщество превыше всего. Наш проект некоммерческий, мы не зарабатываем на этом деньги, это просто увлечение франшизой Battlefield.",
                "join": "Присоединиться к команде"
            }
        },
        "play": {
            "hardware-requirements": {
                "requirements": {
                    "internet": {
                        "description": "Требуется для входа в систему, игры в многопользовательском режиме и создания сервера",
                        "title": "Интернет"
                    },
                    "os": {
                        "description": "Минимум Windows 8, рекомендуется Windows 10. Поддерживаются некоторые дистрибутивы Linux.",
                        "title": "Операционная система"
                    },
                    "hdd": {
                        "title": "Место на диске",
                        "description": "30 ГБ. Рекомендуется SSD."
                    },
                    "processor": {
                        "title": "Процессор",
                        "description": "Рекомендуется четырехъядерный процессор Intel или шестиядерный процессор AMD"
                    },
                    "graphics": {
                        "description": "Минимум NVIDIA GeForce GTX 660 или AMD Radeon HD 7870",
                        "title": "Видеокарта"
                    },
                    "memory": {
                        "title": "Память",
                        "description": "Минимум 4 ГБ ОЗУ, рекомендуется 8 ГБ"
                    }
                },
                "title": "Системные требования"
            },
            "download": {
                "unavailable": {
                    "title": "Warsaw Revamped пока недоступна!",
                    "description": "Warsaw Revamped все еще находится в стадии разработки, однако мы часто проводим технические игровые тесты, позволяющие нашему сообществу тестировать последние функции и изменения."
                },
                "downloading": {
                    "fail": {
                        "description": "Не удалось завершить загрузку, проверьте подключение к Интернету и попробуйте снова."
                    },
                    "progress": {
                        "prepare": "Мы подготавливаем вашу загрузку...",
                        "description": "Лаунчер загружается в фоновом режиме. Не покидайте эту страницу, иначе загрузка будет отменена."
                    },
                    "success": {
                        "description": "Ваша загрузка завершена. Вы можете открыть {exe} для процесса установки"
                    }
                },
                "available": {
                    "alert": {
                        "title": "Версия для разработчиков Warsaw Revamped",
                        "description": "У вас есть эксклюзивный доступ к версии разработчиков Warsaw Revamped. Эта версия часто обновляется и будет содержать ошибки и проблемы, мы будем признательны, если вы сообщите нам об этих проблемах, чтобы мы могли их решить. Совместное использование лаунчера с третьей стороной без разрешения Warsaw Revamped не допускается."
                    },
                    "title": "Скачать Warsaw Revamped",
                    "title_progress": "Загрузка Warsaw Revamped",
                    "select_platform": "Поддержка Linux все еще находится на очень ранней стадии и может работать некорректно.",
                    "in_progress": "Выполняется загрузка"
                }
            }
        },
        "launcher": {
            "auth": {
                "title": "Вошел",
                "description": "Вы вошли в систему, перейдите к лаунчеру Warsaw Revamped."
            }
        }
    }
}
