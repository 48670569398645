{
    "en": {
        "page": {
            "insider": {
                "join": {
                    "description": "Изпратете ни имейл, обясняващ защо трябва да станете WR Insider. Включете миналия опит и мотивацията си към {email}. Не забравяйте да ни дадете вашето име и таг в Discord, за да можем да разговаряме с вас в Discord.",
                    "title": "Интересувате ли се да се присъедините към програмата WR Insiders?"
                },
                "description": "Помогнете ни да изградим Warsaw Revamped",
                "faq": {
                    "who": {
                        "title": "За кого е запазена програмата WR Insiders?",
                        "description": "Целта на програмата WR Insiders е да даде възможност на всеки да се присъедини като WR Insider. Значи сте изключително мотивирани, все още играете редовно на Battlefield 4™ и/или управлявате Discord сървър, фен сайт, форум или страница в Reddit, посветена на Battlefield™ или Battlefield 4™? Тогава се квалифицирате като WR Insider!\n\nСтриймърите и създателите на видео съдържание в YouTube и Twitch също са квалифицирани като WR Insiders и са повече от добре дошли да изпробват Warsaw Revamped рано и да споделят своето съдържание със своята аудитория и други.\n\nОсвен страстните играчи на Battlefield 4™, стриймъри и създатели на видео съдържание, специализираните модери също са квалифицирани като WR Insiders!"
                    },
                    "what": {
                        "title": "Какво представлява програмата WR Insiders?",
                        "description": "Програмата WR Insiders е предназначена да обедини разделената общност на Battlefield™ и да покаже на какво е способен WR. Като геймъри имаме едно общо нещо, обичаме този франчайз. За да разпространим любовта си към Battlefield 4™ и да покажем върху какво работим зад кулисите, създадохме WR Insiders."
                    },
                    "advantages": {
                        "title": "Какви са предимствата да си вътрешен човек?",
                        "description": "WR Insiders имат достъп до ексклузивен раздел на Discord, където искаме чести отзиви за нови функции, идеи за дизайн или внедряване.\n\nWR Insiders получават ексклузивен ранен достъп до нови функции, включително API за модиране, за да започнат да създават модове рано.\n\nWR Insiders имат изключителен ранен достъп до Playtests чрез затворена алфа версия и в бъдеще по време на затворената бета, когато Warsaw Revamped достигне бета фаза. WR Insiders са първите, получили ранен достъп до Warsaw Revamped."
                    }
                }
            }
        },
        "general": {
            "buttons": {
                "see-more-feedback": "Вижте още отзиви",
                "learn-more": "Научи повече",
                "other-platforms": "Други платформи",
                "download": "Изтегли",
                "help": "Помощ",
                "retry": "Опитай отново",
                "join-discord": "Присъединете се към Discord",
                "continue": "Продължи"
            },
            "errors": {
                "feedback": {
                    "error-generic": "Не успешно свързване със сървърите за обратна връзка. Моля, опитайте отново по-късно."
                }
            }
        },
        "nav": {
            "primary": {
                "home": "Вкъщи",
                "play_button": "Играй",
                "mode": {
                    "dark_on": "Включи тъмен режим",
                    "dark_off": "Изключи тъмен режим"
                }
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Влезте или създайте акаунт"
                }
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "Доверие & Безопастност",
                    "long": "Екип Доверие & Безопастност",
                    "info": {
                        "help": {
                            "title": "Поддръжка на играча",
                            "description": "За да предоставим на потребителите възможно най-доброто изживяване, имаме опитен екип за поддръжка на играчи, който ще отговори на заявките относно вашия акаунт, сигурност и функции."
                        },
                        "protect": {
                            "title": "Модериране",
                            "description": "Модераторите налагат Кодекса за поведение, като използват достъпа си до инструменти, които им позволяват да предупреждават потребители, които нарушават правилата или дори им забраняват достъпа до платформата WR."
                        },
                        "badge": {
                            "title": "Признание",
                            "description": "Членовете на екипа Trust & Safety са пазители, които специално наблюдават WR платформата, за да защитят невинни потребители и да следят „лошите момчета“. Можете да ги разпознаете по уникалната им значка."
                        },
                        "coc": {
                            "title": "Изменения в Кодекса за поведение",
                            "description": "Членовете на екипа за доверие и безопасност работят в тясно сътрудничество с персонала на WR, за да поддържат Кодекса за поведение. Това означава, че правилата винаги се поддържат актуални и е гарантирано, че платформата е безопасно и приятно място за всички."
                        }
                    },
                    "description": "Екипът за доверие и безопасност носи критичната отговорност да защитава потребителите на Warsaw Revamped, като гарантира онлайн безопасността, като се бори със злоупотребата във всички платформи Warsaw Revamped."
                },
                "admin": {
                    "short": "Администратор",
                    "long": "Warsaw Revamped Администратор",
                    "description": "Администраторите отговарят за различни административни задължения и наблюдават ежедневните операции."
                },
                "qa": {
                    "short": "Осигуряване на качество",
                    "long": "Осигуряване на качество",
                    "description": "Екипът за осигуряване на качеството тества за грешки в авангардни компилации."
                },
                "dev": {
                    "short": "Разработчик",
                    "long": "Екип за разработване",
                    "description": "Екипът за разработка е отговорен за развитието на Warsaw Revamped."
                },
                "staff": {
                    "short": "Персонал",
                    "long": "Персонал на Warsaw Revamped",
                    "description": "Персонал"
                },
                "verified": {
                    "short": "Потвърдено",
                    "long": "Потвърден акаунт",
                    "info": {
                        "active": {
                            "title": "Активен",
                            "description": "Потвърдените акаунти участват активно в общността Warsaw Revamped. Те активно използват акаунта си и в много случаи създават съдържание."
                        },
                        "badge": {
                            "title": "Признание",
                            "description": "Потвърдените акаунти могат да бъдат разпознати чрез отметката за проверка в техния профил, което ви позволява незабавно да разпознаете важен човек от общността на Warsaw Revamped."
                        }
                    },
                    "description": "Значката за потвърден акаунт на Warsaw Revamped показва, че този акаунт е автентичен и се присъжда на членове, които играят важна роля в общността Warsaw Revamped"
                }
            }
        },
        "news": {
            "mini": {
                "read-more": "Прочетете още",
                "more-news": "Още новини"
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "Открихме проблем с Warsaw Dragon, помогнете ни да подобрим, като изпратите отзиви",
                "send_feedback": "Изпратете отзиви"
            }
        },
        "error": {
            "general_error_title": "Опа...!",
            "page_not_found": {
                "title": "Страницата не е намерена",
                "description": "Страницата, която се опитвате да достигнете, не съществува."
            },
            "profile_unavailable": {
                "title": "Профилът не е наличен",
                "account_suspended": "Този акаунт в момента е спрян от Warsaw Revamped.",
                "load_api_error": "Профилът, който търсите, не можа да бъде зареден."
            },
            "require_sign_in": {
                "title": "Не сте влезли",
                "description": "За да видите това съдържание, трябва да влезете с вашия Варшавски ID."
            },
            "buttons": {
                "return": "Връщане",
                "go_home": "Върнете се към началната страница",
                "sign_in": "Влез"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "Ограничения на акаунта",
                    "description": "Акаунтите могат да получават временни ограничения въз основа на тяхното здравословно състояние, това включва, но не се ограничава до активиране на акаунт и активирано многофакторно удостоверяване. Получаването на спиране, предупреждение или друга санкция също може да доведе до ограничаване на акаунта ви."
                },
                "features": {
                    "host": {
                        "title": "Хостинг на свой собствен сървър, Warsaw Revamped",
                        "suspended": "Вашият достъп до хостинг на Warsaw Revamped сървъри е спрян от екипа за доверие и безопасност поради нарушения на условията на услугата.",
                        "no_discord": "Трябва да свържете акаунта си в Discord с вашия Warsaw ID.",
                        "ok": "Можете да хоствате публични и частни Warsaw Revamped сървъри."
                    },
                    "play": {
                        "title": "Играе се на Warsaw Revamped сървър",
                        "suspended": "Вашият достъп до игра на Warsaw Revamped сървъри е спрян от екипа за доверие и безопасност поради нарушения на условията на услугата.",
                        "ok": "Можете да играете на публични и частни Warsaw Revamped сървъри."
                    }
                },
                "alert": {
                    "title": "Вашият акаунт е ограничен!",
                    "more-information": "Повече информация",
                    "suspension": {
                        "any": {
                            "permanent": "Вашият акаунт е спрян за постоянно от Warsaw Revamped поради {reason}.",
                            "temporarily": "Вашият акаунт е спрян за {time} поради {reason}."
                        },
                        "host": {
                            "permanent": "Вашият акаунт е спрян за постоянно от хостинг сървъри поради {reason}.",
                            "temporarily": "Вашият акаунт е спрян от хостинг сървъри за {time} поради {reason}."
                        }
                    }
                }
            },
            "navigator": {
                "settings": "Настройки",
                "eula": "Споразумения"
            },
            "settings": {
                "page_title": "Моят акаунт",
                "navigation": {
                    "experimental_features": "Експериментални характеристики",
                    "settings": "Настройки"
                },
                "experimental_features": {
                    "description": "Често пускаме частично нови функции, за да ни позволи да събираме отзиви за тях, преди да ги пуснем на всички. Изброените по-долу функции в момента са в експериментален етап и може да не са завършен продукт. Оценяваме обратната връзка за тези функции.",
                    "warning_test_feature": "Тази експериментална функция се въвежда само за ограничен брой потребители, за да ни позволи да проверяваме за проблеми, преди да я пуснем напълно.",
                    "title": "Експериментални характеристики",
                    "no_features_available": "Понастоящем няма активирани експериментални функции за вашия акаунт."
                }
            },
            "eula": {
                "page_title": "Потребителски споразумения",
                "title": "Лицензионни споразумения с крайни потребители",
                "description": "Вижте и прочетете споразуменията с потребители, които сте приели",
                "documents": {
                    "terms": "Условия за ползване",
                    "coc": "Кодекс на поведение"
                },
                "accepted": "Приехте този документ на {time}"
            }
        },
        "notifications": {
            "email-not-activated": "Вашият имейл трябва да бъде потвърден, преди да можете да играете на Warsaw Revamped."
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Тъмен режим",
                "description": "Активирайте тази експериментална функция, като щракнете върху луната или слънцето в лентата за навигация"
            }
        },
        "profile": {
            "badge": {
                "view": {
                    "levels": "нива {badge}",
                    "obtained": "получено преди {time}",
                    "stats": "{badge}: постигнато от {percentage} играчи"
                },
                "type": {
                    "exclusive": {
                        "description": "Тази badge не може да се получи."
                    },
                    "achievement": {
                        "title": "Постижение"
                    }
                }
            }
        },
        "open-positions": {
            "title": "Отворени позиции",
            "who-we-are": {
                "title": "Кои сме ние?",
                "prg2": "В момента ние сме екип, който е над 10 души и покриваме всички традиционни роли на разработката на игри, дизайна, управлението на общността и така нататък.",
                "prg1": "Warsaw Revamped се управлява от екип, който допринася и си сътрудничи дистанционно от цял свят, с членове, вариращи от професионални програмисти и разработчици до новодошли. Нашите доброволци имат разнообразен опит, но споделят обща страст към Battlefield 4 и гарантирането, че нашата общност е щастлива."
            },
            "tips": {
                "title": "Някои съвети преди да кандидатствате",
                "tip3": "Неуспехът да получим отговор за съжаление означава, че не се интересуваме от разглеждането на вашата кандидатура. Поради големия брой кандидати не можем да отговорим на всеки на ръка.",
                "tip2": "Ние сме 100% доброволчески екип и не получаваме финансова компенсация за работата си по Warsaw Revamped. Ние сме некомерсиални и не събираме и не получаваме пари.",
                "tip1": "Покажете ни какво сте направили на съответната позиция, за която кандидатствате. Това може да стане чрез видеоклипове в YouTube, екранни снимки, връзки към GitHub или Gitlab и т.н. Ние няма да стартираме никакъв код за първоначални прегледи на кандидатите."
            },
            "list": {
                "none": {
                    "title": "Няма отворени позиции",
                    "description": "В момента няма отворени позиции."
                }
            }
        },
        "team": {
            "recruiting": {
                "title": "Присъединете се към екипа на Warsaw Revamped!",
                "join": "Присъединете се към екипа",
                "description": "Винаги търсим нови хора, които споделят същата страст към Battlefield 4™ и програмирането. Нашата цел е да предоставим безопасна и вдъхновяваща платформа за модиране, която поставя общността на първо място преди всичко. Нашият проект е некомерсиален, ние не правим никакви пари от това, това е чиста страст към франчайза Battlefield."
            },
            "meet": {
                "more": "И това са само някои хора, част от нашия страхотен екип! Има много повече хора, които прекарват много от свободното си време, за да подобрят Warsaw Revamped, които могат да бъдат идентифицирани по специалната им значка на Warsaw Staff. Не забравяйте да кажете здравей, когато ги видите.",
                "fonctions": {
                    "web-ui-ux-lead-dev": "Водещ разработчик на уеб и потребителски интерфейс/UX",
                    "qa-lead": "Водещ за QA",
                    "qa-member": "QA тестер",
                    "game-lead-dev": "Разработчик на игри и бекенд, основател",
                    "backend-lead-dev": "Бекенд разработчик и DevOps"
                },
                "core_team": "Основен екип",
                "contributors": "Сътрудници",
                "descriptions": {
                    "mrelectrify": "Започнах проекта WR през 2018 г. с PolloLoco, след като изпитвах постоянни DDoS и катастрофални атаки с моите общностни сървъри без поддръжка от DICE. След разговори за ребалансиране с някои конкурентни играчи се роди целта за модиране. Работих по проекта непрекъснато в целия университет и проведохме първата ни алфа през август 2020 г. Аз отговарям за играта и бекенда. В днешно време съм софтуерен инженер в голяма компания и работя върху WR за забавление в свободното си време.",
                    "neutron": "Присъедини се през 2018 г. и помогна на MrElectrify да стартира проекта през 2020 г., като изгради първия уебсайт и уеб API. Оттогава стана разработчик на всичко, особено на бекенда, процес на модернизиране, извършване на DevOps работа и като цяло подпомагане на другите разработчици с техните проекти.",
                    "firjen": "Присъединих се към проекта в края на 2021 г. заедно със Sjoerd като уеб разработчик. Оттогава станах водещ разработчик на Warsaw Revamped стартер и Warsaw Dragon, рамка, използвана за всички уебсайтове на WR.",
                    "sjoerd": "Присъединих се към екипа на Warsaw Revamped на 24 септември 2021 г. като мениджър на общността. От пристигането си отговарям за кампанията около Open Alpha 2021, добавих още социални канали, включително Twitter и Twitch, разширих нашия онлайн обхват и разгърнах множество инициативи, включително седмични въпроси и отговори в Reddit, програмата WR Insiders и нашата платформа за обратна връзка WR . Като мениджър на общността поддържам връзка с разработчиците и общността и контролирам различни екипи, организирам кампании, грижа се за поставянето на съдържание и отговарям за връзките с обществеността."
                },
                "title": "Запознайте се с екипа"
            },
            "title": "Запознайте се с екипа",
            "about": {
                "title": "Кой стои зад Warsaw Revamped?",
                "description": "Warsaw Revamped се управлява от екип, който допринася и си сътрудничи дистанционно от цял свят, с членове, вариращи от професионални програмисти и разработчици до новодошли. Споделяме обща страст към Battlefield 4™ и модирането. Нашата цел е да гарантираме, че общността може да сподели и нашата страст."
            }
        }
    }
}
