/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import Vue from "vue";
import Vuex from "vuex";

import account from "../ext/base/dragon/modules/account/stores/account.store";
import layout from '../ext/base/dragon/modules/layout/stores/layout.store';
import dragon from '../ext/base/dragon/dragon.store';

import unleash from '../dragon/modules/unleash/unleash.store';
import notifications from '../dragon/modules/notifications/notifications.store';
import sentry from '../dragon/modules/sentry/stores/sentry.store';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        account,
        sentry,
        layout,
        unleash,
        notifications,
        dragon
    }
});