{
    "page": {
        "home": {
            "title": "Início"
        },
        "download": {
            "title": "Download"
        }
    }
}
