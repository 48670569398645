{
    "en": {
        "nav": {
            "primary": {
                "home": "Accueil",
                "mode": {
                    "dark_on": "Activer le mode sombre",
                    "dark_off": "Désactiver le mode sombre"
                },
                "play_button": "Jouer"
            },
            "auth": {
                "tooltip": {
                    "sign_in": "Connectez-vous ou créez un compte"
                }
            }
        },
        "error": {
            "general_error_title": "Oups...!",
            "page_not_found": {
                "title": "Page non trouvée",
                "description": "The page you are trying to reach doesn't exist."
            },
            "profile_unavailable": {
                "title": "Profil non disponible",
                "account_suspended": "Ce compte est actuellement suspendu de Warsaw Revamped.",
                "load_api_error": "Le profil que vous recherchez n'a pas pu être chargé."
            },
            "require_sign_in": {
                "title": "Non connecté",
                "description": "In order to view this content you must sign in with your Warsaw ID."
            },
            "buttons": {
                "return": "Retour",
                "go_home": "Retour à la page d'accueil",
                "sign_in": "Connexion"
            }
        },
        "account": {
            "limited": {
                "page": {
                    "title": "Account limitations",
                    "description": "Accounts can receive temporary restrictions based on their health status, this includes but is not limited to account activation and having multi-factor authentication enabled. Receiving a suspension, warning or other sanction can also cause your account to be limited."
                },
                "features": {
                    "host": {
                        "title": "Hosting your own Warsaw Revamped server",
                        "suspended": "Your access to hosting Warsaw Revamped servers has been suspended by the Trust & Safety team because of terms of service violations.",
                        "no_discord": "You must link your Discord account with your Warsaw ID",
                        "ok": "You can host public and private Warsaw Revamped servers"
                    },
                    "play": {
                        "title": "Playing on Warsaw Revamped server",
                        "suspended": "Your access to play on Warsaw Revamped servers has been suspended by the Trust & Safety team because of terms of service violations.",
                        "ok": "You can play on public and private Warsaw Revamped servers"
                    }
                },
                "alert": {
                    "title": "Votre compte a été limité !",
                    "more-information": "Plus d'informations",
                    "suspension": {
                        "any": {
                            "permanent": "Your account has been permanently suspended from Warsaw Revamped because of {reason}.",
                            "temporarily": "Your account has been suspended for {time} because of {reason}."
                        },
                        "host": {
                            "permanent": "Your account has been suspended from hosting servers because of {reason}.",
                            "temporarily": "Your account has been suspended from hosting servers for {time} because of {reason}."
                        }
                    }
                }
            },
            "settings": {
                "page_title": "Mon compte",
                "navigation": {
                    "settings": "Réglages",
                    "experimental_features": "Fonctionnalités expérimentales"
                },
                "experimental_features": {
                    "title": "Fonctionnalités expérimentales",
                    "description": "We frequently partially release new features to allow us to collect feedback about it before releasing it to everyone. The features listed below are currently in an experimental stage and may not be the finishing product. We appreciate the feedback on these features.",
                    "no_features_available": "No experimental features are currently enabled for your account.",
                    "warning_test_feature": "This experimental feature is only rolled out to a limited amount of users to allow us to check for issues before releasing it fully."
                }
            },
            "navigator": {
                "settings": "Réglages",
                "eula": "Accords"
            },
            "eula": {
                "page_title": "User agreements",
                "title": "End user license agreements",
                "description": "View and read the user agreements you've accepted",
                "documents": {
                    "terms": "Conditions d'utilisation",
                    "coc": "Code de Conduite"
                },
                "accepted": "Vous avez accepté ce document le {time}"
            }
        },
        "experimental_features": {
            "expfeat_darkmode": {
                "name": "Mode sombre",
                "description": ""
            }
        },
        "permission": {
            "status-badge": {
                "mod": {
                    "short": "",
                    "long": "",
                    "description": "The trust and safety team has the critical responsibility of protection Warsaw Revamped's users by ensuring online safety by fighting abusive behavior across all Warsaw Revamped platforms.",
                    "info": {
                        "help": {
                            "title": "",
                            "description": "In order to provide users with the best possible service, we have an experienced Player Support team that will answer your tickets for questions regarding your account, security and features."
                        },
                        "protect": {
                            "title": "Moderation",
                            "description": "Moderators protect the Code of Conduct by using their access to tools that allow them to warn users who break the rules or even ban them from the WR platform."
                        },
                        "badge": {
                            "title": "Reconnaissance",
                            "description": "Trust & Safety staff members are guardians who specifically monitor the WR platform to protect innocent users and keep an eye on the 'bad guys'. You can recognise them by their unique badge."
                        },
                        "coc": {
                            "title": "",
                            "description": "Trust & Safety staff members work closely with the WR Staff to maintain the Code of Conduct. This means that the rules are always kept up to date and it is ensured that the platform is a safe and enjoyable place for everyone."
                        }
                    }
                },
                "admin": {
                    "short": "Administrator",
                    "long": "",
                    "description": ""
                },
                "qa": {
                    "short": "Assurance Qualité",
                    "long": "Assurance Qualité",
                    "description": "The administrators are in charge of various administrative duties and oversee day-to-day operations."
                },
                "dev": {
                    "short": "Développeur",
                    "long": "Équipe de développement",
                    "description": "no"
                },
                "staff": {
                    "short": "Personnel",
                    "long": "Warsaw Revamped personnel",
                    "description": "Personnel"
                },
                "verified": {
                    "short": "Vérifiée",
                    "long": "Compte vérifié",
                    "description": "Verified accounts on Warsaw Revamped shows that this account is authentic and is awarded to members who play an important role in the Warsaw Revamped community",
                    "info": {
                        "active": {
                            "title": "Actif",
                            "description": "Verified accounts are actively involved in the Warsaw Revamped community. They actively use their account and in many cases create content."
                        },
                        "badge": {
                            "title": "Reconnaissance",
                            "description": ""
                        }
                    }
                }
            }
        },
        "page": {
            "insider": {
                "description": "Aidez-nous à construire Warsaw Revamped"
            }
        },
        "sentry": {
            "alert-bar": {
                "description": "We've detected an issue with Warsaw Dragon, help us to improve by submitting feedback",
                "send_feedback": "Send feedback"
            }
        },
        "news": {
            "mini": {
                "read-more": "Lire la suite",
                "more-news": "Plus d'actualités"
            }
        },
        "general": {
            "buttons": {
                "learn-more": "En savoir plus",
                "other-platforms": "Autres plateformes",
                "download": "Télécharger",
                "help": "Aide",
                "retry": "Réessayez",
                "ok": "Ok",
                "continue": "Poursuivre",
                "join-discord": "Rejoindre Discord"
            }
        },
        "notifications": {
            "email-not-activated": "Votre email doit être validé avant de pouvoir jouer sur Warsaw Revamped."
        },
        "profile": {
            "badge": {
                "view": {
                    "stats": "{badge} : obtenu par {pourcentage} des joueurs",
                    "obtained": "obtenu il y a {time}"
                },
                "type": {
                    "exclusive": {
                        "description": "Ce badge ne peut pas être obtenu.",
                        "title": "Exclusif"
                    }
                }
            }
        },
        "open-positions": {
            "title": "Positions ouvertes",
            "who-we-are": {
                "title": "Qui sommes nous?"
            },
            "tips": {
                "title": "Quelques conseils avant de postuler",
                "tip1": "Montrez-nous ce que vous avez fait dans le poste auquel vous postulez. Cela peut se faire via des vidéos YouTube, des captures d'écran, des liens vers GitHub ou Gitlab, etc. Nous n'exécuterons aucun code pour les examens initiaux des candidats.",
                "tip3": "L'absence de réponse signifie malheureusement que nous ne sommes pas intéressés à poursuivre cette candidature. En raison du grand nombre de candidats, nous ne pouvons pas répondre à chacun."
            },
            "list": {
                "none": {
                    "description": "Il n'y a actuellement aucun poste ouvert.",
                    "title": "Aucun poste ouvert"
                }
            }
        },
        "home": {
            "roadmap": {
                "title": "Roadmap",
                "items": {
                    "dedicated-servers": {
                        "title": "Serveurs dédiés",
                        "short-description": "Serveurs dédiés personnalisables pouvant être démarrés par n'importe qui avec jusqu'à 128 joueurs"
                    },
                    "core-game": {
                        "title": " Backend personnalisé et fiabilité du jeu de base"
                    },
                    "bot": {
                        "title": "Robots IA"
                    },
                    "anti-cheat": {
                        "title": "Anti-Cheat"
                    }
                },
                "status": {
                    "done": "Complété",
                    "in_progress": "En cours",
                    "planned": "Prévu"
                }
            },
            "insiders": {
                "title": "WR Insiders Program"
            },
            "important-features": {
                "title": "Fonctionnalités passionnantes"
            }
        },
        "team": {
            "recruiting": {
                "title": "Rejoignez l'équipe de Warsaw Revamped!",
                "join": "Rejoignez l'équipe"
            },
            "meet": {
                "title": "Rencontrer l'équipe",
                "fonctions": {
                    "community-manager": "Manager Relations Communautaires",
                    "game-lead-dev": "Développeur jeux & backend, Fondateur",
                    "backend-lead-dev": "Développeur backend & DevOps",
                    "web-ui-ux-lead-dev": "Développeur Web & UI/UX",
                    "qa-lead": "Responsable AQ",
                    "game-designer": "Designer de jeu",
                    "qa-member": "Testeur AQ"
                },
                "core_team": "Équipe de base",
                "contributors": "Contributeurs"
            },
            "title": "Rencontrer l'équipe",
            "about": {
                "title": "Qui se cache derrière Warsaw Revamped?"
            }
        },
        "play": {
            "hardware-requirements": {
                "requirements": {
                    "memory": {
                        "title": "Mémoire"
                    },
                    "internet": {
                        "title": "Internet"
                    },
                    "graphics": {
                        "title": "Graphique"
                    },
                    "hdd": {
                        "title": "Stockage"
                    },
                    "processor": {
                        "title": "Processeur"
                    }
                },
                "title": "Exigences matérielles"
            },
            "download": {
                "available": {
                    "in_progress": "Téléchargement en cours",
                    "title": "Télécharger Warsaw Revamped",
                    "title_progress": "Téléchargement de Warsaw Revamped",
                    "select_platform": "La prise en charge de Linux est encore à un stade très précoce et peut ne pas fonctionner correctement."
                },
                "unavailable": {
                    "title": "Warsaw Revamped n'est pas encore disponible!"
                }
            }
        },
        "launcher": {
            "auth": {
                "title": "Connecté"
            }
        }
    }
}
