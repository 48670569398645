{
    "footer": {
        "about": {
            "team": "Vårt team",
            "join-team": "Gå med i teamet",
            "title": "Om oss"
        },
        "help": {
            "title": "Warsaw Hjälp",
            "contact-us": "Kontakta oss",
            "faq": "Vanliga frågor",
            "feedback": "Feedback",
            "status": "Status"
        },
        "logo": {
            "title": "Skapad av"
        },
        "eula": {
            "terms-of-service": "Villkor för tjänsten",
            "privacy-policy": "Sekretesspolicy",
            "title": "Användaravtal",
            "code-of-conduct": "Uppförandekodex",
            "cookie-policy": "Policy för cookies"
        },
        "legal": {
            "legal_b": "Vi är inte på något sätt knutna till Electronic Arts Inc. eller EA Digital Illusions CE AB (även känt som DICE). Battlefield® och Battlefield 4® är registrerade varumärken av Electronic Arts Inc. Alla företags-, produkt- och tjänstebeteckningar, logotyper och varumärken samt allt annat upphovsrättsskyddat material tillhör respektive ägare och används endast i identifieringssyfte. Användning av dessa material innebär inte att de godkänns.",
            "legal_c": "OM DET INTE UTTRYCKLIGEN ANGES ÄR INGEN TILLVERKARE AV VAPEN, FORDON ELLER UTRUSTNING ANSLUTEN TILL ELLER HAR SPONSRAT ELLER GODKÄNT DETTA SPEL.",
            "legal_a": "© {år} Warszawa Revamped. Alla rättigheter förbehålls"
        },
        "dragon": {
            "title": "Drivs av"
        },
        "game": {
            "play": "Spela",
            "insider": "WR Insider"
        }
    },
    "header": {
        "tooltips": {
            "sign-in": "Logga in eller skapa ett konto"
        },
        "services": {
            "wr": "Hem",
            "wr-help": "WR Hjälp",
            "wr-labs": "labb"
        },
        "my-account": {
            "my-profile": "Min profil",
            "preferences": "Mina inställningar",
            "my-id": "Mitt Warsaw ID",
            "signout": "Logga ut"
        }
    },
    "general": {
        "buttons": {
            "return-to-home": "Återgå till startsidan"
        }
    },
    "error": {
        "error-404": {
            "title": "Sidan hittades inte",
            "page-title": "Sidan hittades inte",
            "info": "Sidan du försöker nå finns tyvärr inte."
        }
    }
}
