{
    "home": {
        "title": "主页"
    },
    "play": {
        "title": "开始游戏"
    },
    "insider": {
        "title": "WR 先锋"
    },
    "roadmap": {
        "title": "路线图"
    },
    "401": {
        "title": "未授权"
    },
    "404": {
        "title": "未找到"
    },
    "legal": {
        "cookie-policy": {
            "title": "Cookie 政策"
        },
        "privacy-policy": {
            "title": "隐私政策"
        }
    }
}
