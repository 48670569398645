<template>
    <div class="wr-side-block">
        <!-- Header -->
        <div class="d-flex" style="padding:25px;background-color: #333;color:var(--wr-color-gray)">
            <div @click="$store.state.layout.account_block = false" style="cursor:pointer;">
                <i class="fas fa-times fa-fw"></i>
            </div>
            <div style="margin-left:25px;font-weight: bold;">
                {{ this.title }}
            </div>
        </div>

        <slot />

    </div>
</template>
<style>
    .wr-side-block {
        height:100vh;
        width:550px;
        background-color: #222;
        position: fixed;
        z-index: 1000;
        right:0;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    @media only screen and (max-width: 550px) {
        .wr-side-block {
            width:100%;
        }
    }
</style>
<script>
    export default {
        name: "wr-side-block",
        props: {
            title: { type: String, required: true }
        }
    };
</script>