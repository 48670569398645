<template>
    <button class="wr-btn" :class="[{'dark' : this.dark}, {'loading' : this.loading}, this.scheme, this.size]" :disabled="loading || disabled">
        <!-- Custom left icon -->
        <div class="icon" v-if="icon">
            <i :class="this.icon"></i>
        </div>

        <!-- Custom text -->
        <div v-if="text">
            {{ this.text }}
        </div>
        <div v-else>
            <slot />
        </div>

        <!-- Show an icon on the right side of the text -->
        <div class="icon-right" v-if="icon_right">
            <i :class="this.icon_right"></i>
        </div>

        <!-- Show a loading spinner instead of text AND icon(s) -->
        <div class="loading" style="opacity: 1 !important;" v-if="loading">
            <b-spinner />
        </div>
    </button>
</template>
<script>
    export default {
        name: "wr-button",
        props: {
            scheme: { type: String, required: true },
            text: { type: String, required: false },
            icon: { type: String, required: false },
            size: { type: String, required: false },
            icon_right: { type: String, required: false },
            dark: { type: Boolean, required: false },
            disabled: { type: Boolean, required: false },
            loading: { type: Boolean, required: false }
        }
    };
</script>