/*
 __      __                                      __________                                              .___
/  \    /  \_____ _______  ___________ __  _  __ \______   \ _______  _______    _____ ______   ____   __| _/
\   \/\/   /\__  \\_  __ \/  ___/\__  \\ \/ \/ /  |       _// __ \  \/ /\__  \  /     \\____ \_/ __ \ / __ |
 \        /  / __ \|  | \/\___ \  / __ \\     /   |    |   \  ___/\   /  / __ \|  Y Y  \  |_> >  ___// /_/ |
  \__/\  /  (____  /__|  /____  >(____  /\/\_/    |____|_  /\___  >\_/  (____  /__|_|  /   __/ \___  >____ |
       \/        \/           \/      \/                 \/     \/           \/      \/|__|        \/     \/
 Warsaw Revamped (c) 2022 - All rights reserved.
 Warsaw Dragon - A full-fledged website for Warsaw Revamped, a new way to play Battlefield 4.
*/

import IModule from "../../IModule";
import store from '../../../../../store';
import {Config} from "../../../../../dragon/config/config";
import Keycloak from "keycloak-js";
import dragonConfig from '../../../../../dragon.json';
import * as Sentry from "@sentry/vue";
import gravatar from 'gravatar';

/**
 * Account Manager
 * @author Firjen <firjen@warsaw-revamped.com>
 * @since 1.0
 * @copyright Warsaw Revamped (c)
 */
export default class AccountMod extends IModule {
    constructor() {
        super("Account");
    }

    protected async enable(): Promise<any> {
        // Check if authentication is enabled in the config
        if (!dragonConfig.features.includes('warsaw-id')) {
            this.mod_logger.info("Authentication has been disabled in the config.");
            store.state.account.signingIn = false;
            return;
        }

        // Create a new Keycloak object
        store.state.account.keycloak = new Keycloak({
            url: Config.authentication.server, realm: Config.authentication.realm, clientId: Config.authentication.clientId
        });

        // Keycloak init
        store.state.account.keycloak.init({ onLoad: Config.authentication.onload }).then((auth) => {

            if (!auth) {
                this.mod_logger.info("User account is not authenticated with Keycloak.");
                this.userSignedOut();
            } else {
                this.mod_logger.info("User account is successfully authenticated with Keycloak.");
                this.userSignedIn()
            }
            store.state.account.signingIn = false;

        }).catch((error) => {
            console.error(error);
        });
    }

    requestSignout() {
        store.state.account.signingIn = true;
        store.state.account.keycloak.logout({ redirectUri: window.location.href });
    }

    requestSignin() {
        store.state.account.keycloak.login({ redirectUri: window.location.href});
    }

    /**
     * When the user signs in
     */
    userSignedIn() {
        this.mod_logger.verbose("User signed in event");

        store.state.account.keycloak.loadUserProfile()
            .then((profile) => {
                store.state.account.profile = profile;
                store.state.account.gravatar = gravatar.url(profile['email'], {d: "identicon", r: "pg"}, true);

                this.mod_logger.verbose("Keycloak profile loaded");

                // Update unleash context
                if (store.state.unleash.client !== null) {
                    this.mod_logger.verbose("Updating client Unleash context...");
                    store.state.unleash.client.setContextField('userId', profile['id']);
                    store.state.unleash.client.setContextField('username', profile['username']);
                }

                // Set Sentry context
                Sentry.setUser({email: profile['email'], id: profile['id'], username: profile['username']});
            }).catch(() => {
            store.state.acccount.profile = null;
            alert("Failed to load profile");

            // Clear Unleash context
            if (store.state.unleash.client !== null)
                store.state.unleash.client.updateContext({});

            // Clear Sentry context
            Sentry.configureScope(scope => scope.setUser(null));
        });

        setInterval(() => {
            this.refreshToken();
        }, 20000);

        store.state.account.signedIn = true;
    }

    userSignedOut() {
        if (!store.state.account.signedIn)
            return;

        this.mod_logger.verbose("User signed out event");
        // @ts-ignore
        this.parent.profile.destroy();

        store.state.account.signedIn = false;
    }

    /**
     * Refresh the user's Keycloak token.
     */
    private refreshToken() {
        store.state.account.keycloak.updateToken(60).then((refreshed) => { // If token expires within 60 seconds, then refresh
            if (refreshed) {
                this.mod_logger.info("Keycloak token has been refreshed")
            } else {
                this.mod_logger.verbose("Refused to refresh token as it's still valid for " + (store.state.account.keycloak.tokenParsed.exp + store.state.account.keycloak.timeSkew - new Date().getTime() / 1000) + "seconds");
            }
        }).catch(() => {
            this.mod_logger.error("Failed to refresh keycloak token.")
        });
    }
}