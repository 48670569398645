{
    "home": {
        "title": "主頁面"
    },
    "play": {
        "title": "遊玩"
    },
    "insider": {
        "title": "WR Insider"
    },
    "roadmap": {
        "title": "路線圖"
    },
    "401": {
        "title": "未經授權"
    },
    "404": {
        "title": "未找到"
    },
    "legal": {
        "cookie-policy": {
            "title": "Cookie政策"
        },
        "privacy-policy": {
            "title": "隱私政策"
        }
    }
}
