<template>
    <div class="side-account-block">
        <div style="padding:25px;color: var(--wr-color-light_gray);background-color: #111">

            <div class="d-flex">
                <div class="side-user-info d-flex">
                    <div class="avatar">
                        <b-avatar @click.native="$router.push({ name: 'user-profile', params: { user: $store.state.profile.profile_username } })" square size="70px" style="height:70px;border: 2px solid #333;cursor:pointer;" :src="this.$store.state.account.gravatar" />
                    </div>
                    <div style="margin-left:12px;" class="d-flex flex-column">
                        <div style="color: var(--wr-color-primary);font-weight: bold;font-size: 20px;">
                            {{ this.$store.state.account.profile.firstName }}
                        </div>
                        <div style="color:var(--wr-color-gray)">
                            -/-
                        </div>
                    </div>
                </div>

                <div class="ml-auto">
                    <wr_button :disabled="this.$store.state.account.signingIn" @click.native="$dragon.account.requestSignout()" scheme="danger" size="small" :text="$t('base.blocks.account.profile.sign-out')" icon_right="fas fa-arrow-right-from-arc" />
                </div>
            </div>


            <div class="d-flex justify-content-between" style="margin-top:25px;">
                <div class="wr-link" @click="$dragon.account.keycloak.accountManagement()">
                    {{ $t('base.blocks.account.profile.warsaw-id') }}
                </div>
                <div class="wr-link" onclick="alert('Not added yet')">
                    {{ $t('base.blocks.account.profile.preferences') }}
                </div>
            </div>
        </div>

        <!-- Experimental features -->
        <div class="d-flex align-items-center" style="padding:10px;text-transform: uppercase;color:var(--wr-color-gray);width:100%">
            <div style="margin-left:5px;color: var(--wr-color-primary);display: inline-block;border-radius: 60px;background-color:#333;padding: 13px 15px;"><i class="far fa-flask fa-fw"></i></div>
            <div style="padding: 15px;margin-left:5px; ">{{ $t('base.blocks.account.experimental.title') }}</div>
            <div class="ml-auto wr-link" style="margin-right:15px;"><i class="far fa-arrow-circle-right fa-fw"></i></div>
        </div>

        <!-- Data -->
        <div style="background-color: #333;padding:15px;color:white;font-size: 14px;">
            <span v-if="false">You don't have access to any experimental features.</span>

            <div class="d-flex align-items-center" style="margin-top:10px;" v-else>
                <div>
                    <div style="color: var(--wr-color-primary);display: inline-block;border-radius: 60px;background-color:#111;padding: 0.5em 0.6em;font-size: 21px;"><i class="far fa-moon fa-fw"></i></div>
                </div>
                <div style="margin-left:10px;">
                    <div>
                        Night mode
                    </div>
                    <div style="font-size: 13px;color:var(--wr-color-gray)">
                        Enable using the moon and sun in the bar
                    </div>
                </div>
                <div class="ml-auto" style="color:var(--wr-color-success);margin-right:15px;font-size: 22px;">
                    <i class="fas fa-check"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import wr_button from '../../form/buttons/button.vue';

    export default {
        name: "wr-side-account-block",
        components: {
            wr_button
        },
    };
</script>