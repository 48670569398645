<template>
    <nav id="account-bar">
        <div class="container d-flex flex-md-row align-items-center" style="height: 100%">

            <!-- Show the WR services -->
            <svg @click="returnHome()" class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.55 499.88">
                <g id="efa2a59d-6e5b-4a5c-9d71-930179e51091">
                    <g id="a64bb5b5-1c02-4309-afe0-c42e2125ee8d">
                        <g id="e57cf4d9-aaf2-4959-8072-04a06800ff5f">
                            <g id="bad93cf3-3bea-488a-a04d-5bac9923f8e9">
                                <path d="M0,19.29V0L115.33,115.37v19.29Zm440.47,363.5-152-152v-.12l-9.64-9.52L0,499.88V173.09L115.33,288.45V269.29L0,153.81V76.9L115.33,192.26v38.57L278.81,67.26,442.26,230.83Z"/>
                                <path d="M785.62,439.52,651,305H554.91L670.27,189.52h153.8V170.37H554.91V324.16L439.55,208.81V55h500V305H804.91L939.55,439.52ZM231.94,114.16l46.87-46.9,276.1,276.19v76.92Zm-29.77,29.78,19.55-19.56Q388.31,301.2,554.91,478v19.17Z"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

            <div id="nav-head-left" class="d-flex align-items-center d-none d-lg-flex" style="width: 100%;">
                <div class="header-link" @click.native="window.open(service.url,'_blank')" v-for="service in services.filter(a => a.show)">
                    {{ $t('base.service.' + service.id) }}
                </div>
            </div>

            <!-- Right-side -->
            <div class="nav-right d-flex justify-content-start" style="width:100%;">
                <div id="nav-head-right" class="ml-auto d-flex align-items-center" style="font-size:1.4vh;">

                    <div id="nav-head-right-support" class="d-flex align-items-center">
                        <div class="header-link d-none d-lg-block" style=";font-weight:800;text-transform:uppercase">
                            <span>{{ $t('base.header.support') }}</span>
                        </div>
                        <div class="header-link d-lg-none" style=";font-weight:800;text-transform:uppercase">
                            <span><i class="fas fa-cloud-question fa-fw"></i></span>
                        </div>
                    </div>

                    <div v-if="this.dragonDef.features.includes('warsaw-id')" id="nav-head-right-account" class="d-flex align-items-center">

                        <!-- Account click button -->
                        <div class="header-link d-none d-lg-block" @click="accountClick()" style="">
                            <span>{{ $t('base.header.account') }}</span>
                        </div>

                        <!-- Status -->
                        <div style="width:35px;margin-left:1vh;">
                            <b-spinner v-if="this.$store.state.account.signingIn" style="width:2.2vh;height:2.2vh;font-size:1.1vh;margin-top:0.2vh;"></b-spinner>
                            <i style="font-size:2.5vh;" v-else-if="!this.$store.state.account.signedIn" class="fas fa-circle-user"></i>
                            <b-avatar v-else size="2.5vh" :src="this.$store.state.account.gravatar" id="account-avatar"></b-avatar>
                        </div>
                    </div>

                     <!-- Light / dark mode -->
                    <div v-if="this.dragonDef.features.includes('dark-mode')" style="width:5px;margin-left:-2vh;">
                        <i @click="toggleDarkMode()" v-if="this.$store.state.layout.dark_mode && this.$store.state.unleash.client.isEnabled('expfeat_darkmode')" class="header-link fas fa-sun-alt fa-fw"></i>
                        <i @click="toggleDarkMode()" v-else-if="this.$store.state.unleash.client.isEnabled('expfeat_darkmode')" class="header-link fas fa-moon fa-fw"></i>
                    </div>

                </div>
            </div>
        </div>
    </nav>
</template>
<style scoped>
    #account-bar {
        background-color:#222;
        font-size:1.4vh !important;
        color:var(--wr-color-gray);
        padding-top:0.6vh;
        height: 3.75vh;
        padding-bottom: 0.6vh;
        z-index: 100;
    }

    /* New */
    #nav-head-right > div {
        margin-right: 3.25vh;
    }

    #nav-head-left > div {
        margin-left: 3.25vh;
    }

    #nav-head-right div .header-link, #nav-head-left .header-link {
        transition: color 0.25s ease;
        cursor: pointer;
    }

    #nav-head-right div .header-link-disabled {
        cursor: not-allowed;
    }

    #nav-head-right {
        font-weight:800;
        text-transform:uppercase;
    }

    #nav-head-right > div:last-child, #nav-head-left > div:last-child {
        margin-right: 0;
    }

    #nav-head-right div .header-link:hover {
        color:var(--wr-color-light_gray)
    }

    #nav-head-left .header-link:hover {
        color:var(--wr-color-primary-light)
    }

    #nav-head-right div .header-link-disabled:hover {
        color:var(--wr-color-gray);
    }

    .logo {
        width: 7.25vh;
        fill: var(--wr-color-gray);
        transition: fill 0.25s ease;
        cursor: pointer;
    }

    .logo:hover {
        fill: var(--wr-color-light_gray);
    }
</style>
<script>
    import dragonDef from '../../../../dragon.json';
    import services from '../../data/services.json';
    import { Config } from '../../config/config';

    export default {
        name: "navigation-account-header",
        methods: {
            /**
             *  Called when user clicks on the WR logo.
             */
            returnHome() {
                // If on the main website, just return the user to the homepage.
                if (dragonDef.siteId === "wr") {
                    this.$router.push({name: 'home'})
                }

                // If on other websites, return them to the main website.
                else {
                    window.open(services['warsaw-id'].url, '_blank');
                }
            },

            accountClick() {
                if (this.$store.state.account.signingIn)
                    return;

                if (!this.$store.state.account.signedIn) {
                    this.$dragon.account.requestSignin();
                    return;
                }

                this.$store.state.layout.account_block = !this.$store.state.layout.account_block;
            },

            toggleDarkMode() {
                this.$dragon.layout.darkmode.setDarkMode(!this.$store.state.layout.dark_mode); // Toggle dark mode
            },
        },
        mounted() {
            this.dragonDef = dragonDef;
            this.services = services;
        },
        data() {
            return {
                dragonDef: null,
                services: null
            }
        },
    };
</script>