<template>
    <div class="sticky-top" style="z-index:5;">
        <nav class="site-header" :class="{ 'dark' : this.$store.state.layout.dark_mode }">
            <div class="container d-flex flex-row justify-content-start align-items-center">

                <!-- Site logo -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 939.55 499.88" style="width: 9vh;">
                    <g id="efa2a59d-6e5b-4a5c-9d71-930179e51091">
                        <g id="a64bb5b5-1c02-4309-afe0-c42e2125ee8d">
                            <g id="e57cf4d9-aaf2-4959-8072-04a06800ff5f">
                                <g id="bad93cf3-3bea-488a-a04d-5bac9923f8e9">
                                    <path class="bdecb8a3-ad24-468c-9f17-9b94d26ca683" d="M0,19.29V0L115.33,115.37v19.29Zm440.47,363.5-152-152v-.12l-9.64-9.52L0,499.88V173.09L115.33,288.45V269.29L0,153.81V76.9L115.33,192.26v38.57L278.81,67.26,442.26,230.83Z"/>
                                    <path class="bdecb8a3-ad24-468c-9f17-9b94d26ca683" d="M785.62,439.52,651,305H554.91L670.27,189.52h153.8V170.37H554.91V324.16L439.55,208.81V55h500V305H804.91L939.55,439.52ZM231.94,114.16l46.87-46.9,276.1,276.19v76.92Zm-29.77,29.78,19.55-19.56Q388.31,301.2,554.91,478v19.17Z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <!-- Navigation text -->
                <div class="d-flex buttons align-items-center" style="width:100%;">
                    <router-link tag="div" :to="{ name: 'home' }" exact-active-class="active">{{ $t('nav.primary.home') }}</router-link>

                        <span class="ml-auto d-flex">
                            <router-link tag="button" class="btn btn-success" style="color:white !important;padding: 0.8vh 2.8vh;font-size:2vh !important;" :to="{ name: 'play' }" exact-active-class="active">{{ $t('nav.primary.play_button') }}</router-link>
                        </span>
                    </div>
            </div>
        </nav>

        <!-- Alerts -->
        <div v-for="notif in this.$store.state.notifications.simple_notifications_list">
            <dragon_alert :uuid="notif.uuid" :source="notif.classcolor" :icon="notif.icon" :text="notif.text" :animation="notif.animation" :dismissable="notif.dismissable"/>
        </div>
    </div>
</template>
<script>
    import store from '../../../store';
    import dragon_alert from '../../layout/alerts/dragon-alert';
    import sentry_alert from '../../layout/alerts/sentry-dragon-alert';
    import sentry_modal from '../../layout/alerts/sentry-feedback-modal';

    export default {
        name: "nav-header",
        components: {
            dragon_alert,
            sentry_alert,
            sentry_modal
        },
    }
</script>
<style type="less">
    .site-header {
        background-color: white;
        box-shadow: 0 0 2px 0 rgb(0 0 0);
        transition: all 0.5s ease;
        padding-top: 1vh;
        padding-bottom: 1vh;
        z-index: 5;
    }

    .bdecb8a3-ad24-468c-9f17-9b94d26ca683 {
        fill:#ff9400;
    }

    /* Header dark theme */
    .site-header.dark {
        color:#ccc;
        background-color: #424242;
    }
    .site-header .buttons {
        margin: auto 1vh auto 0; /* Properly centering all the buttons */
        text-decoration:none;
        font-size:2vh;
    }
    .site-header img { /* Currently primary used for the logo */
        width:6.5vh;
        margin:1vh;
    }
    .site-header .buttons .active { /* When a router link is active */
        color: var(--wr-color-primary-dark);
    }
    .site-header .buttons .active:hover { /* When a router link is active on hover */
        color: var(--wr-color-primary-dark);
        cursor: unset;
        transform: scale(1.0);
    }
    .site-header .buttons > div {
        transition: all 0.4s ease;
        transform: scale(1.0);
        cursor: pointer;
        margin-left: 3vh;
    }
    .site-header .buttons > div:hover {
        color: var(--wr-color-primary);
        transform: scale(1.09);
    }
</style>