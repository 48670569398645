{
    "home": {
        "description": "The latest news and updates for everything related to Warsaw Revamped: the mod platform for Battlefield 4&trade;."
    },
    "play": {
        "title": "Play",
        "description": "Create your own Battlefield 4&trade; gaming experience. Play, discover and create."
    },
    "insider": {
        "title": "WR Insiders",
        "description": "Designed to bring the Battlefield&trade; community together. Content creators and committed players are welcome to join our WR Insiders Program."
    },
    "roadmap": {
        "title": "Roadmap",
        "description": "The Warsaw Revamped Roadmap details the content upon release from day one, including playing against AI Soldiers for Solo, Co-op and Multiplayer."
    },
    "401": {
        "title": "Unauthorized",
        "description": "To view this page you must be logged in with your WR ID and have the appropriate privileges."
    },
    "404": {
        "title": "Not Found",
        "description": "The page you are looking for was not found. Need help? Then go to help.warsaw-revamped.com for more information."
    },
    "user-profile": {
        "title": "Profile",
        "description": ""
    },
    "account-prefs": {
        "title": "Preferences",
        "description": ""
    },
    "team": {
        "title": "Team",
        "description": "",
        "join": {
            "title": "Join the team",
            "description": ""
        },
        "view": {
            "title": "Apply",
            "description": ""
        }
    },
    "legal": {
        "home": {
            "title": "Legal",
            "description": ""
        },
        "cookie-policy": {
            "title": "Cookie Policy",
            "description": "Learn what cookies we collect and store on your device when you visit our website and use our services."
        },
        "privacy-policy": {
            "title": "Privacy Policy",
            "description": "Learn how we handle, store and protect privacy-sensitive data and guard the integrity of our platform for our users."
        },
        "code-of-conduct": {
            "title": "Code of Conduct",
            "description": ""
        },
        "terms-of-service": {
            "title": "Terms of Service",
            "description": ""
        },
        "video-policy": {
            "title": "Video Policy",
            "description": ""
        },
        "ugc-policy": {
            "title": "UGC Policy",
            "description": ""
        }
    },
    "launcher": {
        "authenticate": {
            "title": "Authentication",
            "description": ""
        }
    }
}
